import { Component } from '@angular/core';
import timer from 'app/managecustomer/Timer';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-components-managecustomer-customer-campaign',
  templateUrl: './customer-campaign.component.html'
})
export class CustomerCampaignComponent implements IActivatable {
  customerObservable: CustomerObservable;
  customer: Boo.Objects.Customer;
  refreshCustomer: (callback?: any) => void;
  getRefreshedCustomer: () => Observable<any>;

  canActivate?(_user: Boo.Objects.User, _partner: Boo.Objects.Partner, params: any): boolean {
    this.setCustomer(params.customer);

    if (params.refreshCustomer) {
      this.refreshCustomer = params.refreshCustomer;
    }

    if (params.getRefreshedCustomer) {
      this.getRefreshedCustomer = params.getRefreshedCustomer
    }

    return true;
  }

  tryRefreshCustomer(): void {
    if (this.refreshCustomer) {
      this.refreshCustomer();

      if (this.getRefreshedCustomer) {
        this.setCustomer(this.getRefreshedCustomer());
      }
    }
  }

  tryOpenTicket(ticketId: number): void {
    timer.resetUIActivityTime();

    const ticketOptions = {
        customer: this.customerObservable,
        ticketId: ticketId
    };

    timer.resetUIActivityTime();

    PriorityViewModel.show('app-components-ticket', ticketOptions);
  }

  private setCustomer(customer: any): void {
    this.customerObservable = customer;
    this.customer = ko.mapping.toJS(customer);
  }
}
