import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import CustomValidators from '../../../../shared/custom-validators';
import LocalProfileValidators from '../../../../shared/local-profile-validators';
import { Countries } from 'app/models/enums/Countries';
import { BusinessCitationService } from '../../../../services/business-citation.service';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { LocalProfileService } from '../../../../services/local-profile.service';
import { SessionStorageService } from '../../../../services/session-storage.service';
import { PartnerSsoService } from '../../../../services/partner-sso.service';
import { RegistrationPhoneNumberService } from '../../../../services/registration-phone-number.service';
import ClaimStatuses from 'app/shared/localprofiles/ClaimStatuses';
import { LocalProfileSpecialHourFactory } from '../../../../factories/local-profile-special-hour.factory';

@Component({
  selector: 'app-components-shared-local-profile',
  templateUrl: './local-profile.component.html',
  styleUrls: ['./local-profile.component.scss']
})
export class LocalProfileComponent implements OnInit {
  @Input() localProfileForm: UntypedFormGroup;
  @Input() customer: Boo.Objects.Customer;
  @Input() readOnly: boolean = false;
  @Input() showRegistration: boolean = false;
  @Input() registrationPhoneNumber?: Boo.Objects.AutoSelectedRegistrationPhoneNumber;
  @Input() disableBusinessCitationEligibilityControls: boolean = false;
  @Input() requireFullValidation: boolean = false;

  businessHoursForms: UntypedFormArray;
  specialHoursForms: UntypedFormArray;
  socialProfileForms: UntypedFormArray;

  isLoading = false;
  isReadOnly = false;
  staticData: any;
  categories: Boo.BusinessCitations.Models.BusinessCitationProviderCategory[];
  socialProfileTypes: Boo.BusinessCitations.Models.BusinessCitationProviderSocialProfileType[];
  languages: Boo.BusinessCitations.Models.BusinessCitationProviderLanguage[];
  paymentOptions: string[];
  countries: Boo.Objects.Country[];
  states: IStateDisplay[];
  serviceAreaInput$ = new Subject<string>();
  serviceAreaLoading = false;
  serviceAreas$: Observable<Boo.Objects.LocalProfiles.LocalProfileServiceArea[]> = of([]);
  partnerRequiresSso: boolean = false;
  partnerSsoLabel: string = '';
  listingClaimedByStatuses: app.shared.localprofiles.interfaces.IClaimStatus[];

  private datePattern = /\d{4}-\d{2}-\d{2}/;
  private socialMediaValidators = {
    Facebook: [Validators.minLength(5), LocalProfileValidators.facebookPageValidator(), Validators.maxLength(242)],
    Twitter: [LocalProfileValidators.twitterHandleValidator(), Validators.maxLength(15)],
    Instagram: [LocalProfileValidators.instagramHandleValidator(), Validators.maxLength(30)]
  };

  constructor(
    private businessCitationService: BusinessCitationService,
    private localProfileService: LocalProfileService,
    private localProfileSpecialHourFactory: LocalProfileSpecialHourFactory,
    private partnerSsoService: PartnerSsoService,
    private registrationPhoneNumberService: RegistrationPhoneNumberService,
    private sessionStorageService: SessionStorageService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.isReadOnly = this.readOnly;
    this.configureStaticOptions();
    this.businessHoursForms = this.localProfileForm.get('businessHours') as UntypedFormArray;
    this.specialHoursForms = this.localProfileForm.get('specialHours') as UntypedFormArray;
    this.socialProfileForms = this.localProfileForm.get('socialProfiles') as UntypedFormArray;
    this.partnerRequiresSso = this.partnerSsoService.localProfilesRequireSso(this.customer.PartnerId);
    this.partnerSsoLabel = this.partnerSsoService.getLocalProfileSsoLabel(this.customer.PartnerId);

    this.serviceAreas$ = this.serviceAreaInput$.pipe(
      filter(x => !!x),
      distinctUntilChanged(),
      debounceTime(800),
      tap(() => this.serviceAreaLoading = true),
      switchMap(input => this.localProfileService.findServiceAreas(input)
        .pipe(
          catchError(() => of([])),
          tap(() => this.serviceAreaLoading = false)
        ))
    );

    forkJoin({
      categories: this.businessCitationService.getBusinessCitationProviderCategories(this.localProfileForm.value.businessCitationProviderId),
      providerProfileData: this.businessCitationService.getBusinessCitationProviderProfileData(this.localProfileForm.value.businessCitationProviderId),
      staticData: this.sessionStorageService.getStaticData()
    }).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(result => {
      this.categories = result.categories;
      this.staticData = result.staticData;
      this.countries = this.staticData.Countries as Boo.Objects.Country[];
      this.states = (this.staticData.States as IStateDisplay[])
        .filter(x => x.CountryId === this.localProfileForm.value.countryId)
        .flatMap(x => { x.displayName = `${x.Abbreviation} - ${x.Name}`; return x; });

      this.paymentOptions = result.providerProfileData.PaymentOptions;
      this.socialProfileTypes = result.providerProfileData.SocialProfileTypes;
      this.languages = result.providerProfileData.Languages;

      // Prevent NG0100: ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => {
        this.setValidators(this.localProfileForm.get('isActive').value);
        this.setFormDependencies();
      }, 0);
    },
      (err) => toastr.error(err)
    );
  }

  addSpecialHours(): void {
    this.specialHoursForms.push(this.localProfileSpecialHourFactory.createNewFormGroup(this.localProfileForm.get('localProfileId').value));
  }

  removeSpecialHours(specialHours: any): void {
    this.specialHoursForms.removeAt(this.specialHoursForms.controls.indexOf(specialHours));
  }

  checkAddress(): void {
    let streetAddress = this.localProfileForm.get('streetAddress').value;
    let city = this.localProfileForm.get('city').value;
    let state = this.localProfileForm.get('stateId').value ? this.states.find(x => x.StateId === this.localProfileForm.get('stateId').value).Name : null;
    let zip = this.localProfileForm.get('zip').value;

    let url = state ? `https://www.google.com/search?q=${streetAddress}+${city}+${state}+${zip}` : `https://www.google.com/search?q=${streetAddress}+${city}+${zip}`;

    window.open(url);
  }

  requestNewPhoneNumber(): void {
    let phoneNumber = this.registrationPhoneNumber;
    if (!phoneNumber) {
      return;
    }

    this.isLoading = true;

    this.registrationPhoneNumberService.deactivateAndRequestNew(phoneNumber)
      .pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(newPhoneNumber => {
        this.registrationPhoneNumber = newPhoneNumber;
        newPhoneNumber ? toastr.info('A new registration phone number was successfully generated.')
          : toastr.error('A new registration phone number is not available.');
      },
        (err) => toastr.error(err)
      );
  }

  editOnPartnerSite(): void {
    this.isLoading = true;
    (this.localProfileForm.get('identifier').value ?
      this.partnerSsoService.getLocalProfileSso(this.customer.PartnerId, this.localProfileForm.get('identifier').value) :
      this.partnerSsoService.getCustomerSso(this.customer.PartnerId, this.customer.CustomerId))
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(url => {
        if (url) {
          window.open(url, '_blank');
        } else {
          toastr.error('Could not log into partner site. Please ensure sso configuration is correct.');
        }
      });
  }

  isOpen24HoursChanged($event: any, item: UntypedFormGroup): void {
    if ($event.target.checked) {
      item.get('isClosed').setValue(false);
      this.clearIntervals(item);
    }
  }

  isClosedChanged($event: any, item: UntypedFormGroup): void {
    if ($event.target.checked) {
      item.get('isOpen24Hours').setValue(false);
      this.clearIntervals(item);
    }
  }

  clearIntervals(item: UntypedFormGroup): void {
    this.clearFirstInterval(item);
    this.clearSecondInterval(item);
  }

  clearFirstInterval(item: UntypedFormGroup): void {
    item.get('from1').setValue('');
    item.get('to1').setValue('');
  }

  clearSecondInterval(item: UntypedFormGroup): void {
    item.get('from2').setValue('');
    item.get('to2').setValue('');
  }

  updateValueAndValidity() {
    this.localProfileForm.markAllAsTouched();

    this.localProfileForm.get('additionalHoursInformation').updateValueAndValidity();
    this.localProfileForm.get('alternatePhone').updateValueAndValidity();
    this.localProfileForm.get('businessHours').updateValueAndValidity();
    this.localProfileForm.get('canSubscribe').updateValueAndValidity();
    this.localProfileForm.get('categories').updateValueAndValidity();
    this.localProfileForm.get('city').updateValueAndValidity();
    this.localProfileForm.get('companyName').updateValueAndValidity();
    this.localProfileForm.get('contactName').updateValueAndValidity();
    this.localProfileForm.get('countryId').updateValueAndValidity({ emitEvent: false });
    this.localProfileForm.get('description').updateValueAndValidity();
    this.localProfileForm.get('email').updateValueAndValidity();
    this.localProfileForm.get('fax').updateValueAndValidity();
    this.localProfileForm.get('featuredMessage').updateValueAndValidity();
    this.localProfileForm.get('googleCoverPhoto').updateValueAndValidity();
    this.localProfileForm.get('googlePreferredPhoto').updateValueAndValidity();
    this.localProfileForm.get('googleProfilePhoto').updateValueAndValidity();
    this.localProfileForm.get('googleProfileUrl').updateValueAndValidity();
    this.localProfileForm.get('imageUrls').updateValueAndValidity();
    this.localProfileForm.get('logoUrl').updateValueAndValidity();
    this.localProfileForm.get('notes').updateValueAndValidity();
    this.localProfileForm.get('openingDate').updateValueAndValidity();
    this.localProfileForm.get('phone').updateValueAndValidity();
    this.localProfileForm.get('serviceAreas').updateValueAndValidity();
    this.localProfileForm.get('specialHours').updateValueAndValidity();
    this.localProfileForm.get('stateId').updateValueAndValidity();
    this.localProfileForm.get('streetAddress').updateValueAndValidity();
    this.localProfileForm.get('streetAddress2').updateValueAndValidity();
    this.localProfileForm.get('verifyAddressMatchesWithGoogle').updateValueAndValidity();
    this.localProfileForm.get('videoUrls').updateValueAndValidity();
    this.localProfileForm.get('website').updateValueAndValidity();
    this.localProfileForm.get('zip').updateValueAndValidity();

    this.socialProfileForms.controls.forEach(c => c.updateValueAndValidity());
  }

  private setValidators(isActive: boolean) {
    if (isActive) {
      if (this.requireFullValidation || this.localProfileForm.get('isComplete').value) {
        this.setActiveFullValidation();
      } else {
        this.setMinimumValidation();
      }
    } else {
      this.setInactiveFullValidation();
    }
  }

  private setMinimumValidation(): void {
    this.localProfileForm.get('canSubscribe').setValidators([Validators.required]);
    this.localProfileForm.get('serviceAreas').setValidators([CustomValidators.requiredIfValidator(this.serviceAreasAreRequired.bind(this)), CustomValidators.maxLengthArrayValidator(20)]);
    this.localProfileForm.get('streetAddress').setValidators([Validators.required, LocalProfileValidators.cannotContainPOBox(), Validators.maxLength(255)]);
    this.localProfileForm.get('streetAddress2').setValidators([LocalProfileValidators.cannotContainPOBox(), Validators.maxLength(255)]);
    this.localProfileForm.get('zip').setValidators([Validators.required, Validators.maxLength(10)]);
  }

  private setInactiveFullValidation(): void {
    this.localProfileForm.get('additionalHoursInformation').setValidators([Validators.maxLength(255)]);
    this.localProfileForm.get('alternatePhone').setValidators([Validators.maxLength(20)]);
    this.localProfileForm.get('businessHours').setValidators([LocalProfileValidators.businessHoursValidator()]);
    this.localProfileForm.get('canSubscribe').setValidators([Validators.required, CustomValidators.requiredFalse('A local profile must be active to be subscribed')]);
    this.localProfileForm.get('categories').setValidators([CustomValidators.maxLengthArrayValidator(10)]);
    this.localProfileForm.get('city').setValidators([Validators.maxLength(255)]);
    this.localProfileForm.get('companyName').setValidators([Validators.maxLength(255)]);
    this.localProfileForm.get('contactName').setValidators([Validators.maxLength(128)]);
    this.localProfileForm.get('countryId').setValidators([]);
    this.localProfileForm.get('description').setValidators([Validators.maxLength(1000)]);
    this.localProfileForm.get('email').setValidators([Validators.maxLength(255)]);
    this.localProfileForm.get('fax').setValidators([Validators.maxLength(20)]);
    this.localProfileForm.get('featuredMessage').setValidators([Validators.maxLength(200)]);
    this.localProfileForm.get('googleCoverPhoto').setValidators([Validators.maxLength(1024)]);
    this.localProfileForm.get('googlePreferredPhoto').setValidators([]);
    this.localProfileForm.get('googleProfilePhoto').setValidators([]);
    this.localProfileForm.get('googleProfileUrl').setValidators([Validators.maxLength(512)]);
    this.localProfileForm.get('imageUrls').setValidators([Validators.maxLength(1024)]);
    this.localProfileForm.get('logoUrl').setValidators([Validators.maxLength(1024)]);
    this.localProfileForm.get('notes').setValidators([Validators.maxLength(1024)]);
    this.localProfileForm.get('openingDate').setValidators([]);
    this.localProfileForm.get('phone').setValidators([Validators.maxLength(20)]);
    this.localProfileForm.get('serviceAreas').setValidators([CustomValidators.maxLengthArrayValidator(20)]);
    this.localProfileForm.get('specialHours').setValidators([LocalProfileValidators.specialHoursValidator(false)]);
    this.localProfileForm.get('stateId').setValidators([]);
    this.localProfileForm.get('streetAddress').setValidators([Validators.maxLength(255)]);
    this.localProfileForm.get('streetAddress2').setValidators([Validators.maxLength(255)]);
    this.localProfileForm.get('verifyAddressMatchesWithGoogle').setValidators([]);
    this.localProfileForm.get('videoUrls').setValidators([Validators.maxLength(1024)]);
    this.localProfileForm.get('website').setValidators([Validators.maxLength(350)]);
    this.localProfileForm.get('zip').setValidators([Validators.maxLength(10)]);
    
    this.socialProfileForms.controls.forEach(c => c.get('url').setValidators(this.socialMediaValidators[c.value.name] ?? [Validators.maxLength(255), CustomValidators.validUrl()]));

    const countryIdControl = this.localProfileForm.get('countryId');
    countryIdControl.updateValueAndValidity();
    countryIdControl.valid ? countryIdControl.disable() : countryIdControl.enable();
  }

  private setActiveFullValidation(): void {
    this.localProfileForm.get('additionalHoursInformation').setValidators([Validators.maxLength(255)]);
    this.localProfileForm.get('alternatePhone').setValidators([CustomValidators.phoneNumberValidator(this.countryAbbreviation.bind(this)), Validators.maxLength(20)]);
    this.localProfileForm.get('businessHours').setValidators([LocalProfileValidators.businessHoursValidator()]);
    this.localProfileForm.get('canSubscribe').setValidators([Validators.required]);
    this.localProfileForm.get('categories').setValidators([Validators.required, CustomValidators.maxLengthArrayValidator(10)]);
    this.localProfileForm.get('city').setValidators([Validators.required, Validators.maxLength(255)]);
    this.localProfileForm.get('companyName').setValidators([Validators.required, CustomValidators.cannotContainHTML(), CustomValidators.cannotContainControlCharacters(), Validators.maxLength(255)]);
    this.localProfileForm.get('contactName').setValidators([Validators.maxLength(128)]);
    this.localProfileForm.get('countryId').setValidators([Validators.required, CustomValidators.mustEqualValidator(this.customer.CountryId, 'Country must match the customer\'s country.')]);
    this.localProfileForm.get('description').setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(1000)]);
    this.localProfileForm.get('email').setValidators([CustomValidators.email, Validators.maxLength(255)]);
    this.localProfileForm.get('fax').setValidators([Validators.maxLength(20), CustomValidators.phoneNumberValidator(this.countryAbbreviation.bind(this))]);
    this.localProfileForm.get('featuredMessage').setValidators([Validators.required, CustomValidators.cannotContainUrl(), CustomValidators.cannotContainDigits(10, 'Cannot contain a phone number'), CustomValidators.cannotContainHTML(), Validators.maxLength(200)]);
    this.localProfileForm.get('googleCoverPhoto').setValidators([CustomValidators.cannotContainLeadingOrTrailingSpace(), CustomValidators.validUrl(), Validators.maxLength(1024)]);
    this.localProfileForm.get('googlePreferredPhoto').setValidators([CustomValidators.cannotContainLeadingOrTrailingSpace(), CustomValidators.validUrl()]);
    this.localProfileForm.get('googleProfilePhoto').setValidators([CustomValidators.cannotContainLeadingOrTrailingSpace(), CustomValidators.validUrl()]);
    this.localProfileForm.get('googleProfileUrl').setValidators([Validators.maxLength(512)]);
    this.localProfileForm.get('imageUrls').setValidators([Validators.maxLength(1024)]);
    this.localProfileForm.get('logoUrl').setValidators([Validators.maxLength(1024)]);
    this.localProfileForm.get('notes').setValidators([Validators.maxLength(1024)]);
    this.localProfileForm.get('openingDate').setValidators([CustomValidators.patternValidator(this.datePattern, { 'invalid': { alternateMessage: 'Must be a complete and valid date' } }), CustomValidators.minDate('1000-01-01'), CustomValidators.maxDate(moment().year() + 10 + '-12-31')]);
    this.localProfileForm.get('phone').setValidators([CustomValidators.phoneNumberValidator(this.countryAbbreviation.bind(this)), Validators.maxLength(20)]);
    this.localProfileForm.get('serviceAreas').setValidators([CustomValidators.requiredIfValidator(this.serviceAreasAreRequired.bind(this)), CustomValidators.maxLengthArrayValidator(20)]);
    this.localProfileForm.get('specialHours').setValidators([LocalProfileValidators.specialHoursValidator(true)]);
    this.localProfileForm.get('stateId').setValidators([CustomValidators.requiredIfValidator(this.stateIsRequired.bind(this))]);
    this.localProfileForm.get('streetAddress').setValidators([Validators.required, LocalProfileValidators.cannotContainPOBox(), Validators.maxLength(255)]);
    this.localProfileForm.get('streetAddress2').setValidators([LocalProfileValidators.cannotContainPOBox(), Validators.maxLength(255)]);
    this.localProfileForm.get('verifyAddressMatchesWithGoogle').setValidators([Validators.requiredTrue]);
    this.localProfileForm.get('videoUrls').setValidators([Validators.maxLength(1024)]);
    this.localProfileForm.get('website').setValidators([CustomValidators.validUrl(), Validators.maxLength(350)]);
    this.localProfileForm.get('zip').setValidators([Validators.required, CustomValidators.postalCodeValidator(this.countryId.bind(this)), Validators.maxLength(10)]);

    this.socialProfileForms.controls.forEach(c => {
      let url = c.get('url');
      
      // If the social media type has a custom validator, use it
      if (this.socialMediaValidators[c.value.name]) {
        url.setValidators(this.socialMediaValidators[c.value.name]);
        return;
      }

      if (!c.value.prependBaseUrl) {
        url.setValidators([
          Validators.maxLength(255),
          CustomValidators.validUrl(),
          CustomValidators.patternValidator(
            // This pattern is also used in the SocialProfileConfig in Service
            new RegExp(`^(https?:\/\/)?([a-z0-9]+\\.)?${c.value.baseUrl}`, 'i'),
            { 'invalid': { alternateMessage: `URL must be located on ${c.value.baseUrl}` } })
        ]);
      } else
      {
        url.setValidators([Validators.maxLength(255)]);
      }
  });

    const countryIdControl = this.localProfileForm.get('countryId');
    countryIdControl.updateValueAndValidity();
    countryIdControl.valid ? countryIdControl.disable() : countryIdControl.enable();
  }

  private setFormDependencies() {
    this.localProfileForm.get('isStreetAddressHidden').valueChanges.subscribe((value: boolean) => {
      this.localProfileForm.get('serviceAreas').markAsTouched();
      this.localProfileForm.get('serviceAreas').updateValueAndValidity();
    });

    this.localProfileForm.get('countryId').valueChanges.subscribe((countryId: number) => {
      this.localProfileForm.get('stateId').setValue(null);
      this.states = [];
      this.states = (this.staticData.States as IStateDisplay[])
        .filter(x => x.CountryId === this.localProfileForm.value.countryId)
        .flatMap(x => { x.displayName = `${x.Abbreviation} - ${x.Name}`; return x; });

      this.localProfileForm.get('stateId').markAsTouched();
      this.localProfileForm.get('phone').markAsTouched();
      this.localProfileForm.get('alternatePhone').markAsTouched();
      this.localProfileForm.get('fax').markAsTouched();
      this.localProfileForm.get('zip').markAsTouched();

      this.localProfileForm.get('stateId').updateValueAndValidity();
      this.localProfileForm.get('phone').updateValueAndValidity();
      this.localProfileForm.get('alternatePhone').updateValueAndValidity();
      this.localProfileForm.get('fax').updateValueAndValidity();
      this.localProfileForm.get('zip').updateValueAndValidity();
    });

    this.localProfileForm.get('isActive').valueChanges.subscribe((isActive: boolean) => {
      this.setValidators(isActive);
      this.updateValueAndValidity();
    });

    if (this.isReadOnly) {
      this.localProfileForm.get('googleListingClaimedByStatusId').disable({ emitEvent: false });
      this.localProfileForm.get('paymentOptions').disable({ emitEvent: false });
      this.localProfileForm.get('brands').disable({ emitEvent: false });
      this.localProfileForm.get('languages').disable({ emitEvent: false });
      this.localProfileForm.get('countryId').disable({ emitEvent: false });
      this.localProfileForm.get('stateId').disable({ emitEvent: false });
      this.localProfileForm.get('categories').disable({ emitEvent: false });
      this.localProfileForm.get('serviceAreas').disable({ emitEvent: false });

      this.businessHoursForms.controls.forEach((control: UntypedFormGroup) => {
        control.get('from1').disable({ emitEvent: false });
        control.get('to1').disable({ emitEvent: false });
        control.get('from2').disable({ emitEvent: false });
        control.get('to2').disable({ emitEvent: false });
      });

      this.specialHoursForms.controls.forEach((control: UntypedFormGroup) => {
        control.get('date').disable({ emitEvent: false });
        control.get('from1').disable({ emitEvent: false });
        control.get('to1').disable({ emitEvent: false });
        control.get('from2').disable({ emitEvent: false });
        control.get('to2').disable({ emitEvent: false });
      });

      this.socialProfileForms.controls.forEach(c => c.disable({ emitEvent: false }));
    }
  }

  private countryAbbreviation() {
    const country = _.find(this.countries,
      (c: any) => {
        return c.CountryId === this.localProfileForm.get('countryId').value;
      });
    return country ? country.Abbreviation : 'US';
  };

  private countryId() {
    return this.localProfileForm.get('countryId').value ?? Countries.US;
  };

  private stateIsRequired(): boolean {
    return this.states.length > 0;
  }

  private serviceAreasAreRequired(): boolean {
    return this.localProfileForm.get('isStreetAddressHidden').value;
  }

  private configureStaticOptions() {
    this.listingClaimedByStatuses = [
      {
        ListingClaimedByStatusId: ClaimStatuses.NotClaimed,
        Name: 'Not Claimed'
      },
      {
        ListingClaimedByStatusId: ClaimStatuses.Client,
        Name: 'Client'
      },
      {
        ListingClaimedByStatusId: ClaimStatuses.Partner,
        Name: this.customer.Partner.Name
      }
    ];
  }
}

interface IStateDisplay extends Boo.Objects.State {
  displayName: string;
}
