import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TabPanelDisplayMode } from 'app/components/enums/TabPanelDisplayMode';
import { LocalProfileService } from '../../../../services/local-profile.service';
import { LocalProfileFactory } from '../../../../factories/local-profile.factory';
import { finalize, switchMap } from 'rxjs/operators';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { BusinessCitationService } from '../../../../services/business-citation.service';
import { LocalProfileListComponent } from './../local-profile-list/local-profile-list.component';
import { PartnerSsoService } from '../../../../services/partner-sso.service';
import TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum;
import { BusinessCitationSubscriptionsComponent } from '../business-citation-subscriptions/business-citation-subscriptions.component';

@Component({
  selector: 'app-components-shared-local-profiles',
  templateUrl: './local-profiles.component.html'
})
export class LocalProfilesComponent implements OnInit {
  @ViewChild(LocalProfileListComponent) listComponent: LocalProfileListComponent;
  @ViewChild(BusinessCitationSubscriptionsComponent) subscriptionComponent: BusinessCitationSubscriptionsComponent;

  @Input() customer: Boo.Objects.Customer;
  @Input() localProfileId: number = 0;
  @Input() readOnly: boolean = false;
  @Input() showRegistration: boolean = false;
  @Input() showSubscriptions: boolean = false;
  @Input() showSubscriptionSaveButton: boolean = false;
  @Input() manageableTaskTypes?: TaskTypeEnum[] = [];
  @Input() registrationPhoneNumber?: Boo.Objects.AutoSelectedRegistrationPhoneNumber;
  @Input() allowAutoSubscribe: boolean = false;
  @Input() allowValidationAndAugmentationOrdering: boolean = false;
  @Input() requireFullLocalProfileOnAdd: boolean = false;
  @Input() requireFullValidation: boolean = false;
  @Input() canAddLocalProfile: boolean;
  @Input() createValidationAndAugmentationForNewLocalProfiles: boolean = false;
  @Input() defaultCanSubscribeToTrueOnAdd: boolean = false;

  tabPanelDisplayMode = TabPanelDisplayMode;
  isLoading: boolean = false;
  isReadOnly: boolean = false;
  shouldShowSubscriptions: boolean = false;
  form: UntypedFormGroup;
  localProfileForms: UntypedFormArray;
  localProfiles: Boo.Objects.LocalProfiles.LocalProfile[];
  businessCitationProviderId: number;
  categories: Boo.BusinessCitations.Models.BusinessCitationProviderCategory[];
  profileData: Boo.BusinessCitations.Models.BusinessCitationProviderProfileData;
  partnerRequiresSso: boolean = false;
  partnerSsoLabel: string = '';
  disableBusinessCitationEligibilityControls: boolean = false;

  constructor(
    private businessCitationService: BusinessCitationService,
    private localProfileFactory: LocalProfileFactory,
    private localProfileService: LocalProfileService,
    private partnerSsoService: PartnerSsoService,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.partnerRequiresSso = this.partnerSsoService.localProfilesRequireSso(this.customer.PartnerId);
    this.partnerSsoLabel = this.partnerSsoService.getLocalProfileSsoLabel(this.customer.PartnerId);
    this.isReadOnly = this.partnerRequiresSso || this.readOnly;
    this.shouldShowSubscriptions = (this.showSubscriptions || this.manageableTaskTypes.length > 0) && !this.localProfileId;
    this.disableBusinessCitationEligibilityControls = !!this.localProfileId;

    this.load()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(_ => { }, (err) => toastr.error(err));
  }

  load(): Observable<void> {
    this.localProfileForms = this.formBuilder.array([]);
    this.form = this.formBuilder.group({
      localProfiles: this.localProfileForms
    });

    // Prevent NG0100: ExpressionChangedAfterItHasBeenCheckedError
    this.localProfileForms.markAsTouched();

    return forkJoin({
      localProfile: this.localProfileId ? this.localProfileService.by(this.localProfileId) : of(null),
      localProfiles: !this.localProfileId ? this.localProfileService.byCustomerId(this.customer.CustomerId) : of([]),
      businessCitationProviderId: this.businessCitationService.getDefaultBusinessCitationProvider(this.customer.CustomerId)
    }).pipe(
      switchMap(result => {

        if (result.localProfile) {
          this.localProfiles = [result.localProfile];
        }
        else {
          this.localProfiles = result.localProfiles.sort(this.localProfileService.sort.bind(this));
        }

        this.businessCitationProviderId = result.businessCitationProviderId;
        return forkJoin({
          categories: this.businessCitationService.getBusinessCitationProviderCategories(this.businessCitationProviderId),
          profileData: this.businessCitationService.getBusinessCitationProviderProfileData(this.businessCitationProviderId)
        }).pipe(
          switchMap(result => {
          this.categories = result.categories;
          this.profileData = result.profileData;
          this.localProfiles.forEach(x => { this.localProfileForms.push(this.localProfileFactory.createFullLocalProfileFormGroup(x, this.profileData)); });
          return of(null);
        }));
      })
    );
  }

  saveLocalProfilesButton() {
    this.isLoading = true;
    this.saveLocalProfiles()
      .pipe(
        switchMap(_ => {
          toastr.success('Local Profiles saved');
          return this.refresh();
        }),
        finalize(() => this.isLoading = false))
      .subscribe(_ => { }, (err) => toastr.error(err));
  }

  saveLocalProfiles(): Observable<void> {
    this.form.markAllAsTouched();
    this.listComponent.updateValueAndValidity();

    if (this.form.invalid) {
      return throwError('Please fix the errors on the page and try again.');
    }

    let saveableLocalProfiles = this.localProfileFactory.createSaveableLocalProfiles(this.customer,
      this.businessCitationProviderId,
      this.categories,
      this.localProfiles,
      this.localProfileForms,
      this.requireFullLocalProfileOnAdd);

    if (saveableLocalProfiles.length > 0) {
      return this.localProfileService.save({ localProfiles: saveableLocalProfiles, createValidationAndAugmentationForNewLocalProfiles: this.createValidationAndAugmentationForNewLocalProfiles });
    }
    return of(null);
  }

  saveSubscriptions(preventReload: boolean = false): Observable<void> {
    if (this.subscriptionComponent) {
      if (!this.subscriptionComponent.isValid()) {
        return throwError(this.subscriptionComponent.requiredAction);
      }

      return this.subscriptionComponent.save(preventReload);
    }
    return of(null);
  }

  isValid(): boolean {
    if (!this.partnerRequiresSso) {
      this.form.markAllAsTouched();
      this.listComponent.updateValueAndValidity();
    }
    let localProfilesAreValid = this.partnerRequiresSso ? true : this.form.valid;
    let subscriptionsAreValid = this.subscriptionComponent ? this.subscriptionComponent.isValid() : true;
    return localProfilesAreValid && subscriptionsAreValid;
  }

  hasPendingChanges(): boolean {
    return this.form.dirty || this.localProfileForms.dirty;
  }

  doesPartnerRequiresSso(): boolean {
    return this.partnerRequiresSso;
  }

  refreshButton() {
    this.isLoading = true;
    this.refresh()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(_ => { }, (err) => toastr.error(err));
  }

  refresh(): Observable<void> {
    if (this.listComponent) {
      this.listComponent.reset();
    }

    return this.load().pipe(
      switchMap(_ => {
        if (this.subscriptionComponent) {
          return this.subscriptionComponent.load();
        }
        return of(null);
      })
    );
  }

  add() {
    let localProfileForm = this.localProfileFactory.createNewFormGroup(
      this.customer,
      this.businessCitationProviderId,
      this.profileData,
      this.requireFullLocalProfileOnAdd,
      this.defaultCanSubscribeToTrueOnAdd);
    this.localProfileForms.push(localProfileForm);
    this.listComponent.setActiveLocalProfile(localProfileForm);
  }

  editOnPartnerSite(): void {
    this.isLoading = true;
    this.partnerSsoService.getCustomerSso(this.customer.PartnerId, this.customer.CustomerId)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(url => {
        if (url) {
          window.open(url, '_blank');
        } else {
          toastr.error('Could not log into partner site. Please ensure sso configuration is correct.');
        }
      });
  }

  getRegistrationNumber(): Boo.Objects.AutoSelectedRegistrationPhoneNumber {
    if (this.localProfileId && this.localProfileForms.length === 1) {
      return this.listComponent.getRegistrationNumber();
    }
    return null;
  }
}