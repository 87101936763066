import { WorkRequestEditConfig } from '../shared/models/WorkRequestEditConfig';
import TaskTypes = Boo.Objects.Enums.TaskTypeEnum;
import { FileExtensions } from '../shared/file-extensions';

export default class WorkRequestEditConfigFactory {
  static allowNoWebsiteOption: TaskTypes[] = [
    Boo.Objects.Enums.TaskTypeEnum.KeywordResearchStrategyUpdate
  ];

  static shouldHideWebsiteUrl: TaskTypes[] = [
    Boo.Objects.Enums.TaskTypeEnum.KeywordResearchStrategyUpdate
  ];

  static shouldHideFiles: TaskTypes[] = [
    Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendation,
    Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendationAndImplementation,
    Boo.Objects.Enums.TaskTypeEnum.KeywordResearchStrategyUpdate
  ];

  static allowsSpreadsheetUploads: TaskTypes[] = [
    Boo.Objects.Enums.TaskTypeEnum.ThreeHundredOneRedirectMappingAndImplementation
  ];

  static create(workTypeCreationId: TaskTypes): WorkRequestEditConfig {
    return {
      allowNoWebsiteOption : WorkRequestEditConfigFactory.allowNoWebsiteOption.includes(workTypeCreationId),
      shouldShowDescription : true,
      shouldShowWebsiteUrl : !WorkRequestEditConfigFactory.shouldHideWebsiteUrl.includes(workTypeCreationId),
      shouldShowFiles : !WorkRequestEditConfigFactory.shouldHideFiles.includes(workTypeCreationId),
      acceptedFileExtensions : WorkRequestEditConfigFactory.allowsSpreadsheetUploads.includes(workTypeCreationId) ? FileExtensions.defaultsWithSpreadsheets() : FileExtensions.defaults()
    };
  }
}
