import { Component, Input } from '@angular/core';
import { HaloKeywordSuggestion, KeywordConfig, KeywordSuggestion, PageSuggestion } from '../../../../shared/models/keywords/keywords';
import { KeywordResearchService } from '../../../../services/keyword-research.service';

@Component({
  selector: 'app-components-shared-keyword-research-halo-keywords',
  templateUrl: './halo-keywords.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class HaloKeywordsComponent {
  @Input() customer: Boo.Objects.Customer;
  @Input() pageSuggestions: PageSuggestion[];
  @Input() haloKeywordSuggestions: HaloKeywordSuggestion[];
  @Input() keywordConfig: KeywordConfig;

  constructor(private keywordResearchService: KeywordResearchService) { }

  keywordSort = (a: KeywordSuggestion, b: KeywordSuggestion): number => this.keywordResearchService.keywordSort(a, b);

  get selectedHaloKeywordSuggestions(): HaloKeywordSuggestion[] {
    return this.haloKeywordSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational);
  }
}
