@if(addingKeyword) {
  <div class="row justify-content-start">
    <div class="col-auto">
      <ng-container *ngTemplateOutlet="keyword"></ng-container>
    </div>
    <div class="col-auto" *ngIf="!customer.IsCampaignNational">
      <ng-container *ngTemplateOutlet="area"></ng-container>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-primary" (click)="addKeyword()"><i class="fas fa-plus"></i>Keyword</button>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-warning" (click)="toggleAddingKeyword()">Cancel</button>
    </div>
  </div>
}
@else {
  <div class="row">
    <div class="col-12">
      @if (canAddKeywords) {
        <button class="btn btn-primary me-4 btn-sm" type="button" data-toggle="dropdown">
          <i class="fas fa-plus"></i>Keyword
        </button>
        <ul class="dropdown-menu" role="menu">
          <li>
            <button class="btn-link dropdown-item" (click)="toggleAddingKeyword()">Custom Keyword</button>
          </li>
          @if (availableKeywordSuggestions.length) {
            <div class="dropdown-divider"></div>
            <app-components-shared-keyword-research-keyword-suggestion-filter
              [keywordSuggestions]="availableKeywordSuggestions"
              [filteredKeywordSuggestions]="filteredKeywordSuggestions"
              (filterChanged)="filterChanged($event)">
            </app-components-shared-keyword-research-keyword-suggestion-filter>
            <ng-container *ngFor="let keywordSuggestion of filteredKeywordSuggestions | orderBy: keywordSort">
              <li>
                <button class="btn-link dropdown-item text-end"
                (click)="addKeywordSuggestion(keywordSuggestion)">
                <div class="d-flex justify-content-between">
                  <span>
                    {{ keywordSuggestion.KeywordPhrase }}
                    @if (kwrSearchTerm === keywordSuggestion.KeywordPhrase.toLowerCase()) {
                      <i class="text-primary fas fa-star fa-no-margin" title="Keyword Research search term"></i>
                    }
                  </span>
                  <span style="margin-left: 30px;">
                    @if (keywordSuggestion.VolumeDescription) {
                      <span class="badge keyword-column-data-badge" [ngClass]="{
                        'bg-warning': keywordSuggestion.VolumeDescription === 'Low' || keywordSuggestion.VolumeDescription === 'Medium',
                        'bg-success': keywordSuggestion.VolumeDescription === 'High'}">Vol: {{ keywordSuggestion.VolumeDescription }}</span>
                    }
                    @if (keywordSuggestion.CompetitionDescription) {
                      <span class="badge keyword-column-data-badge" [ngClass]="{
                        'bg-warning': keywordSuggestion.CompetitionDescription === 'High' || keywordSuggestion.CompetitionDescription === 'Medium',
                        'bg-success': keywordSuggestion.CompetitionDescription === 'Low'}">Cmp: {{ keywordSuggestion.CompetitionDescription }}</span>
                    }
                    @if (keywordSuggestion.Rank) {
                      <span class="badge bg-secondary keyword-column-data-badge"
                        [ngClass]="{'bg-warning': keywordSuggestion.Rank <= rankThreshold}">Rank: {{ keywordSuggestion.Rank }}</span>
                    }
                  </span>
                </div>
              </button>
              </li>
            </ng-container>
          }
        </ul>
      }
      @else if (pageSuggestion.IsReadOnly) {
        <button class="btn btn-primary me-4 btn-sm display-title-when-disabled"
          type="button" disabled title="Adding keywords not allowed">
          <i class="fas fa-plus"></i>Keyword
        </button>
      }
      @else {
        <button class="btn btn-primary me-4 btn-sm display-title-when-disabled"
          type="button" disabled title="Request keyword research before adding keywords">
          <i class="fas fa-plus"></i>Keyword
        </button>
      }
    </div>
  </div>
}

<ng-template #keyword>
  <div class="input-group mb-0" [formValidation]="formGroup">
    <span class="input-group-text">Keyword</span>
    <input #keywordInput type="text" class="form-control" [formControl]="keywordControl"
      [formValidation]="keywordControl" (keyup.enter)="addKeyword()"/>
  </div>
</ng-template>

<ng-template #area>
  <div class="input-group mb-0" [formValidation]="formGroup">
    <span class="input-group-text">Location</span>
    <input #areaInput type="text" class="form-control" [formControl]="areaControl"
      [formValidation]="areaControl" (keyup.enter)="addKeyword()" />
  </div>
</ng-template>