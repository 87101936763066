import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HaloKeywordSuggestion, KeywordConfig, KeywordResearchTypes, PageSuggestion } from '../../../../shared/models/keywords/keywords';
import { KeywordResearchService } from '../../../../services/keyword-research.service';

@Component({
  selector: 'app-components-shared-keyword-research-pages-and-keywords',
  templateUrl: './pages-and-keywords.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class PagesAndKeywordsComponent {
  @Input() keywordResearchType: KeywordResearchTypes;
  @Input() customer: Boo.Objects.Customer;
  @Input() pageSuggestions: PageSuggestion[];
  @Input() haloKeywordSuggestions: HaloKeywordSuggestion[];
  @Input() keywordConfig: KeywordConfig;
  @Output() keywordReseachRequested = new EventEmitter<void>();
  @Output() newPagesRequested = new EventEmitter<void>();

  constructor(private keywordResearchService: KeywordResearchService) { }

  researchRequested(): void {
    this.keywordReseachRequested.emit();
  }

  pagesRequested(): void {
    if (this.keywordResearchType !== KeywordResearchTypes.SeoOnboarding) {
      return;
    }

    this.newPagesRequested.emit();
  }

  pageSort = (a: PageSuggestion, b: PageSuggestion): number => this.keywordResearchService.pageSort(a, b);

  get selectedPageSuggestions(): PageSuggestion[] {
    return this.pageSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational);
  }
}
