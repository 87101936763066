import { Countries } from 'app/models/enums/Countries';

export class PostalCode {
  public isValid(value: string, country: Countries): boolean {
    switch (country) {
      case Countries.US:
        return this.isUSFormat(value);
      case Countries.CA:
        return this.isCanadaFormat(value);
      // Mexico
      case Countries.MX:
        return this.isUSFormat(value);
      // France
      case Countries.FR:
      // Thailand
      case Countries.TH:
        return this.isFiveNumbers(value);
      // Hungary
      case Countries.HU:
        return this.isFourNumbers(value);
      // Ireland
      case Countries.IE:
        return this.isEircode(value);
      default:
        return true;
    }
  }

  private isCanadaFormat(value: string): boolean {
    return /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1}[\s]{1}\d{1}[A-Z]{1}\d{1}$/i.test(value);
  }

  private isUSFormat(value: string): boolean {
    return /^\d{5}(?:[-\s]\d{4})?$/.test(value);
  }

  private isFiveNumbers(value: string): boolean {
    return /\d{5}/.test(value);
  }

  private isFourNumbers(value: string): boolean {
      return /\d{4}/.test(value);
  }

  private isEircode(value: string): boolean {
    // https://www.citizensinformation.ie/en/consumer/phone-internet-tv-and-postal-services/eircode/
    // case insensitive test even though the Eircode spec requires uppercase.
    return /([AC-FHKNPRTV-Y]\d{2}|D6W)\s*[0-9AC-FHKNPRTV-Y]{4}/i.test(value);
  }
}
