import { Injectable } from '@angular/core';
import Check from 'framework/Check';
import { CustomerAccountTypeService } from '../../../app/services/customer-account-type.service';
import CustomerAccountTypes = Boo.Objects.Enums.CustomerAccountTypes;

@Injectable({
  providedIn: 'root'
})
export class CustomerAccountRouteFactory {
    /*
     * A sensible default for a misconfigured customer account type. 
     */
    private default: IAccountTypeFactoryConfig = { control: 'Credentials', title: 'Account' };

    /*
     * Internal variable to hold the configuration. 
     */
    private accountConfigs: { [id: number]: IAccountTypeFactoryConfig } = {};

    constructor(
      private customerAccountTypeService: CustomerAccountTypeService
    ) {
      // ngOnInit does not get called for injected services
      this.init();
    }
    /**
     * Gets a composition route for the customer account type (based on the customer account type of account). 
     * Never returns null. 
     * @param {Boo.Objects.CustomerAccountBase} account
     * @param {app.services.interfaces.ICustomerAccountService} customerAccountService
     * @param {number} entityId (Either PartnerId or CustomerId)
     */
    create(account: Boo.Objects.CustomerAccountBase, customerAccountService: app.services.interfaces.ICustomerAccountService, entityId: number, extraValidationCallbacks?: app.managecustomer.components.interfaces.CustomerAccountExtraValidationCallback[], editableNotes?: boolean): ICompositionRoute {
        Check.isNotEmpty(account, 'Customer account is required');
        let config: IAccountTypeFactoryConfig = this.accountConfigs[account.CustomerAccountTypeId] || this.default;
        let params: app.managecustomer.components.interfaces.CustomerAccountActivationParams = {
            customerAccountBase: ko.mapping.fromJS(account),
            customerAccountService: customerAccountService,
            entityId: entityId,
            showPort: config.showPort || false,
            canOpenUrl: config.canOpenUrl,
            title: config.title,
            extraValidationCallbacks: extraValidationCallbacks,
            EditableNotes: editableNotes
        };

        return this.component(config.control, params);
    }

    /**
     * Initializes the static class and populates the config.
     */
    private init(): void {
        if (Object.keys(this.accountConfigs).length > 0) {
            return;
        }

        this.accountConfigs[CustomerAccountTypes.GoogleAccount] = { title: this.customerAccountTypeService.getName(CustomerAccountTypes.GoogleAccount), control: 'Credentials' };
        this.accountConfigs[CustomerAccountTypes.GoogleSearchConsole] = { title: this.customerAccountTypeService.getName(CustomerAccountTypes.GoogleSearchConsole), control: 'Credentials' };
        this.accountConfigs[CustomerAccountTypes.GoogleAnalytics] = { title: this.customerAccountTypeService.getName(CustomerAccountTypes.GoogleAnalytics), control: 'Credentials' };
        this.accountConfigs[CustomerAccountTypes.GoogleTagManager] = { title: this.customerAccountTypeService.getName(CustomerAccountTypes.GoogleTagManager), control: 'Credentials' };
        this.accountConfigs[CustomerAccountTypes.GoogleBusinessProfile] = { title: this.customerAccountTypeService.getName(CustomerAccountTypes.GoogleBusinessProfile), control: 'Credentials' };
        this.accountConfigs[CustomerAccountTypes.GoogleBusinessProfile] = { title: this.customerAccountTypeService.getName(CustomerAccountTypes.GoogleBusinessProfile), control: 'Credentials' };

        this.accountConfigs[CustomerAccountTypes.BlogAdmin] = { title: this.customerAccountTypeService.getName(CustomerAccountTypes.BlogAdmin), control: 'SiteCredentials' };
        this.accountConfigs[CustomerAccountTypes.BlogEditor] = { title: this.customerAccountTypeService.getName(CustomerAccountTypes.BlogEditor), control: 'SiteCredentials' };
        this.accountConfigs[CustomerAccountTypes.BlogHosting] = { title: this.customerAccountTypeService.getName(CustomerAccountTypes.BlogHosting), control: 'SiteCredentials' };

        this.accountConfigs[CustomerAccountTypes.WebsiteHosting] = { title: this.customerAccountTypeService.getName(CustomerAccountTypes.WebsiteHosting), control: 'SiteCredentials' };
        this.accountConfigs[CustomerAccountTypes.CMSCustomerAccess] = { title: this.customerAccountTypeService.getName(CustomerAccountTypes.CMSCustomerAccess), control: 'SiteCredentials' };
        this.accountConfigs[CustomerAccountTypes.CMSAdminAccess] = { title: this.customerAccountTypeService.getName(CustomerAccountTypes.CMSAdminAccess), control: 'SiteCredentials' };
        this.accountConfigs[CustomerAccountTypes.FTP] = { title: this.customerAccountTypeService.getName(CustomerAccountTypes.FTP), control: 'SiteCredentials', showPort: true, canOpenUrl: false };
        this.accountConfigs[CustomerAccountTypes.DomainRegistrar] = { title: this.customerAccountTypeService.getName(CustomerAccountTypes.DomainRegistrar), control: 'SiteCredentials' };
    }

    /**
     * Resolves the component and initializes the model class.
     * @param {string} name
     * @param {app.managecustomer.components.interfaces.CustomerAccountActivationParams} params
     */
    private component(name: string, params: app.managecustomer.components.interfaces.CustomerAccountActivationParams): ICompositionRoute {
        return {
            selector: `app-components-customeraccount-${name}`,
            options: params
        };
    }
}

interface IAccountTypeFactoryConfig {
    control: string;
    title: string;
    showPort?: boolean;
    canOpenUrl?: boolean;
}