import { Component, Input } from '@angular/core';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-shared-pages-and-keywords',
  templateUrl: './pages-and-keywords.component.html',
  styleUrls: ['../customer-campaign/customer-campaign.component.scss']
})
export class PagesAndKeywordsComponent {
  @Input() customer: Boo.Objects.Customer;
  @Input() websiteUrls: Boo.Objects.WebsiteUrl[];
  @Input() haloKeywords: Boo.Objects.WebsiteKeywordTracking[];
  @Input() archivedWebsiteUrls: Boo.Objects.ArchivedWebsiteUrl[];
  @Input() archivedKeywords: Boo.Objects.ArchivedKeyword[];
  @Input() readOnlyReasons: string;
  @Input() firstPageKeywordPhrases: string[];
  @Input() restrictions: Boo.Objects.CustomerCampaign.Enums.RestrictionTypes[];
  @Input() reviewSource: Boo.OnsiteRecommendations.Models.Enums.ReviewSources;

  constructor(private customerCampaignService: CustomerCampaignService) { }

  isActiveStatus(websiteUrl: Boo.Objects.WebsiteUrl): boolean {
    return this.customerCampaignService.isActiveStatus(websiteUrl);
  }

  get activeWebsiteUrlCount(): number {
    return this.websiteUrls ? this.websiteUrls.filter(websiteUrl => this.isActiveStatus(websiteUrl)).length : 0;
  }
}
