/* tslint:disable */

export default class partner {
    public AccountExecutiveUserId = <any>null
    public Address1 = <any>null
    public Address2 = <any>null
    public AllowsTextMessaging = false
    public BccEmailAddress = <any>null
    public BccEmailAddressDisplayName = <any>null
    public BillingContactEmailAddress = <any>null
    public BillingContactName = <any>null
    public BillingContactPhone = <any>null
    public City = <any>null
    public CountryId = 0
    public CurrencyId = 0
    public DefaultLanguageId = 0
    public FaviconS3Url = <any>null
    public FromEmailAddress = <any>null
    public FromEmailAddressDisplayName = <any>null
    public GoogleConsumerKey = <any>null
    public GoogleConsumerSecret = <any>null
    public HasFinanceApproved = false
    public InsertedDate = <any>null
    public IsActive = false
    public IsBillingDateChangeEnabled = false
    public IsBillingVisible = false
    public IsFinancialIntegrationNeeded = false
    public IsLive = false
    public IsSingleSignonRequired = false
    public IsUserAndContactRestricted = false
    public IsWhiteLabel = false
    public LaunchDate = <any>null
    public LogoLargeS3Url = <any>null
    public LogoSmallS3Url = <any>null
    public LogoJpgS3Url = <any>null
    public MyMarketInfoSubdomain = <any>null
    public Name = <any>null
    public Net = 0
    public PartnerId = 0
    public PartnerManagerUserId = 0
    public PartnerChannelId = 0
    public PartnerUserLevelPermissions = <any>null
    public PostalCode = <any>null
    public ReportingUrl = <any>null
    public ReportsEmailAddress = <any>null
    public ResellerAccessToken = <any>null
    public ResellerId = <any>null
    public RevsharePercentSetup = 0.0
    public SalesPageUrl = <any>null
    public SalesPhoneNumber = <any>null
    public SalesUserId = 0
    public ServiceUrl = <any>null
    public SmtpHost = <any>null
    public SmtpPassword = <any>null
    public SmtpPort = <any>null
    public SmtpUsername = <any>null
    public StateId = 0
    public SupportEmailAddress = <any>null
    public SystemNotificationEmailAddress = <any>null
    public SupportLevelId = 0
    public SupportPhoneNumber = <any>null
    public TechnicalContactEmail = <any>null
    public TechnicalContactName = <any>null
    public TechnicalContactPhone = <any>null
    public TextMessagingPhoneNumber = <any>null
    public TimezoneOffset = 0
    public Users = <any>null
    public WwwUrl = <any>null
    public ReportIncludeSSOLink = false
    public LogoJpg = <any>null
    public BusinessEntityId = <any>null
    public IsSmtpPasswordSet = false
    public SetSmtpPassword = ""
    public BusinessDevelopmentRepUserId = <any>null
    public StorefrontId = <any>null
    public DeactivationDate = <any>null
    public PartnerTicketTypes = <any>null
    public UsesDiscoveryProcess = false
    public SchedulingLink = <any>null
    public OnboardingSchedulingLink = <any>null
}
