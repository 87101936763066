import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KeywordSuggestionFilter, KeywordSuggestion, } from '../../../../shared/models/keywords/keywords';
import { KeywordResearchService } from '../../../../services/keyword-research.service';

@Component({
  selector: 'app-components-shared-keyword-research-keyword-suggestion-filter',
  templateUrl: './keyword-suggestion-filter.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class KeywordSuggestionFilterComponent implements OnInit {
  @Input() keywordSuggestions: KeywordSuggestion[] = [];
  @Input() filteredKeywordSuggestions: KeywordSuggestion[] = [];
  @Output() filterChanged: EventEmitter<KeywordSuggestionFilter> = new EventEmitter<KeywordSuggestionFilter>();

  formGroup: FormGroup;

  constructor(private keywordResearchService: KeywordResearchService) { }

  ngOnInit(): void {
    this.setupForm();
    this.filterChanged.emit(this.keywordResearchService.defaultKeywordSuggestionFilter);
  }

  setupForm(): void {
    let defaultFilter =this.keywordResearchService.defaultKeywordSuggestionFilter;
    this.formGroup = new FormGroup({ 
      keyword: new FormControl(defaultFilter.keyword, null),
      fromRank: new FormControl(defaultFilter.fromRank, [ Validators.required ]),
      toRank: new FormControl(defaultFilter.toRank, [ Validators.required ]),
      volume: new FormControl(defaultFilter.volume, null),
      competition: new FormControl(defaultFilter.competition, null),
    }, null);

    this.formGroup.valueChanges.subscribe((value) => {
      this.filterChanged.emit({
        keyword: value.keyword.toLowerCase(),
        fromRank: value.fromRank,
        toRank: value.toRank,
        volume: value.volume,
        competition: value.competition
      } as KeywordSuggestionFilter);
    });
  }

  resetFilter(): void {
    this.formGroup.reset(this.keywordResearchService.defaultKeywordSuggestionFilter);
  }
}