<button class="btn btn-success dropdown-toggle" type="button" data-toggle="dropdown" [disabled]="websiteUrl?.IsReadOnly || restrictions.includes(customerCampaignRestrictionTypes_ReadOnly)"
  [ngClass]="{'btn-success': websiteUrl.StatusId === websiteUrlStatuses_Active, 'btn-primary': websiteUrl.StatusId === websiteUrlStatuses_Limited, 'btn-secondary': websiteUrl.StatusId === websiteUrlStatuses_New }">
  <ng-container *ngIf="websiteUrl.StatusId === websiteUrlStatuses_Active">Active</ng-container>
  <ng-container *ngIf="websiteUrl.StatusId === websiteUrlStatuses_Limited">Limited</ng-container>
  <ng-container *ngIf="websiteUrl.StatusId === websiteUrlStatuses_New">New</ng-container>
</button>
<ul class="dropdown-menu" role="menu">
  <ng-container *ngIf="websiteUrl.StatusId === websiteUrlStatuses_Active">
    <li>
      <button class="btn-link dropdown-item" (click)="updateWebsiteUrlStatus(websiteUrl, websiteUrlStatuses_New)">New</button>
    </li>
    <li>
      <button class="btn-link dropdown-item" (click)="updateWebsiteUrlStatus(websiteUrl, websiteUrlStatuses_Limited)">Limited</button>
    </li>
    <li>
      <button class="btn-link dropdown-item disabled">Active</button>
    </li>
  </ng-container>
  <ng-container *ngIf="websiteUrl.StatusId === websiteUrlStatuses_Limited">
    <li>
      <button class="btn-link dropdown-item" (click)="updateWebsiteUrlStatus(websiteUrl, websiteUrlStatuses_New)">New</button>
    </li>
    <li>
      <button class="btn-link dropdown-item disabled">Limited</button>
    </li>
    <li>
      <button class="btn-link dropdown-item" (click)="updateWebsiteUrlStatus(websiteUrl, websiteUrlStatuses_Active)">Active</button>
    </li>
  </ng-container>
  <ng-container *ngIf="websiteUrl.StatusId === websiteUrlStatuses_New">
    <li>
      <button class="btn-link dropdown-item disabled">New</button>
    </li>
    <li>
      <button class="btn-link dropdown-item" (click)="updateWebsiteUrlStatus(websiteUrl, websiteUrlStatuses_Limited)">Limited</button>
    </li>
    <li>
      <button class="btn-link dropdown-item" (click)="updateWebsiteUrlStatus(websiteUrl, websiteUrlStatuses_Active)">Active</button>
    </li>
  </ng-container>
  <div class="dropdown-divider"></div>
  <li>
    <button class="btn-link dropdown-item" (click)="updateWebsiteUrlStatus(websiteUrl, websiteUrlStatuses_Archived)">Archive</button>
  </li>
</ul>