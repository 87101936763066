<div [loadingOverlay]="isLoading" class="card">
	<div class="card-header">
		Add Customer
	</div>
	<div class="card-body" [formGroup]="formGroup">
		<div class="row">
			<div class="col-md-4">
				<h6>Customer Contact</h6>
				<hr>
			</div>
			<div class="col-md-8">
				<h6>Basic Information</h6>
				<hr>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<div class="form-group">
					<label class="control-label">First Name</label>
					<input formControlName="FirstName" [formValidation]="formGroup.get('FirstName')" class="form-control"/>
				</div>
				<div class="form-group">
					<label class="control-label">Last Name</label>
					<input formControlName="LastName" [formValidation]="formGroup.get('LastName')" class="form-control"/>
				</div>
				<div class="form-group">
					<label class="control-label">Email</label>
					<input formControlName="Email" [formValidation]="formGroup.get('Email')" class="form-control"/>
				</div>
				<div class="form-group">
					<label class="control-label">Phone</label>
					<input formControlName="Phone" [formValidation]="formGroup.get('Phone')" class="form-control"/>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<label class="control-label">Company Name</label>
					<input formControlName="Company" [formValidation]="formGroup.get('Company')" class="form-control"/>
				</div>
				<div class="form-group">
					<label class="control-label">Website</label>
					<input formControlName="Url" [formValidation]="formGroup.get('Url')" class="form-control"/>
				</div>
				<div class="form-group">
					<label class="control-label">Language</label>
					<ng-select [items]="languages" [clearable]="true" placeholder="Choose..." bindLabel="Name"
						bindValue="LanguageId" formControlName="LanguageId" [formValidation]="formGroup.get('LanguageId')" [virtualScroll]="true"></ng-select>
				</div>
				<div class="form-group">
					<label class="control-label">Country</label>
					<ng-select [items]="countries" [clearable]="true" placeholder="Choose..." bindLabel="Name"
						bindValue="CountryId" formControlName="CountryId" [formValidation]="formGroup.get('CountryId')" [virtualScroll]="true"></ng-select>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<label class="control-label">
						<span>Merchant Id</span>
						<span class="text-muted">(Optional)</span>
						<app-components-helptext [key]="'AddCustomer_MerchantId'"></app-components-helptext></label>
					<input formControlName="MerchantId" class="form-control"/>
				</div>
				<div class="form-group">
					<label class="control-label">
						<span>Referred By</span>
						<span class="text-muted">(Optional)</span>
					</label>
					<input formControlName="ReferredBy" class="form-control"/>
				</div>
				<ng-container *ngIf="partner.PartnerId === 1">
					<div class="form-group">
						<label class="control-label">LeadSource</label>
						<ng-select [items]="leadSources" [clearable]="true" placeholder="Choose..." bindLabel="Name"
							bindValue="LeadSourceId" formControlName="LeadSourceId" [virtualScroll]="true"
							[addTag]="addLeadSource" addTagText="Add Lead Source"></ng-select>
					</div>
				</ng-container>
				<ng-container *ngIf="partner.PartnerId !== 1">
					<div class="text-end">
						<button (click)="showAdvancedMerchantInfo()" class="btn btn-link">Advanced Merchant Fields</button>
					</div>
				</ng-container>
			</div>
		</div>
		@if(merchantFieldLabelMap) {
			<div [hidden]="!viewAdvancedMerchantInfo" class="row">
				<hr/>
				<div class="col-md-6">
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.IdLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantId" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.SourceLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantSource" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.ProposalIdLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantProposalId" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.CategoryLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantCategory" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.CategoryIdLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantCategoryId" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.SubCategoryLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantSubCategory" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.SubCategoryIdLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantSubCategoryId" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.ConsultantLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantConsultant" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.ConsultantContactLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantConsultantContact" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.UdacLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantUdac" class="form-control"/>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.ProductCodeLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantProductCode" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.ItemIdLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantItemId" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.MiscIdLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantMiscId" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.RegionLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantRegion" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.CustomerValueLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantCustomerValue" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.ContractEndDateLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantContractEndDate" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.ServiceLevelLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantServiceLevel" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.SitePublishDateLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantSitePublishDate" class="form-control" placeholder="MM-DD-YYYY"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.CampaignManagerLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantCampaignManager" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap.CampaignManagerContactLabel.Value}}</label>
						<div class="col-md-7">
							<input formControlName="MerchantCampaignManagerContact" class="form-control"/>
						</div>
					</div>
				</div>
			</div>
		}
	</div>
	<div class="card-footer text-end">
		<button (click)="save()" class="btn btn-primary">
			<i class="fa fa-plus"></i>
			Add Customer
		</button>
	</div>
</div>
