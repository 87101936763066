<div class="card">
  <div class="card-header">
    Blogs
  </div>
  <div [loadingOverlay]="isLoading()" class="card-body">
    <compose Selector="app-components-specialist-blogmetrics"></compose>
    <div class="card">
      <div class="card-header">
        <span>Search</span>
        <a (click)="searchCollapsed(!searchCollapsed())" data-toggle="collapse" data-target="#searchBlogs"
          class="float-end">
          <i [hidden]="searchCollapsed()" class="far fa-caret-square-up"></i>
          <i [hidden]="!searchCollapsed()" class="far fa-caret-square-down"></i>
        </a>
      </div>
      <div class="collapse show" id="searchBlogs">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">Blog URL</label>
                <input [dataValidation]="{ placement: 'top', value: blogUrl }" [ngModel]="blogUrl()"
                  (ngModelChange)="blogUrl($event)" [ngModelOptions]="{ standalone: true }"
                  [returnKey]="searchBlogs.bind(this)" type="text" class="form-control" />
              </div>
              <div class="form-group">
                <label class="control-label">Deployment Status</label>
                <select [dataValidation]="{ placement: 'top', value: amazonDeploymentStatusId }"
                  [ngModel]="amazonDeploymentStatusId()" (ngModelChange)="amazonDeploymentStatusId($event)"
                  [ngModelOptions]="{ standalone: true }" [returnKey]="searchBlogs.bind(this)" class="form-select">
                  <option *ngFor="let option of amazonDeploymentStatuses()" [ngValue]="option.Id">{{ option.Name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label class="control-label">Link Location Active</label>
                <select [dataValidation]="{ placement: 'top', value: linkLocationStatus }"
                  [ngModel]="linkLocationStatus()" (ngModelChange)="linkLocationStatus($event)"
                  [ngModelOptions]="{ standalone: true }" [returnKey]="searchBlogs.bind(this)" type="number"
                  class="form-select">
                  <option value="0">All</option>
                  <option value="1">Active</option>
                  <option value="2">Inactive</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">Link Location ID</label>
                <input [dataValidation]="{ placement: 'top', value: linkLocationId }" [ngModel]="linkLocationId()"
                  (ngModelChange)="linkLocationId($event)" [ngModelOptions]="{ standalone: true }"
                  [returnKey]="searchBlogs.bind(this)" type="number" class="form-control" />
              </div>
              <div class="form-group">
                <label class="control-label">Vertical Name</label>
                <div [dataValidation]="{ placement: 'top', value: verticalId }">
                  <app-components-verticalselector [ngModel]="verticalId()"
                  (ngModelChange)="verticalId($event)" [ngModelOptions]="{ standalone: true }"
                  [returnKey]="searchBlogs.bind(this)"></app-components-verticalselector>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-md-12">Language</label>
                <select [dataValidation]="{ placement: 'top', value: languageId }" [ngModel]="languageId()"
                  (ngModelChange)="languageId($event)" [ngModelOptions]="{ standalone: true }"
                  [returnKey]="searchBlogs.bind(this)" class="form-select">
                  <option *ngFor="let option of languages" [ngValue]="option.LanguageId">{{ option.Name }}</option>
                </select>
              </div>
              <div class="form-group">
                <label class="control-label">Country</label>
                <select [dataValidation]="{ placement: 'top', value: countryId }" [ngModel]="countryId()"
                  (ngModelChange)="countryId($event)" [ngModelOptions]="{ standalone: true }"
                  [returnKey]="searchBlogs.bind(this)" class="form-select">
                  <option *ngFor="let option of countries" [ngValue]="option.CountryId">{{ option.Name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button (click)="getDownBlogs()" class="btn btn-link text-start">
            <i class="fa fa-search"></i>
            View Down Blogs
          </button>
          <button (click)="searchBlogs()" class="btn btn-primary float-end">
            <i class="fa fa-search"></i>
            Search
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-table [items]="blogs" [exportFilename]="'blog-sites'">
          <ng-template #tableHeader>
            <tr>
              <th export sort field="LinkLocationId">Link Location Id</th>
              <th export sort asc field="BlogUrl">Blog Url</th>
              <th export sort field="VerticalName">Vertical Name</th>
              <th export sort field="Language">Language</th>
              <th export sort field="Country">Country</th>
              <th export sort field="LastResponseTimeInMilliseconds">Last Response Time(ms)</th>
              <th export sort field="LinkLocationStatus">Link Location Active</th>
              <th export sort field="AmazonDeploymentStatus">Deployment Status</th>
              <th>View/Edit</th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-blog>
            <tr>
              <td>{{blog.LinkLocationId}}</td>
              <td>{{blog.BlogUrl}}</td>
              <td>{{blog.VerticalName}}</td>
              <td>{{blog.Language}}</td>
              <td>{{blog.Country}}</td>
              <td>{{blog.LastResponseTimeInMilliseconds}}</td>
              <td>{{(blog.LinkLocationStatus) ? 'Active' : 'Inactive'}}</td>
              <td>{{blog.AmazonDeploymentStatus}}</td>
              <td>
                <button (click)="editBlog(blog)" class="btn btn-primary">
                  <i class="fas fa-external-link-square-alt"></i>
                  Open
                </button>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
</div>