<div class="card" [loadingOverlay]="isLoading" *withPermissions="let allow of ['CanManagePartnerEmails']">
  <div class="card-header">
    {{partner.PartnerName}} Emails
    <button (click)="close()" class="btn-close" title="Close"></button>
  </div>
  <div class="card-body">
    <ng-container *ngIf="arePartnerEmailsDisabled">
      <div class="alert alert-warning">
        <i class="fa fa-exclamation-triangle"></i>
        Emails are disabled for this partner.
      </div>
    </ng-container>
    <app-table [items]="notificationDefinitions">
      <ng-template #tableHeader>
        <tr>
          <th class="col-10" sort field="Name">Type</th>
          <th class="col-1">Templates</th>
          <th class="col-1" sort field="EnabledForSubscriber">Enabled</th>
        </tr>
      </ng-template>
      <ng-template #tableBody let-item>
        <ng-container>
          <tr>
            <td><app-components-helptext [key]="'Notification_' + item.Name"></app-components-helptext>{{item.Name}}</td>
            <td>
              <button class="btn btn-info" (click)="openTemplates(item)">
                <span><i class="fa fa-magnifying-glass"></i> View</span>
              </button>
            </td>
            <td>
              <shared-components-customchoice type="checkbox" [(ngModel)]="item.EnabledForSubscriber" [disabled]="!allow().CanManagePartnerEmails() || !item.Disableable"></shared-components-customchoice>
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </app-table>
  </div>
  <div class="pb-2 pe-3" *ngIf="allow().CanManagePartnerEmails()">
    <button class="btn btn-success float-end" (click)="save()">Save Changes</button>
  </div>
</div>