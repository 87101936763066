import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';

@Component({
  selector: 'app-components-ticket-onsiterecommendationscomplete',
  templateUrl: './OnsiteRecommendationsComplete.component.html'
})
export class OnsiteRecommendationsCompleteComponent extends BaseComponent {

  customerJS: Boo.Objects.Customer;

  customerCampaignRestrictions: Boo.Objects.CustomerCampaign.Enums.RestrictionTypes[] = [
    Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.DisablePageUpdate,
    Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.HideStrategyUpdateRequestButton
  ];

  canActivate?(_user: Boo.Objects.User, _partner: Boo.Objects.Partner, params: any): boolean {
    this.customerJS = ko.mapping.toJS(params.customer);
    return true;
  }
}
