<div class="d-flex align-items-center">
  <div class="keyword-column">
    @if (pageSuggestion.IsReadOnly) {
      <button class="btn btn-secondary me-4 btn-sm display-title-when-disabled" type="button" title="Keyword removal not allowed" disabled>
        <i class="fas fa-times fa-no-margin"></i>
      </button>
    } @else {
      <button class="btn btn-secondary me-4 btn-sm" type="button" (click)="removeKeyword()" title="Remove Keyword">
        <i class="fas fa-times fa-no-margin"></i>
      </button>
    }
    {{ keywordSuggestion.KeywordPhrase }}
    @if (kwrSearchTerm === keywordSuggestion.KeywordPhrase.toLowerCase()) {
      <i class="text-primary fas fa-star fa-no-margin" title="Keyword Research search term"></i>
    }
  </div>
  <div class="keyword-column-data">
    @if (keywordSuggestion.VolumeDescription) {
      <span>Volume: <span [ngClass]="{
        'text-warning': keywordSuggestion.VolumeDescription === 'Low' || keywordSuggestion.VolumeDescription === 'Medium',
        'text-success': keywordSuggestion.VolumeDescription === 'High'}">{{keywordSuggestion.VolumeDescription}}</span>
      </span>
    }
  </div>
  <div class="keyword-column-data">
    @if (keywordSuggestion.CompetitionDescription) {
      <span>Competition: <span [ngClass]="{
        'text-warning': keywordSuggestion.CompetitionDescription === 'High' || keywordSuggestion.CompetitionDescription === 'Medium',
        'text-success': keywordSuggestion.CompetitionDescription === 'Low'}">{{keywordSuggestion.CompetitionDescription}}</span>
      </span>
    }
  </div>
  <div class="keyword-column-data">
    @if (keywordSuggestion.Rank) {
      <span>Rank: {{keywordSuggestion.Rank}}</span>
    }
  </div>
  @if (validationMessage) {
    <div class="flex-grow-1 text-danger fw-normal me-3 text-end">
      {{ validationMessage }}
    </div>
  }
</div>