import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from '../../shared/shared.module';
import { HelpTextComponent } from './HelpText/HelpText.component';
import { HelpTextEditorComponent } from './HelpTextEditor/HelpTextEditor.component';
import { CustomerFilesComponent } from './customer-files/customer-files.component';
import { CredentialsComponent } from './customer-account/credentials/credentials.component';
import { SiteCredentialsComponent } from './customer-account/site-credentials/site-credentials.component';
import { ValidationChecklistComponent } from './ValidationChecklist/ValidationChecklist.component';
import { CustomReportsComponent } from './CustomReports/CustomReports.component';
import { CompositionRouteGridComponent } from './CompositionRouteGrid/CompositionRouteGrid.component';
import { EmptyComponent } from './empty/empty.component';
import { LanguageQuestionGroupComponent } from './LanguageQuestionGroup/LanguageQuestionGroup.component';
import { TimeZoneDateTimeComponent } from './time-zone-date-time/time-zone-date-time.component';
import { VerticalSelectorComponent } from './VerticalSelector/VerticalSelector.component';
import { UploadFileComponent } from './UploadFile/UploadFile.component';
import { ODataGridComponent } from './o-data-grid/o-data-grid.component';
import { OptionToggleComponent } from './OptionToggle/OptionToggle.component';
import { PublishOptionsComponent } from './PublishOptions/PublishOptions.component';
import { PagerComponent } from './Pager/Pager.component';
import { RecentOrdersComponent } from './RecentOrders/RecentOrders.component';
import { CmsSelectionComponent } from '../managecustomer/WebsiteAccess/Cms/CmsSelection.Component';
import { DudaSiteAdminSSOButtonComponent } from '../websites/DudaSiteAdminSSOButton/DudaSiteAdminSSOButton.Component';
import { ChecklistComponent } from './Checklist/checklist/checklist.component';
import { UpaChecklistComponent } from './Checklist/upa-checklist/upa-checklist.component';
import { AddressComponent } from './local-profiles/address/address.component';
import { BusinessCitationSubscriptionsComponent } from './local-profiles/business-citation-subscriptions/business-citation-subscriptions.component';
import { LocalProfilesComponent } from './local-profiles/local-profiles/local-profiles.component';
import { LocalProfileComponent } from './local-profiles/local-profile/local-profile.component';
import { MinimumLocalProfileComponent } from './local-profiles/minimum-local-profile/minimum-local-profile.component';
import { LocalProfileListComponent } from './local-profiles/local-profile-list/local-profile-list.component';
import { ViewLocalProfilesComponent } from './local-profiles/view-local-profiles/view-local-profiles.component';
import { ViewLocalProfileComponent } from './local-profiles/view-local-profile/view-local-profile.component';
import { ViewLocalProfileListComponent } from './local-profiles/view-local-profile-list/view-local-profile-list.component';
import { TaskFilesComponent } from './task-files/task-files.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { NotificationTemplateComponent } from './notification-template/notification-template.component';
import { NotificationDefinitionComponent } from './notification-definition/notification-definition.component';
import { CustomerCampaignComponent } from './customer-campaign/customer-campaign/customer-campaign.component';
import { CampaignSummaryComponent } from './customer-campaign/campaign-summary/campaign-summary.component';
import { PagesAndKeywordsComponent } from './customer-campaign/pages-and-keywords/pages-and-keywords.component';
import { PageComponent } from './customer-campaign/page/page.component';
import { KeywordComponent } from './customer-campaign/keyword/keyword.component';
import { HaloKeywordsComponent } from './customer-campaign/halo-keywords/halo-keywords.component';
import { HaloKeywordComponent } from './customer-campaign/halo-keyword/halo-keyword.component';
import { ArchivedKeywordsComponent } from './customer-campaign/archived-keywords/archived-keywords.component';
import { ArchivedPagesComponent } from './customer-campaign/archived-pages/archived-pages.component';
import { OnsiteTaskInformationComponent } from './customer-campaign/onsite-task-information/onsite-task-information.component';
import { TaskApprovalComponent } from './customer-campaign/task-approval/task-approval.component';
import { AddPageComponent } from './customer-campaign/add-page/add-page.component';
import { AddKeywordComponent } from './customer-campaign/add-keyword/add-keyword.component';
import { PageStatusComponent } from './customer-campaign/page-status/page-status.component';
import { PageUpdateComponent } from './customer-campaign/page-update/page-update.component';
import { KeywordResearchComponent } from './keyword-research/keyword-research/keyword-research.component';
import { AddKeywordComponent as KeywordResearchAddKeywordComponent } from './keyword-research/add-keyword/add-keyword.component';
import { AddHaloKeywordComponent as KeywordResearchAddHaloKeywordComponent } from './keyword-research/add-halo-keyword/add-halo-keyword.component';
import { AddPageComponent as KeywordResearchAddPageComponent } from './keyword-research/add-page/add-page.component';
import { KeywordComponent as KeywordResearchKeywordComponent } from './keyword-research/keyword/keyword.component';
import { KeywordSuggestionFilterComponent as KeywordResearchKeywordSuggestionFilterComponent } from './keyword-research/keyword-suggestion-filter/keyword-suggestion-filter.component';
import { PageComponent as KeywordResearchPageComponent } from './keyword-research/page/page.component';
import { PagesAndKeywordsComponent as KeywordResearchPagesAndKeywordsComponent } from './keyword-research/pages-and-keywords/pages-and-keywords.component';
import { HaloKeywordComponent as KeywordResearchHaloKeywordComponent } from './keyword-research/halo-keyword/halo-keyword.component';
import { HaloKeywordsComponent as KeywordResearchHaloKeywordsComponent } from './keyword-research/halo-keywords/halo-keywords.component';
import { SummaryComponent as KeywordResearchSummaryComponent} from './keyword-research/summary/summary.component';
import { AddKeywordResearchComponent as KeywordResearchAddKeywordResearchComponent } from './keyword-research/add-keyword-research/add-keyword-research.component';
import { ProcessKeywordResearchComponent as KeywordResearchProcessKeywordResearchComponent } from './keyword-research/process-keyword-research/process-keyword-research.component';
import { SeoActionPlanComponent as KeywordResearchSeoActionPlanComponent } from './keyword-research/seo-action-plan/seo-action-plan.component';

@NgModule({
  declarations: [
    ValidationChecklistComponent,
    CmsSelectionComponent,
    CustomerFilesComponent,
    TaskFilesComponent,
    CustomReportsComponent,
    CredentialsComponent,
    CompositionRouteGridComponent,
    DudaSiteAdminSSOButtonComponent,
    EmptyComponent,
    HelpTextComponent,
    HelpTextEditorComponent,
    LanguageQuestionGroupComponent,
    AddressComponent,
    BusinessCitationSubscriptionsComponent,
    LocalProfilesComponent,
    LocalProfileListComponent,
    LocalProfileComponent,
    MinimumLocalProfileComponent,
    ViewLocalProfilesComponent,
    ViewLocalProfileComponent,
    ViewLocalProfileListComponent,
    ODataGridComponent,
    OptionToggleComponent,
    PagerComponent,
    PublishOptionsComponent,
    RecentOrdersComponent,
    SiteCredentialsComponent,
    TimeZoneDateTimeComponent,
    UploadFileComponent,
    VerticalSelectorComponent,
    ChecklistComponent,
    UpaChecklistComponent,
    NotificationModalComponent,
    NotificationTemplateComponent,
    NotificationDefinitionComponent,
    CustomerCampaignComponent,
    CampaignSummaryComponent,
    PagesAndKeywordsComponent,
    PageComponent,
    KeywordComponent,
    HaloKeywordsComponent,
    HaloKeywordComponent,
    ArchivedKeywordsComponent,
    ArchivedPagesComponent,
    OnsiteTaskInformationComponent,
    TaskApprovalComponent,
    AddPageComponent,
    AddKeywordComponent,
    PageStatusComponent,
    PageUpdateComponent,
    KeywordResearchComponent,
    KeywordResearchAddKeywordComponent,
    KeywordResearchAddHaloKeywordComponent,
    KeywordResearchAddPageComponent,
    KeywordResearchKeywordComponent,
    KeywordResearchKeywordSuggestionFilterComponent,
    KeywordResearchPageComponent,
    KeywordResearchPagesAndKeywordsComponent,
    KeywordResearchHaloKeywordComponent,
    KeywordResearchHaloKeywordsComponent,
    KeywordResearchSummaryComponent,
    KeywordResearchAddKeywordResearchComponent,
    KeywordResearchProcessKeywordResearchComponent,
    KeywordResearchSeoActionPlanComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DragDropModule
  ],
  exports: [
    HelpTextComponent,
    HelpTextEditorComponent,
    RecentOrdersComponent,
    CmsSelectionComponent,
    CustomerFilesComponent,
    TaskFilesComponent,
    DudaSiteAdminSSOButtonComponent,
    ODataGridComponent,
    PublishOptionsComponent,
    ChecklistComponent,
    UpaChecklistComponent,
    LocalProfilesComponent,
    LocalProfileComponent,
    MinimumLocalProfileComponent,
    LocalProfileListComponent,
    ViewLocalProfilesComponent,
    ViewLocalProfileComponent,
    ViewLocalProfileListComponent,
    CustomerCampaignComponent,
    KeywordResearchComponent,
    VerticalSelectorComponent
  ]
})
export class SharedComponentsModule { }
