<button type="button" [ngClass]='websiteUrlTask | onsiteInfoButtonCss' data-toggle="dropdown" [disabled]="taskActionPending">
  {{taskTypeLabel}}
</button>
<ul class="dropdown-menu" role="menu" >
  <strong class="task-data-header">{{websiteUrlTask?.TaskTypeName}}</strong>
  <div class="dropdown-divider"></div>
  <div class="task-data">
    <div>Task ID: {{websiteUrlTask.TaskId}}</div>
    <div>Status: {{websiteUrlTask.WorkflowState}}</div>
    <div>Verification: {{websiteUrlTask.DisplayStatus}}</div>
    <div>Ordered Date: {{(websiteUrlTask.TaskInsertedDate | date: 'shortDate') || 'None'}}</div>
    <div>Written Date: {{(websiteUrlTask.TaskCompletedDate | date: 'shortDate') || 'None'}}</div>
    <div>Published Date: {{(websiteUrlTask.TaskPublishedDate | date: 'shortDate') || 'None'}}</div>
    <div>Verified Date: {{(websiteUrlTask.TaskVerifiedDate | date: 'shortDate') || 'None'}}</div>
    <div *ngIf="websiteUrlTask.TaskActionUrl">
      <a [externalLink]="{ url: websiteUrlTask.TaskActionUrl, text: 'File' }" rel="noreferrer"></a>
    </div>
  </div> 

  <div *ngIf="allowApproveReject || allowVerification" class="dropdown-divider"></div>
  <ng-container *ngIf="allowVerification">
    <li>
      <button class="btn-link dropdown-item" (click)="verifyTask()" [disabled]="restrictions.includes(customerCampaignRestrictionTypes_DisableTaskActions)">
        <i class="fas fa-sync text-warning"></i>
        Verify
      </button>
    </li>
  </ng-container>
  <ng-container *ngIf="allowApproveReject">
    <app-components-shared-task-approval
      [websiteUrlTask]="websiteUrlTask"
      [restrictions]="restrictions"
      [reviewSource]="reviewSource"
      (taskModified)="reloadTask($event)"
      (taskActionPending)="setTaskActionPending($event)">
    </app-components-shared-task-approval>
  </ng-container>
</ul>
