import { Component, Input, output } from '@angular/core';
import { HaloKeywordSuggestion, KeywordConfig, KeywordResearchTypes, PageSuggestion, PageSuggestionSources } from '../../../../shared/models/keywords/keywords';

@Component({
  selector: 'app-components-shared-keyword-research-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class SummaryComponent {
  @Input() keywordResearchType: KeywordResearchTypes;
  @Input() customer: Boo.Objects.Customer;
  @Input() pageSuggestions: PageSuggestion[];
  @Input() haloKeywordSuggestions: HaloKeywordSuggestion[];
  @Input() keywordConfig: KeywordConfig;
  campaignTypeChanged = output();

  KeywordResearchTypes_StrategyUpdate = KeywordResearchTypes.StrategyUpdate;

  get pageCount(): number {
    if (!this.pageSuggestions) {
      return 0;
    }

    switch (this.keywordResearchType) {
      case KeywordResearchTypes.SeoOnboarding:
        return this.pageSuggestions.filter(x => x.IsCampaignNational === this.customer.IsCampaignNational && x.IsSelected).length;
      case KeywordResearchTypes.StrategyUpdate:
        return this.pageSuggestions.filter(x => x.IsCampaignNational === this.customer.IsCampaignNational && (x.SourceId === PageSuggestionSources.CustomerCampaign || x.IsSelected)).length;
      default:
        return 0;
    }
  }

  get keywordCount(): number {
    if (!this.pageSuggestions) {
      return 0;
    }

    switch (this.keywordResearchType) {
      case KeywordResearchTypes.SeoOnboarding:
        return this.pageSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational)
          .flatMap(x => x.KeywordSuggestions)
          .filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).length;
      case KeywordResearchTypes.StrategyUpdate:
        return this.pageSuggestions.filter(x => x.IsCampaignNational === this.customer.IsCampaignNational)
          .flatMap(x => x.KeywordSuggestions)
          .filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).length;
      default:
        return 0;
    }
  }

  get haloCount(): number {
    return this.haloKeywordSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).length;
  }
}
