<div class="d-flex align-items-center">
  <div class="keyword-column">
    <button class="btn btn-secondary me-4 btn-sm" type="button" (click)="removeKeyword()" title="Remove Keyword">
      <i class="fas fa-times fa-no-margin"></i>
    </button>
    {{ haloKeywordSuggestion.KeywordPhrase }}
  </div>
  <div class="keyword-column-data">
    @if (haloKeywordSuggestion.VolumeDescription) {
      <span>Volume: <span [ngClass]="{
        'text-warning': haloKeywordSuggestion.VolumeDescription === 'Low' || haloKeywordSuggestion.VolumeDescription === 'Medium',
        'text-success': haloKeywordSuggestion.VolumeDescription === 'High'}">{{haloKeywordSuggestion.VolumeDescription}}</span>
      </span>
    }
  </div>
  <div class="keyword-column-data">
    @if (haloKeywordSuggestion.CompetitionDescription) {
      <span>Competition: <span [ngClass]="{
        'text-warning': haloKeywordSuggestion.CompetitionDescription === 'High' || haloKeywordSuggestion.CompetitionDescription === 'Medium',
        'text-success': haloKeywordSuggestion.CompetitionDescription === 'Low'}">{{haloKeywordSuggestion.CompetitionDescription}}</span>
      </span>
    }
  </div>
  <div class="keyword-column-data">
    @if (haloKeywordSuggestion.Rank) {
      <span>Rank: {{haloKeywordSuggestion.Rank}}</span>
    }
  </div>
  @if (validationMessage) {
    <div class="flex-grow-1 text-danger fw-normal me-3 text-end">
      {{ validationMessage }}
    </div>
  }
</div>