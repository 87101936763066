import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import CustomValidators from '../../../../shared/custom-validators';
import { KeywordResearch, PageSuggestion } from '../../../../shared/models/keywords/keywords';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-shared-keyword-research-add-keyword-research',
  templateUrl: './add-keyword-research.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class AddKeywordResearchComponent implements OnInit {
  @Input() customer: Boo.Objects.Customer;
  @Input() pageSuggestion: PageSuggestion;
  @Output() researchRequested = new EventEmitter<void>();

  @ViewChild('serviceInput') serviceInput: ElementRef;

  serviceControl: FormControl<string>;
  locationControl: FormControl<string>;
  addingKeywordResearch: boolean = false;

  constructor(private customerCampaignService: CustomerCampaignService) { }

  ngOnInit(): void {
    this.setupForm();
  }

  toggleAddingKeywordResearch(): void {
    this.addingKeywordResearch = !this.addingKeywordResearch;

    if(!this.pageSuggestion.PendingKeywordResearch)
    {
      this.serviceControl.reset('');
      this.locationControl.reset('');
    }
      this.focus();
  }

  setupForm(): void {
    this.serviceControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(128)
    ]);

    this.locationControl = new FormControl('', [
      CustomValidators.requiredIfValidator(() => !this.customer.IsCampaignNational),
      Validators.maxLength(256)
    ]);
  }

  addKeywordResearch(): void {
    this.serviceControl.markAsDirty();
    this.serviceControl.updateValueAndValidity();
    this.locationControl.markAsDirty();
    this.locationControl.updateValueAndValidity();

    if (!this.locationControl.valid || !this.serviceControl.valid) {
      toastr.error("Please correct errors before requesting research.");
      return;
    }

    this.pageSuggestion.PendingKeywordResearch = {
      KeywordResearchId: 0,
      CustomerId: this.customer.CustomerId,
      PageSuggestionId: this.pageSuggestion.PageSuggestionId,
      Service: this.customerCampaignService.normalizeKeyword(this.serviceControl.value),
      InsertedDate: null,
      CompletedDate: null,
      Url: this.pageSuggestion.Url,
      Location: this.customer.IsCampaignNational ? null : this.customerCampaignService.normalizeArea(this.locationControl.value)
    } as KeywordResearch;

    this.pageSuggestion.IsModified = true;

    this.addingKeywordResearch = false;
  }

  removeKeywordResearch(): void {
    this.pageSuggestion.PendingKeywordResearch = null;
    this.serviceControl.reset('');
    this.locationControl.reset('');
  }

  private focus() {
    setTimeout(() => {
      if (this.serviceInput) {
        this.serviceInput.nativeElement.focus();
      }
    }, 0);
  }
}