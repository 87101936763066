import { Injectable } from '@angular/core';

const defaultAcceptedFileExtensions = ['.gif', '.jpg', '.jpeg', '.tiff', '.png', '.bmp', '.docx'];
const spreadsheetFileExtensions = ['.csv', '.xls', '.xlsm', '.xlsx'];

@Injectable({
  providedIn: 'root'
})
export class FileExtensions {
  static defaults(): string[] {
    return defaultAcceptedFileExtensions;
  }

  static defaultsWithSpreadsheets(): string[] {
    return defaultAcceptedFileExtensions.concat(spreadsheetFileExtensions);
  }
}