<ng-container *ngIf="addingPage; else notAddingPage">
  <div class="row justify-content-end">
    <div class="col-6">
      <div class="input-group">
        <span class="input-group-text">{{ customer.Url }}</span>
        <input #pageInput type="text" class="form-control" [formControl]="pageControl" [formValidation]="pageControl" (keyup.enter)="addPage()" />
      </div>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-primary" (click)="addPage()"><i class="fas fa-plus"></i>Page</button>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-warning" (click)="toggleAddingPage(true)">Cancel</button>
    </div>
  </div>
</ng-container>

<ng-template #notAddingPage>
  <button class="btn btn-primary btn-sm" type="button" data-toggle="dropdown" [disabled]="restrictions.includes(customerCampaignRestrictionTypes_ReadOnly)">
    <i class="fas fa-plus"></i>Page
  </button>
  <ul class="dropdown-menu" role="menu">
    <li>
      <button class="btn-link dropdown-item" (click)="toggleAddingPage(true)">Existing Page</button>
    </li>
    <li>
      <button class="btn-link dropdown-item" (click)="toggleAddingPage(false)">New Page</button>
    </li>
  </ul>
</ng-template>



