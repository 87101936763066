 <div class="row mb-2">
  <div class="col offset-4 text-end">
    <app-components-shared-keyword-research-add-page
      [keywordResearchType]="keywordResearchType"
      [customer]="customer"
      [pageSuggestions]="pageSuggestions"
      (newPagesRequested)="pagesRequested()">
    </app-components-shared-keyword-research-add-page>
  </div>
</div>

@if(selectedPageSuggestions.length === 0) {
  <div class="row">
    <div class="col-md-12">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>No pages or keywords have been selected yet</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
}
@else {
  <div class="row" *ngFor="let pageSuggestion of selectedPageSuggestions | orderBy: pageSort">
    <app-components-shared-keyword-research-page
      [keywordResearchType]="keywordResearchType"
      [customer]="customer"
      [pageSuggestion]="pageSuggestion"
      [pageSuggestions]="pageSuggestions"
      [haloKeywordSuggestions]="haloKeywordSuggestions"
      [keywordConfig]="keywordConfig">
    </app-components-shared-keyword-research-page>
  </div>
}

<app-components-shared-keyword-research-process-keyword-research
  [customer]="customer"
  [pageSuggestions]="pageSuggestions"
  (keywordReseachRequested)="researchRequested()">
</app-components-shared-keyword-research-process-keyword-research>