import { Component, Input } from '@angular/core';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-shared-archived-pages',
  templateUrl: './archived-pages.component.html',
  styleUrls: ['../customer-campaign/customer-campaign.component.scss']
})
export class ArchivedPagesComponent {
  @Input() customer: Boo.Objects.Customer;
  @Input() websiteUrls: Boo.Objects.WebsiteUrl[];
  @Input() archivedWebsiteUrls: Boo.Objects.ArchivedWebsiteUrl[];
  @Input() readOnlyReasons: string;
  @Input() restrictions: Boo.Objects.CustomerCampaign.Enums.RestrictionTypes[];

  customerCampaignRestrictionTypes_ReadOnly = Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.ReadOnly;
  websiteUrlStatuses_New = Boo.Objects.Enums.WebsiteUrlStatuses.New;
  websiteUrlStatuses_Limited = Boo.Objects.Enums.WebsiteUrlStatuses.Limited;
  websiteUrlStatuses_Active = Boo.Objects.Enums.WebsiteUrlStatuses.Active;

  constructor(private customerCampaignService: CustomerCampaignService) { }

  updateWebsiteUrlStatus(archivedWebsiteUrl: Boo.Objects.ArchivedWebsiteUrl, status: Boo.Objects.Enums.WebsiteUrlStatuses): void {
    this.customerCampaignService.updateArchivedWebsiteUrlStatus(this.customer.CustomerId, this.websiteUrls, this.archivedWebsiteUrls, archivedWebsiteUrl, status);
  }
}