import { Component } from '@angular/core';
import System from 'framework/System';
import { SeoWorkService } from '../../../services/seo-work.service';
import PresignedUrlRequest from 'app/models/typescript/PresignedUrlRequest';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import WorkRequestHelper from 'app/managecustomer/WorkRequestHelper';
import FlagTypes = Boo.Objects.Work.SeoMinute.Enums.FlagTypes;
import { onsiteCopyOrderTaskTypes, onsiteCopyOrderWithImplementationTaskTypes } from '../../../../Scripts/app/models/collections/TaskTypeCollections';
import { OnsiteRecommendationDetailService } from '../../../services/onsite-recommendation-detail.service';
import WorkRequestEditConfigFactory from '../../../factories/WorkRequestEditConfigFactory';
import { WorkRequestEditConfig } from '../../../shared/models/WorkRequestEditConfig';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';
import { CustomerCampaignService } from '../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-managecustomer-workrequestedit',
  templateUrl: './WorkRequestEdit.component.html'
})
export class WorkRequestEditComponent implements IActivatable {

  websiteUrlStatuses_Active = Boo.Objects.Enums.WebsiteUrlStatuses.Active;

  // input parameters
  customer: KnockoutObservable<Boo.Objects.Customer> = ko.observable();
  taskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType;
  workRequestDetail: Boo.Objects.Work.SeoMinute.WorkRequestDetail = {} as Boo.Objects.Work.SeoMinute.WorkRequestDetail;
  hasValidAccessDetails: boolean = false;

  // calculated properties
  isLoading: KnockoutObservable<boolean> = ko.observable(false);
  isInEditMode: boolean = false;
  hasBeenSaved: boolean = false;
  canSave: KnockoutComputed<boolean>;
  isCustomOther: boolean = false;
  isOnsiteCopy: boolean = false;
  isOnsiteCopyImplementationManual: boolean = false;
  noWebsiteOption: number = 0;

  // child controls and validation
  childControl: KnockoutObservable<any> = ko.observable();
  childControlViewModel: KnockoutObservable<any> = ko.observable();
  pageValidation: KnockoutObservable<any>;
  dfd: JQueryDeferred<void>;

  // data
  websiteUrls: DisableableWebsiteUrl[] = [];
  websiteUrlPlaceholder: string;
  languages: Boo.Objects.Language[] = [];
  workRequestFiles: Boo.Objects.Work.WorkRequestFile[] = [];
  partnerUser: Boo.Objects.PartnerUser;
  partner: Boo.Objects.Partner;
  partnerUsers: Boo.Objects.PartnerUser[];
  user: Boo.Objects.User;
  implementationResponsibility: Boo.OnsiteRecommendations.Models.Enums.Responsibilities;

  // UI Bound fields
  title: string;
  taskTypeTips: string = '';
  selectedWebsiteUrl: DisableableWebsiteUrl;
  websiteUrlId: KnockoutObservable<number> = ko.observable();
  customDescription: KnockoutObservable<string> = ko.observable('');
  notifyOnCompletion: KnockoutObservable<boolean> = ko.observable();
  billableMinutes: KnockoutComputed<string>;
  customName: KnockoutObservable<string> = ko.observable('');
  customTaskTypeName: KnockoutObservable<string> = ko.observable('');
  taskActionUrl: KnockoutObservable<string> = ko.observable('');
  customBillableMinutes: KnockoutObservable<number> = ko.observable(0);
  config: WorkRequestEditConfig;

  constructor(
    private seoWorkService: SeoWorkService,
    private onsiteRecommendationDetailService: OnsiteRecommendationDetailService,
    private sessionStorageService: SessionStorageService,
    private customerCampaignService: CustomerCampaignService) { }

  activate(params: any): JQueryPromise<any> {
    this.isLoading(true);
    return $.when<any>(
      Utils.wrapDfd(this.sessionStorageService.getStaticData()),
      Utils.wrapDfd(this.sessionStorageService.getPartnerUser()),
      Utils.wrapDfd(this.sessionStorageService.getPartner()),
      Utils.wrapDfd(this.sessionStorageService.getPartnerUsers()),
      Utils.wrapDfd(this.sessionStorageService.getUser()))
      .then((staticData: Boo.Objects.LaunchPadStaticData,
        pUser: Boo.Objects.PartnerUser,
        storagePartner: Boo.Objects.Partner,
        storagePartnerUsers: Boo.Objects.PartnerUser[],
        storageUser: Boo.Objects.User) => {
          let customer = ko.mapping.toJS(params.customer);
          this.customer(customer);
          staticData.Languages.forEach(x => this.languages.push(x));
          this.partnerUser = pUser;
          this.partner = storagePartner;
          this.partnerUsers = storagePartnerUsers;
          this.user = storageUser;

          this.hasValidAccessDetails = params.hasValidAccessDetails;
          this.workRequestDetail = params.workRequestDetail;
          this.taskType = params.taskType;
          return Utils.wrapDfd(this.onsiteRecommendationDetailService.getByCustomerId(customer.CustomerId));
      }).then((osrDetails: Boo.Objects.OnsiteRecommendationDetail) => {
        this.implementationResponsibility = osrDetails.Responsibility;
        return Utils.wrapDfd(this.customerCampaignService.getWebsiteUrls(this.customer().CustomerId, true, this.customerCampaignService.activeStatuses())); // excludeInactiveKeywords: true
      })
      .then((result: Boo.Objects.WebsiteUrl[]) => {
        this.config = WorkRequestEditConfigFactory.create(this.workRequestDetail.WorkRequest.WorkTypeCreationId);
        this.websiteUrlPlaceholder = this.config.allowNoWebsiteOption ? 'None/Other (If other, specify in description)' : 'Select Website URL';

        const restrictedUrls = this.taskType.Flags.filter(x => x.Type === FlagTypes.RestrictedUrls).flatMap(x => x.Data.flatMap(y => ({ Url: y, Message: x.Message })));
        this.websiteUrls = result as DisableableWebsiteUrl[];
        this.websiteUrls.forEach(x => {
          let restrictedUrl = restrictedUrls.find(y => y.Url === x.Url);
          x.disabled = !!restrictedUrl;
          x.disabledMessage = restrictedUrl?.Message;
        });

        this.isInEditMode = this.workRequestDetail.CanEdit;
        this.hasBeenSaved = this.workRequestDetail.WorkRequest.WorkRequestId > 0;
        this.isCustomOther = this.taskType.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.Custom;

        this.title = (this.isInEditMode ? 'Edit' : 'New') + ' Work Details';

        this.isOnsiteCopy = !this.taskType
          || onsiteCopyOrderTaskTypes.includes(this.taskType.TaskTypeEnum);

        this.canSave = ko.computed(() => {
          return this.workRequestDetail && !this.isLoading() && (!this.hasBeenSaved || this.isInEditMode);
        });

        this.billableMinutes = ko.computed(() => {
          if (!this.workRequestDetail) {
            return '00:00';
          }

          let billableMinutes = this.workRequestDetail.WorkRequest.WorkQuantity;
          let hours = Math.floor(billableMinutes / 60);
          let minutes = billableMinutes % 60;
          let padNumber = (number: number) => {
            return number > 9 ? number : '0' + number;
          };

          return padNumber(hours) + ' hrs : ' + padNumber(minutes) + ' mins';
        });

        this.taskTypeTips = this.taskType.Tips || '';

        this.isOnsiteCopyImplementationManual = this.taskType && this.taskType.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.OnsiteCopyImplementationManual;

        if (this.isInEditMode && this.hasBeenSaved) {
          return Utils.wrapDfd(this.seoWorkService.getWorkRequest(this.workRequestDetail.WorkRequest.WorkRequestId));
        } else {
          return System.resolvedPromise({} as Boo.Objects.Work.WorkRequest);
        }
      }).then(wr => {
        if (wr.WorkRequestId) {
          this.workRequestDetail.WorkRequest = wr;
          this.workRequestFiles = wr.WorkRequestFiles;
        }
        this.defaultHasValidAccessDetailsIfNotSet();
        this.setupValidation();
        this.initializeView();
        return System.emptyPromise();
      }).fail((displayMessage: any) => {
        toastr.error(displayMessage);
      }).always(() => {
        this.isLoading(false);
      });
  }

  changeSelectedWebsiteUrl(websiteUrl: DisableableWebsiteUrl): void {
    this.websiteUrlId(websiteUrl?.WebsiteUrlId);
    if (this.childControlViewModel()
      && onsiteCopyOrderTaskTypes.includes(this.taskType.TaskTypeEnum)) {
      this.childControlViewModel().selectedUrlChanged(this.selectedWebsiteUrl);
    }
  }

  getWebsiteUrlIconClass(websiteUrl: DisableableWebsiteUrl): string {
    return websiteUrl.disabled ? 'fas fa-stop-circle text-danger' : 'fas fa-check-square text-success';
  }
  
  getWebsiteUrlTitle(websiteUrl: DisableableWebsiteUrl): string {
    return websiteUrl.disabled ? websiteUrl.disabledMessage : '';
  }

  groupWebsiteUrls(websiteUrl: DisableableWebsiteUrl): boolean {
    return websiteUrl.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active;
  }

  save(): void {

    if (!this.pageValidation.isValid() || !this.childControlCanSaveValidator()) {
      this.pageValidation.errors.showAllMessages();
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return;
    }

    if (this.isCustomOther && this.customBillableMinutes() === 0) {
      bootbox.alert('Please allocate billable time greater than zero to this task to continue.');
      return;
    }

    this.isLoading(true);

    this.workRequestDetail.WorkRequestWebsiteUrl = this.selectedWebsiteUrl?.Url;
    const workRequest = this.workRequestDetail.WorkRequest;

    WorkRequestHelper.setCustomCreatedByUserId(workRequest, this.partnerUser.UserId, this.partnerUser.UserId);
    WorkRequestHelper.setWebsiteUrlId(workRequest, this.websiteUrlId(), this.partnerUser.UserId);
    WorkRequestHelper.setTaskNotifyOnComplete(workRequest, this.notifyOnCompletion(), this.partnerUser.UserId);
    WorkRequestHelper.setCustomDescription(workRequest, this.customDescription(), this.partnerUser.UserId);
    WorkRequestHelper.setImplementationResponsibility(workRequest, this.implementationResponsibility, this.partnerUser.UserId);

    if (this.isCustomOther) {
      WorkRequestHelper.setCustomName(workRequest, this.customName(), this.partnerUser.UserId);
      WorkRequestHelper.setCustomTaskTypeName(workRequest, this.customTaskTypeName(), this.partnerUser.UserId);
      WorkRequestHelper.setTaskActionUrl(workRequest, this.taskActionUrl(), this.partnerUser.UserId);

      if (this.customBillableMinutes() !== null && this.customBillableMinutes() > 0) {
        this.workRequestDetail.WorkRequest.WorkQuantity = this.customBillableMinutes();
        WorkRequestHelper.setCustomBillableMinutes(workRequest, this.customBillableMinutes(), this.partnerUser.UserId);
      }
    }

    workRequest.WorkRequestFiles = this.workRequestFiles;

    if (this.childControlViewModel()) {
      this.childControlViewModel().save(this.workRequestDetail.WorkRequest);
    }

    if (!this.hasBeenSaved) {
      this.workRequestDetail.CanEdit = true;
    }

    if (this.workRequestDetail.WorkRequest.WorkRequestId > 0) {
      this.isLoading(true);
      Utils.wrapDfd(this.seoWorkService.editSeoWorkRequest(this.workRequestDetail))
        .then(() => {
          toastr.success('Item Edited');
          this.close();
        }).fail((displayMessage: string) => {
          toastr.error(displayMessage);
        }).always(() => {
          this.isLoading(false);
        });
    } else {
      this.close();
    }
  }

  uploadFile(): void {
    let request = <PresignedUrlRequest>({
      BucketName: launchpad.config.S3Buckets.s3staticfiles,
      Folder: `taskfile/${this.workRequestDetail.WorkRequest.CustomerId}`,
      UseUniqueFileName: true
    });

    PriorityViewModel.show('app-components-shared-uploadfile', { preSignedURLRequest: request, acceptedFileExtensions: this.config.acceptedFileExtensions })
      .then((data: any) => {

        let workRequestFile = <Boo.Objects.Work.WorkRequestFile>({
          WorkRequestFileId: 0,
          WorkRequestId: this.workRequestDetail.WorkRequest.WorkRequestId,
          Name: data.FileName,
          TaskFileType: Boo.Objects.Enums.TaskFileTypeEnum.TaskDefinitionFile,
          IsDeleted: false,
          S3Url: data.FullUrl,
          InsertedDate: null
        });
        this.workRequestFiles.push(workRequestFile);
      })
      .fail((displayMessage): void => {
        toastr.error(displayMessage);
        this.isLoading(false);
      });
  }

  deleteFile(file: Boo.Objects.Work.WorkRequestFile): void {
    if (file.WorkRequestFileId > 0) {
      file.IsDeleted = true;
      bootbox.confirm('Are you sure? This action cannot be undone.', (result: any) => {
        if (result) {
          Utils.wrapDfd(this.seoWorkService.deleteWorkRequestFile(file, this.workRequestDetail.WorkOrderItemWorkTypeId ?? 0, this.workRequestDetail.WorkOrderItemWorkId ?? 0))
            .then(() => {
              this.workRequestFiles = _.without(this.workRequestFiles, _.findWhere(this.workRequestFiles, { 'WorkRequestFileId': file.WorkRequestFileId }));
              toastr.success('File Deleted');
            }).fail((displayMessage) => {
              toastr.error(displayMessage);
            });
        }
      });
    } else {
      this.workRequestFiles = _.without(this.workRequestFiles, _.findWhere(this.workRequestFiles, { 'S3Url': file.S3Url }));
    }
  }

  close(): void {
    if (this.dfd) {
      this.dfd.resolve();
    }
  }

  cancel(): void {
    if (this.dfd) {
      this.dfd.reject(this.workRequestDetail);
    }
  }

  childControlCanSaveValidator(): any {
    let result = !this.childControlViewModel() || this.childControlViewModel().canSave();
    return result;
  }

  private setupValidation(): void {
    this.customName.extend({
      validation: { validator: (val: any): boolean => this.fieldHasValue(val), message: 'Action name is a required field' },
      maxLength: 128
    });
    this.customTaskTypeName.extend({
      validation: { validator: (val: any): boolean => this.fieldHasValue(val), message: 'If the task type is Other, you must specify a custom task type name up to 30 characters long.' }
    });
    this.websiteUrlId.extend({
      validation: { validator: (val: number): boolean => this.websiteUrlIdIsValid(val), message: 'Website URL is required.' }
    });
    this.websiteUrlId.extend({
      validation: { validator: (val: number): boolean => this.webiteUrlKeywordsAreValid(val), message: 'Website URL must have keywords.' }
    });
    this.customDescription.extend({
      validation: { validator: launchpad.utils.containsNoHtml, message: 'Html tags are not allowed in the description. Please upload a file if html is needed for this work item.' },
      maxLength: 3000
    });
    this.customDescription.extend({      
      validation: { validator: (val: number): boolean => this.customDescriptionHasValue(val), message: 'A brief description of the work to be completed is required (3000 character maximum).' }
    });
    this.notifyOnCompletion.extend({
      required: {
        onlyIf: () => {
            return !this.childControl() || this.childControl().showNotifyCsr;
        }
      }
    });

    this.pageValidation = ko.validatedObservable([
      this.customName,
      this.customTaskTypeName,
      this.websiteUrlId,
      this.customDescription,
      this.notifyOnCompletion
    ]);

    this.pageValidation.errors.showAllMessages(false);
  }

  private initializeView(): void {
    const workRequest = this.workRequestDetail.WorkRequest;
    if (this.isInEditMode) {
      this.websiteUrlId(WorkRequestHelper.getWebsiteUrlId(workRequest));
      this.selectedWebsiteUrl = this.websiteUrls.find(x => x.WebsiteUrlId === this.websiteUrlId());
      if (this.selectedWebsiteUrl) {
        this.selectedWebsiteUrl.disabled = false;
        this.selectedWebsiteUrl.disabledMessage = '';
      }
      this.customDescription(WorkRequestHelper.getCustomDescription(workRequest));
      this.notifyOnCompletion(WorkRequestHelper.getTaskNotifyOnComplete(workRequest));

      if (this.isCustomOther) {
        this.customName(WorkRequestHelper.getCustomName(workRequest));
        this.customTaskTypeName(WorkRequestHelper.getCustomTaskTypeName(workRequest));
        this.customBillableMinutes(WorkRequestHelper.getCustomBillableMinutes(workRequest));
        this.taskActionUrl(WorkRequestHelper.getTaskActionUrl(workRequest));
      }

      this.workRequestFiles = workRequest.WorkRequestFiles;
    }

    if (!this.isInEditMode) {
      switch (this.taskType.TaskTypeEnum) {
        case Boo.Objects.Enums.TaskTypeEnum.KeywordResearchStrategyUpdate:
          this.customDescription('Dictate the work that needs to be completed on this customer\'s campaign.');
          break;
        default:
          this.customDescription('');
      }
    }
    switch (this.taskType.TaskTypeEnum) {
      case Boo.Objects.Enums.TaskTypeEnum.OnsiteCopyImplementationManual:
        this.notifyOnCompletion(true);
        this.childControl(null);
        this.childControlViewModel(null);
        break;
      case Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteCopyCreationAndImplementation:
      case Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteCopyCreationContentOnly:
      case Boo.Objects.Enums.TaskTypeEnum.OnsiteCopy:
        this.customName(this.taskType.Name);
        this.childControl({
          selector: 'app-components-managecustomer-onsitecopycustomaction',
          showNotifyCsr: false,
          showTaskDescription: false
        });
        break;
      case Boo.Objects.Enums.TaskTypeEnum.GoogleAnalyticsCreationAndImplementation:
        this.childControl({
          selector: 'app-components-managecustomer-googleanalyticscustomaction',
          showNotifyCsr: true,
          showTaskDescription: true
        });
        this.childControlViewModel(null);
        break;
      default:
        this.customBillableMinutes(this.taskType.Minutes);
        this.childControl(null);
        this.childControlViewModel(null);
    }

    if (this.isCustomOther && this.customer() && this.customer().LanguageId !== launchpad.config.LanguageIdEnum.English) {
      alert('This customer is set to have work fulfilled in the ' + this.getLanguageName(this.customer().LanguageId) +
        ' language. Because the work item you are creating will appear in this customer\'s reporting, the Task Type Name and Action Name should be written in ' + this.getLanguageName(this.customer().LanguageId));
    }

    this.pageValidation.errors.showAllMessages(false);
  }

  private defaultHasValidAccessDetailsIfNotSet(): void {

    if (!this.hasValidAccessDetails) {
      let boostSetToPublish: boolean;
      if (this.isOnsiteCopy) {
        boostSetToPublish = onsiteCopyOrderWithImplementationTaskTypes.includes(this.taskType.TaskTypeEnum);
      } else {
        Utils.wrapDfd(this.onsiteRecommendationDetailService.getByCustomerId(this.customer().CustomerId))
          .then((onsiteRecommendationDetail) => {
            boostSetToPublish = onsiteRecommendationDetail.Responsibility === Boo.OnsiteRecommendations.Models.Enums.Responsibilities.Boostability;
          });
      }

      if (this.taskType.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.OnsiteCopyCreateContentStandard) {
        this.hasValidAccessDetails = boostSetToPublish;
      }
    }
  }

  private websiteUrlIdIsValid(newValue: number): boolean {
    return this.config.allowNoWebsiteOption || (newValue > 0 && launchpad.utils.notNullOrEmpty(newValue));
  }

  private webiteUrlKeywordsAreValid(newValue: number): boolean {
    let taskTypesThatRequireKeywords = [
      Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendation,
      Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendationAndImplementation,
      Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendationContentOnly];

    if (!taskTypesThatRequireKeywords.includes(this.taskType.TaskTypeEnum)) {
      return true;
    }

    let selectedUrl = _.find(this.websiteUrls, (url: Boo.Objects.WebsiteUrl) => {
      return url.WebsiteUrlId === newValue;
    });
    if (selectedUrl) {
      return selectedUrl.Keywords.length !== 0;
    }
    return true;
  }

  private getLanguageName(languageId: number): string {
    let language = _.find(this.languages, (lang) => {
      return lang.LanguageId === languageId;
    });
    if (language) {
      return language.Name;
    } else {
      return '';
    }
  }

  private fieldHasValue(newValue: any): boolean {
    if (!this.isCustomOther) {
      return true;
    }
    return launchpad.utils.notNullOrEmpty(newValue);
  }

  private customDescriptionHasValue(newValue: any): boolean {
    if (!this.config.shouldShowDescription) {
      return true;
    }
    return launchpad.utils.notNullOrEmpty(newValue);
  }
}

interface DisableableWebsiteUrl extends Boo.Objects.WebsiteUrl {
  disabled: boolean;
  disabledMessage: string;
}
