import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WorkflowService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/workflows`;

  constructor(private http: HttpClient) { }

  get(workflowId: number): Observable<Boo.Objects.Workflow> {
    const params = new HttpParams().set('workflowId', workflowId);

    return this.http.get<Boo.Objects.Workflow>(`${this.baseUrl}/Get`, { params: params });
  }

  getByTaskId(taskId: number): Observable<Boo.Objects.Workflow> {
    const params = new HttpParams().set('taskId', taskId);

    return this.http.get<Boo.Objects.Workflow>(`${this.baseUrl}/GetByTaskId`, { params: params });
  }

  getWorkflowIdByTaskId(taskId: number): Observable<number> {
    const params = new HttpParams().set('taskId', taskId);

    return this.http.get<number>(`${this.baseUrl}/GetWorkflowIdByTaskId`, { params: params });
  }

  getIdByTicketId(ticketId: number): Observable<number> {
    const params = new HttpParams().set('ticketId', ticketId);

    return this.http.get<number>(`${this.baseUrl}/GetIdByTicketId`, { params: params });
  }

  getClosableWorkflows(customerId: number): Observable<Boo.Objects.Workflows.CloseableWorkflow[]> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.Objects.Workflows.CloseableWorkflow[]>(`${this.baseUrl}/GetClosableWorkflows`, { params: params });
  }

  getOrderingTask(workflowId: number): Observable<Boo.Objects.WorkflowTask> {
    const params = new HttpParams().set('workflowId', workflowId);

    return this.http.get<Boo.Objects.WorkflowTask>(`${this.baseUrl}/GetOrderingTask`, { params: params });
  }

  getOrderingTaskByTaskId(taskId: number): Observable<Boo.Objects.WorkflowTask> {
    const params = new HttpParams().set('taskId', taskId);

    return this.http.get<Boo.Objects.WorkflowTask>(`${this.baseUrl}/GetOrderingTaskByTaskId`, { params: params });
  }

  closeWorkflow(customerId: number, workflowId: number, note: string, taskId?: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/CloseWorkflow`, {
        customerId: customerId,
        workflowId: workflowId,
        note: note,
        taskId: taskId
    });
  }
}
