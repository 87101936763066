import { Injectable } from "@angular/core";
import { KeywordSuggestionConfig } from "app/models/KeywordSuggestionConfig";

@Injectable({
  providedIn: 'root'
})
export class KeywordSuggestionConfigFactory
{
  // TODO: this has been moved to service and should be replaced.
  // TODO: This is a temporary solution to get the default keyword/keywordSuggestion config. This should be replaced with a call to the server.
  // If product requests it we will move this to a database table and build a UI around it in the partner management screen.
  private defaultKeywordConfig: PartnerKeywordConfig[] = [
    { campaignType: campaignType.local, minimumMinutes: 0, maximumMinutes: 959, mainKeywords: 2, pages: 1 },
    { campaignType: campaignType.local, minimumMinutes: 960, maximumMinutes: 1679, mainKeywords: 4, pages: 2 },
    { campaignType: campaignType.local, minimumMinutes: 1680, maximumMinutes: 2399, mainKeywords: 6, pages: 3 },
    { campaignType: campaignType.local, minimumMinutes: 2400, maximumMinutes: 2999, mainKeywords: 8, pages: 4 },
    { campaignType: campaignType.local, minimumMinutes: 3000, maximumMinutes: 3599, mainKeywords: 10, pages: 5 },
    { campaignType: campaignType.local, minimumMinutes: 3600, maximumMinutes: 4199, mainKeywords: 12, pages: 6 },
    { campaignType: campaignType.local, minimumMinutes: 4200, maximumMinutes: 4799, mainKeywords: 14, pages: 7 },
    { campaignType: campaignType.local, minimumMinutes: 4800, maximumMinutes: 5399, mainKeywords: 16, pages: 8 },
    { campaignType: campaignType.local, minimumMinutes: 5400, maximumMinutes: 5999, mainKeywords: 18, pages: 9 },
    { campaignType: campaignType.local, minimumMinutes: 6000, maximumMinutes: 999999, mainKeywords: 20, pages: 10},

    { campaignType: campaignType.national, minimumMinutes: 0, maximumMinutes: 3599, mainKeywords: 2, pages: 1 },
    { campaignType: campaignType.national, minimumMinutes: 3600, maximumMinutes: 999999, mainKeywords: 4, pages: 2 }
  ];

  private thryvMbgPartners: number[] = [
    Boo.Objects.Enums.PartnerEnum.ThryvMoneyBack,
    Boo.Objects.Enums.PartnerEnum.ThryvAustraliaMoneyBack,
    Boo.Objects.Enums.PartnerEnum.ThryvCanadaMoneyBack];

  private partnerKeywordConfig: PartnerKeywordConfig[] = [
    { partnerIds: this.thryvMbgPartners, campaignType: campaignType.local, minimumMinutes: 0, maximumMinutes: 799, mainKeywords: 2, pages: 1 },
    { partnerIds: this.thryvMbgPartners, campaignType: campaignType.local, minimumMinutes: 800, maximumMinutes: 1399, mainKeywords: 4, pages: 2 },
    { partnerIds: this.thryvMbgPartners, campaignType: campaignType.local, minimumMinutes: 1400, maximumMinutes: 2499, mainKeywords: 6, pages: 3 },
    { partnerIds: this.thryvMbgPartners, campaignType: campaignType.local, minimumMinutes: 2500, maximumMinutes: 999999, mainKeywords: 10, pages: 5 },
  ];

  create(seoMinutesPaidRecurring: number, isCampaignNational: boolean, partnerId: number): KeywordSuggestionConfig {
    let customerCampaignType = isCampaignNational ? campaignType.national : campaignType.local;
    let keywordSuggestionConfig = this.partnerKeywordConfig.find(c => c.partnerIds.indexOf(partnerId) >= 0 && c.campaignType === customerCampaignType && seoMinutesPaidRecurring >= c.minimumMinutes && seoMinutesPaidRecurring <= c.maximumMinutes);

    if (!keywordSuggestionConfig) {
      keywordSuggestionConfig = this.defaultKeywordConfig.find(c => c.campaignType === customerCampaignType && seoMinutesPaidRecurring >= c.minimumMinutes && seoMinutesPaidRecurring <= c.maximumMinutes);
    }

    if (!keywordSuggestionConfig) {
      toastr.error(`Unable to find keyword suggestion configuration for this campaign. Please notify the Product team. PartnerId: ${partnerId}, CampaignType: ${customerCampaignType}, Minutes: ${seoMinutesPaidRecurring}`);
      keywordSuggestionConfig = this.defaultKeywordConfig[0];
    }

    const hours = Math.floor(seoMinutesPaidRecurring / 60);

    return {
      minimumMainKeywords: keywordSuggestionConfig.mainKeywords,
      minimumKeywordSuggestions: keywordSuggestionConfig.mainKeywords * 2,
      maximumHaloKeywordSuggestions: Math.max(hours * 2, 100),
      minimumHaloKeywordSuggestions: Math.min(hours, 50),
      minimumPages: keywordSuggestionConfig.pages,
      maximumPages: 20
    }
  }

  createForStrategyUpdate(minutes: number, targetPageCount: number): KeywordSuggestionConfig {
    const hours = Math.floor(minutes / 60);

    return {
      minimumMainKeywords: targetPageCount * 2,
      minimumKeywordSuggestions: targetPageCount * 4,
      maximumHaloKeywordSuggestions: Math.max(hours * 2, 100),
      minimumHaloKeywordSuggestions: Math.min(hours, 50),
      minimumPages: targetPageCount,
      maximumPages: 20
    }
  }
}

enum campaignType {
  local = 1,
  national = 2
}

class PartnerKeywordConfig
{
  public partnerIds?: number[];
  public campaignType : campaignType;
  public minimumMinutes: number;
  public maximumMinutes: number;
  public mainKeywords: number;
  public pages: number;
}
