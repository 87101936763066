import CustomerAccountTypes = Boo.Objects.Enums.CustomerAccountTypes;

export default class CustomerAccountTypeGroup {
    /**
     * A group of account types that represent the SEO section.
     */
    public static seo: number[] = [
        CustomerAccountTypes.GoogleAccount,
        CustomerAccountTypes.GoogleAnalytics,
        CustomerAccountTypes.GoogleBusinessProfile,
        CustomerAccountTypes.GoogleSearchConsole,
        CustomerAccountTypes.GoogleTagManager
    ];

    /**
     * A group of account types for blog editing.
     */
    public static blog: number[] = [
        CustomerAccountTypes.BlogAdmin,
        CustomerAccountTypes.BlogEditor,
        CustomerAccountTypes.BlogHosting
    ];

    /**
     * A group of logins for modifying the customer's website.
     */
    public static website: number[] = [
        CustomerAccountTypes.CMSCustomerAccess,
        CustomerAccountTypes.DomainRegistrar,
        CustomerAccountTypes.FTP,
        CustomerAccountTypes.WebsiteHosting,
        CustomerAccountTypes.CMSAdminAccess
    ];

    /**
     * A group of logins for custom implementation tasks.
     */
    public static customImplementationLogins: number[] = [
        CustomerAccountTypes.GoogleAccount,
        CustomerAccountTypes.GoogleSearchConsole,
        CustomerAccountTypes.GoogleAnalytics,
        CustomerAccountTypes.GoogleTagManager,
        CustomerAccountTypes.DomainRegistrar,
        CustomerAccountTypes.CMSCustomerAccess,
        CustomerAccountTypes.CMSAdminAccess,
        CustomerAccountTypes.BlogHosting,
        CustomerAccountTypes.BlogAdmin,
        CustomerAccountTypes.BlogEditor,
        CustomerAccountTypes.FTP,
        CustomerAccountTypes.WebsiteHosting
    ];

    /**
     * A group of logins for modifying the SEO content survey.
     */
    public static seoContentSurveyLogins: number[] = [
        CustomerAccountTypes.CMSCustomerAccess,
        CustomerAccountTypes.CMSAdminAccess,
        CustomerAccountTypes.BlogHosting,
        CustomerAccountTypes.BlogAdmin,
        CustomerAccountTypes.BlogEditor
    ];

}
