import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HaloKeywordSuggestion, KeywordSuggestionFilter, KeywordSuggestion, PageSuggestion, SaveActions, KeywordSuggestionSources } from '../shared/models/keywords/keywords';

@Injectable({
  providedIn: 'root'
})
export class KeywordResearchService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/keywordresearch`;

  constructor(private http: HttpClient) { }

  getHaloKeywordSuggestions(customerId: number): Observable<HaloKeywordSuggestion[]> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<HaloKeywordSuggestion[]>(`${this.baseUrl}/haloKeywordSuggestions`, { params: params })
  }

  getPageSuggestions(customerId: number): Observable<PageSuggestion[]> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<PageSuggestion[]>(`${this.baseUrl}/pageSuggestions`, { params: params })
  }

  save(customerId: number, pageSuggestions: PageSuggestion[], haloKeywordSuggestions: HaloKeywordSuggestion[], saveActions: SaveActions[], requestSource: Boo.Objects.Enums.RequestSources, isCampaignNational?: boolean): Observable<any> {
    return this.http.post(`${this.baseUrl}/save`, {
      customerId: customerId,
      pageSuggestions: pageSuggestions,
      haloKeywordSuggestions: haloKeywordSuggestions,
      saveActions: saveActions,
      requestSource: requestSource,
      isCampaignNational: isCampaignNational
    });
  }

  requestNewPageSuggestions(customerId: number): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/requestNewPageSuggestions`, customerId);
  }

  defaultKeywordSuggestionFilter: KeywordSuggestionFilter = {
    keyword: '',
    fromRank: 1,
    toRank: 101,
    volume: 'Any',
    competition: 'Any'
  } as KeywordSuggestionFilter;

  filterKeywordSuggestions(keywordSuggestion: KeywordSuggestion[], keywordSuggestionFilter: KeywordSuggestionFilter): KeywordSuggestion[] {
    return keywordSuggestionFilter
      ? keywordSuggestion.filter(x => x.KeywordPhrase.toLowerCase().includes(keywordSuggestionFilter.keyword)
        && (x.Rank >= (keywordSuggestionFilter.fromRank ?? this.defaultKeywordSuggestionFilter.fromRank) && x.Rank <= (keywordSuggestionFilter.toRank ?? this.defaultKeywordSuggestionFilter.toRank))
        && (keywordSuggestionFilter.volume === this.defaultKeywordSuggestionFilter.volume || x.VolumeDescription === keywordSuggestionFilter.volume)
        && (keywordSuggestionFilter.competition === this.defaultKeywordSuggestionFilter.competition || x.CompetitionDescription === keywordSuggestionFilter.competition))
      : keywordSuggestion;
  }

  filterHaloKeywordSuggestions(haloKeywordSuggestion: HaloKeywordSuggestion[], keywordSuggestionFilter: KeywordSuggestionFilter): HaloKeywordSuggestion[] {
    return keywordSuggestionFilter
      ? haloKeywordSuggestion.filter(x => x.KeywordPhrase.toLowerCase().includes(keywordSuggestionFilter.keyword)
        && (x.Rank >= (keywordSuggestionFilter.fromRank ?? this.defaultKeywordSuggestionFilter.fromRank) && x.Rank <= (keywordSuggestionFilter.toRank ?? this.defaultKeywordSuggestionFilter.toRank))
        && (keywordSuggestionFilter.volume === this.defaultKeywordSuggestionFilter.volume || x.VolumeDescription === keywordSuggestionFilter.volume)
        && (keywordSuggestionFilter.competition === this.defaultKeywordSuggestionFilter.competition || x.CompetitionDescription === keywordSuggestionFilter.competition))
      : haloKeywordSuggestion;
  }

  pageSort = (a: PageSuggestion, b: PageSuggestion): number => {
    if (!a.Url && b.Url) {
      return 1;
    }
    if (a.Url && !b.Url) {
        return -1;
    }
    return a.Url.toLowerCase().localeCompare(b.Url.toLowerCase());
  }

  rankThreshold = 3;

  keywordSort = (a: KeywordSuggestion, b: KeywordSuggestion): number => {
    if (a.Rank <= this.rankThreshold && b.Rank > this.rankThreshold) {
      return 1;
    }
    if (a.Rank > this.rankThreshold && b.Rank <= this.rankThreshold) {
      return -1;
    }

    if (a.SourceId === KeywordSuggestionSources.CustomerCampaign && b.SourceId !== KeywordSuggestionSources.CustomerCampaign) {
      return 1;
    }
    if (a.SourceId !== KeywordSuggestionSources.CustomerCampaign && b.SourceId === KeywordSuggestionSources.CustomerCampaign) {
      return -1;
    }

    if (a.SourceId === KeywordSuggestionSources.User && b.SourceId !== KeywordSuggestionSources.User) {
      return 1;
    }
    if (a.SourceId !== KeywordSuggestionSources.User && b.SourceId === KeywordSuggestionSources.User) {
      return -1;
    }

    if (a.Volume === null && b.Volume !== null) {
      return 1;
    }
    if (a.Volume !== null && b.Volume === null) {
      return -1;
    }
    if (a.Volume !== b.Volume) {
      return b.Volume - a.Volume; // desc
    }

    if (a.Competition === null && b.Competition !== null) {
      return 1;
    }
    if (a.Competition !== null && b.Competition === null) {
      return -1;
    }
    if (a.Competition !== b.Competition) {
      return a.Competition - b.Competition; // asc
    }

    return a.KeywordPhrase.toLowerCase().localeCompare(b.KeywordPhrase.toLowerCase());
  }
}
