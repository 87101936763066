import { Component, Input, OnInit } from '@angular/core';
import { FormArray, UntypedFormGroup, Validators } from '@angular/forms';
import CustomValidators from '../../../../shared/custom-validators';
import LocalProfileValidators from '../../../../shared/local-profile-validators';
import { Observable, Subject, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { LocalProfileService } from '../../../../services/local-profile.service';
import { PartnerSsoService } from '../../../../services/partner-sso.service';

@Component({
  selector: 'app-components-shared-minimum-local-profile',
  templateUrl: './minimum-local-profile.component.html',
  styleUrls: ['./minimum-local-profile.component.scss']
})
export class MinimumLocalProfileComponent implements OnInit {
  @Input() localProfileForm: UntypedFormGroup;
  @Input() customer: Boo.Objects.Customer;
  @Input() readOnly: boolean = false;
  @Input() showRegistration: boolean = false;
  @Input() registrationPhoneNumber?: Boo.Objects.AutoSelectedRegistrationPhoneNumber;
  @Input() disableBusinessCitationEligibilityControls: boolean = false;

  businessHoursForms: FormArray;
  specialHoursForms: FormArray;
  isLoading = false;
  isReadOnly = false;
  staticData: any;
  categories: Boo.BusinessCitations.Models.BusinessCitationProviderCategory[];
  countries: Boo.Objects.Country[];
  serviceAreaInput$ = new Subject<string>();
  serviceAreaLoading = false;
  serviceAreas$: Observable<Boo.Objects.LocalProfiles.LocalProfileServiceArea[]> = of([]);
  partnerRequiresSso: boolean = false;
  partnerSsoLabel: string = '';
  listingClaimedByStatuses: app.shared.localprofiles.interfaces.IClaimStatus[];

  constructor(
    private localProfileService: LocalProfileService,
    private partnerSsoService: PartnerSsoService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.isReadOnly = this.readOnly;
    this.partnerRequiresSso = this.partnerSsoService.localProfilesRequireSso(this.customer.PartnerId);
    this.partnerSsoLabel = this.partnerSsoService.getLocalProfileSsoLabel(this.customer.PartnerId);

    this.serviceAreas$ = this.serviceAreaInput$.pipe(
      filter(x => !!x),
      distinctUntilChanged(),
      debounceTime(800),
      tap(() => this.serviceAreaLoading = true),
      switchMap(input => this.localProfileService.findServiceAreas(input)
        .pipe(
          catchError(() => of([])),
          tap(() => this.serviceAreaLoading = false)
        ))
    );

    // Prevent NG0100: ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.setValidation();
      this.setFormDependencies();
      this.isLoading = false;
    }, 0);
  }

  editOnPartnerSite(): void {
    this.isLoading = true;
    (this.localProfileForm.get('identifier').value ?
      this.partnerSsoService.getLocalProfileSso(this.customer.PartnerId, this.localProfileForm.get('identifier').value) :
      this.partnerSsoService.getCustomerSso(this.customer.PartnerId, this.customer.CustomerId))
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(url => {
        if (url) {
          window.open(url, '_blank');
        } else {
          toastr.error('Could not log into partner site. Please ensure sso configuration is correct.');
        }
      });
  }

  updateValueAndValidity() {
    this.localProfileForm.markAllAsTouched();

    this.localProfileForm.get('canSubscribe').updateValueAndValidity();
    this.localProfileForm.get('serviceAreas').updateValueAndValidity();
    this.localProfileForm.get('streetAddress').updateValueAndValidity();
    this.localProfileForm.get('streetAddress2').updateValueAndValidity();
    this.localProfileForm.get('zip').updateValueAndValidity();
  }

  private setValidation(): void {
    this.localProfileForm.get('canSubscribe').setValidators([Validators.required]);
    this.localProfileForm.get('serviceAreas').setValidators([CustomValidators.requiredIfValidator(this.serviceAreasAreRequired.bind(this)), CustomValidators.maxLengthArrayValidator(20)]);
    this.localProfileForm.get('streetAddress').setValidators([Validators.required, LocalProfileValidators.cannotContainPOBox(), Validators.maxLength(255)]);
    this.localProfileForm.get('streetAddress2').setValidators([LocalProfileValidators.cannotContainPOBox(), Validators.maxLength(255)]);
    this.localProfileForm.get('zip').setValidators([Validators.required, Validators.maxLength(10)]);
  }

  private setFormDependencies() {
    this.localProfileForm.get('isStreetAddressHidden').valueChanges.subscribe((value: boolean) => {
      this.localProfileForm.get('serviceAreas').markAsTouched();
      this.localProfileForm.get('serviceAreas').updateValueAndValidity();
    });

    this.localProfileForm.get('isActive').valueChanges.subscribe((isActive: number) => {
      this.updateValueAndValidity();
    });
  }

  private serviceAreasAreRequired(): boolean {
    return this.localProfileForm.get('isStreetAddressHidden').value;
  }
}