import { Component } from '@angular/core';
import { CustomerInformationComponent } from '../CustomerInformation/CustomerInformation.component';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { ReloadService } from '../../../services/reload.service';
import { StoreService } from '../../../services/store.service';
import { CustomerContactPreferencesService } from '../../../services/customer-contact-preferences.service';
import { MerchantFieldsService } from '../../../services/merchant-fields.service';
import { UserService } from '../../../services/user.service';
import { PartnerSupportLevelService } from '../../../services/partner-support-level.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import { FrontService } from '../../../services/front.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerUserService } from '../../../services/customer-user.service';

@Component({
	selector: 'app-components-managecustomer-customerinformationlimited',
	templateUrl: './CustomerInformationLimited.component.html'
})
export class CustomerInformationLimitedComponent extends CustomerInformationComponent {
    public ko = ko;
    public _ = _;
    public ordersSnapshot: Boo.ApisExternal.Store.Objects.Order[];

    public triggerTicketSave: (saveType: SaveTypes, closeTicket: boolean) => JQueryPromise<void>;
    public canResetWebsite: boolean;

    constructor(
        storeService: StoreService,
        customerContactPreferencesService: CustomerContactPreferencesService,
        merchantFieldsService: MerchantFieldsService,
        userService: UserService,
        partnerSupportLevelService: PartnerSupportLevelService,
        sessionStorageService: SessionStorageService,
        private reloadService: ReloadService,
        frontService: FrontService,
        modalService: NgbModal,
        customerUserService: CustomerUserService) {
      super(storeService, customerContactPreferencesService, merchantFieldsService, userService, partnerSupportLevelService, sessionStorageService, frontService, modalService, customerUserService);
    }

    public activate(options: any): any {
        super.activate(options);
        this.canResetWebsite = options.canResetWebsite || false;
        this.ordersSnapshot = options.ordersSnapshot;
        this.triggerTicketSave = options.triggerTicketSave;
	}

	public resetCustomerWebsite(): void {
        this.triggerTicketSave(SaveTypes.UpdateWithoutTicketNoteValidation, false)
            .then(() => {
                PriorityViewModel.show('app-components-customerservice-managertools', { 'customerId': this.customer().CustomerId(), })
                    .done((data: any) => {
                        if (data && data.url) {
                            this.customer().Url(data.url);
                        }
                        if (this.customerSavedCallback) {
                            this.customerSavedCallback();
                        }
                        this.reloadService.reload();
                    });
                });
	}
}
