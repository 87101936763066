<div class="col-md-12">
  <table class="table table-hover mb-4">
    <thead>
      <tr>
        <th>
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <button class="btn btn-secondary me-4 btn-sm" type="button"  (click)="removePage()" title="Remove Url">
                <i class="fas fa-times fa-no-margin"></i>
              </button>
              <i *ngIf="pageSuggestion.ReadOnlyReason" class="fas fa-lock text-danger" triggers="mouseenter:mouseleave" [ngbPopover]="pageSuggestion.ReadOnlyReason"></i>
              @if (pageSuggestion.Url) {
              {{ pageSuggestion.Url }}
              } @else {
                New Page To Be Created
              }
              @if (!pageSuggestion.IsLive) {
                <span class="badge rounded-pill bg-secondary pill-text">New</span>
              }
            </div>
            <div *ngIf="validationMessage" class="text-danger fw-normal me-3">
              {{ validationMessage }}
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let keywordSuggestion of selectedKeywordSuggestions | orderBy: keywordSort">
        <tr>
          <td>
            <app-components-shared-keyword-research-keyword
              [keywordResearchType]="keywordResearchType"
              [customer]="customer"
              [pageSuggestions]="pageSuggestions"
              [haloKeywordSuggestions]="haloKeywordSuggestions"
              [pageSuggestion]="pageSuggestion"
              [keywordSuggestion]="keywordSuggestion"
              [kwrSearchTerm]="kwrSearchTerm">
            </app-components-shared-keyword-research-keyword>
          </td>
        </tr>
      </ng-container>
      <tr>
        <td>
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <app-components-shared-keyword-research-add-keyword
                [customer]="customer"
                [pageSuggestion]="pageSuggestion"
                [pageSuggestions]="pageSuggestions"
                [kwrSearchTerm]="kwrSearchTerm">
              </app-components-shared-keyword-research-add-keyword>
            </div>
            <div>
              <app-components-shared-keyword-research-add-keyword-research
                [customer]="customer"
                [pageSuggestion]="pageSuggestion">
              </app-components-shared-keyword-research-add-keyword-research>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>