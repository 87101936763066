import { Component, OnInit } from '@angular/core';
import System from 'framework/System';
import ProactiveUnderPerformingKeywordValidationState from 'app/specialist/actioncomponents/ProactiveUnderPerformingKeywordValidationState';
import ITaskFilesPublicApi = app.components.interfaces.ITaskFilesPublicApi;
import { TaskNoteService } from '../../../../services/task-note.service';
import { UpaChecklistService } from '../../../../services/upa-checklist.service';
import { HotjarService } from '../../../../services/hotjar.service';
import Utils from '../../../../shared/utils';

@Component({
    selector: 'app-components-specialist-actioncomponents-proactiveunderperformingkeyword',
    templateUrl: './ProactiveUnderPerformingKeyword.component.html'
})
export class ProactiveUnderPerformingKeywordComponent implements OnInit, IActionWorkspace, IActivatable {
    actionViewModel: KnockoutObservable<IActionWorkspace>;
    currentAction: KnockoutObservable<Boo.Objects.Action>;
    customer: Boo.Objects.Customer;
    taskId: number;
    noteMaxCharacters: number = 500;
    validationState: KnockoutObservable<ProactiveUnderPerformingKeywordValidationState> = ko.observable(new ProactiveUnderPerformingKeywordValidationState());
    taskFilesApi: ITaskFilesPublicApi;
    isMoneyBack: boolean = false;
    upaChecklists: Boo.Objects.Checklist.UpaChecklist[];
    validationApi: app.interfaces.IValidationPublicApi;
    customerCampaignRestrictions: Boo.Objects.CustomerCampaign.Enums.RestrictionTypes[] = [
      Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.ReadOnly,
      Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.HideHaloTab,
      Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.HideArchivedTab,
      Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.HideStrategyUpdateRequestButton
    ];

    private childComponentValidations: { validation: app.interfaces.IValidate, uid: string }[] = [];

    constructor(
        private taskNoteService: TaskNoteService,
        private upaChecklistService: UpaChecklistService,
        private hotjarService: HotjarService) { }

    ngOnInit(): void {
        this.validationApi = {
            add: this.addValidation.bind(this),
            remove: this.removeValidation.bind(this)
        }

        this.hotjarService.trigger('TaskType-ProactiveUnderPerformingKeyword');
    }

    getFilesApi: (api: ITaskFilesPublicApi) => void = (api) => {
        this.taskFilesApi = api;
    }

    activate(params: any): JQueryPromise<void> {
        params.controlViewModel(this);
        this.actionViewModel = ko.observable(null);
        this.currentAction = params.currentAction;
        this.customer = this.currentAction().Customer;
        this.taskId = this.currentAction().Task.TaskId;
        this.isMoneyBack = this.currentAction().Task.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.ProactiveUnderPerformingKeywordMoneyBack;

        return Utils.wrapDfd(this.upaChecklistService.getOrCreate(this.taskId))
            .then(upaChecklists => {
                this.upaChecklists = upaChecklists;
                let validationState = this.validationState();
                validationState.hasGottenChecklistResponse = true;
                this.validationState(validationState);
            });
    }

    isStepReadyToReject(): boolean {
        return false;
    }

    isStepReadyToSave(): JQueryPromise<boolean> {
        let currentState = this.validationState();

        if (currentState.hasGottenKeywordsResponse && currentState.hasGottenChecklistResponse) {
            return Utils.wrapDfd(this.upaChecklistService.save(this.upaChecklists))
                .then(() => true);
        }
        else {
            return System.resolvedPromise(false);
        }
    }

    isStepReadyToComplete(): JQueryPromise<boolean> {
        let completeValidationState = this.validationState();

        completeValidationState.hasFiles = this.taskFilesApi.getFiles().length > 0;

        if (!completeValidationState.hasGottenKeywordsResponse) {
            toastr.error('Error loading customer keywords.');
            return System.resolvedPromise(false);
        }

        if (!completeValidationState.hasGottenChecklistResponse) {
            toastr.error('Error loading checklist.');
            return System.resolvedPromise(false);
        }

        if (this.childComponentValidations.some(x => !x.validation.isValid())) {
            var promises = this.childComponentValidations.map(x => x.validation.validate());
            return $.when(...promises)
                .then((...results: app.interfaces.IValidatedResult[]) => {
                    results.map(x => x.errorMessages).flat().forEach(x => toastr.error(x));
                    return false;
                });
        }

        this.validationState(completeValidationState);

        return Utils.wrapDfd(this.upaChecklistService.save(this.upaChecklists))
            .then(() => true);
    }

    isStepReadyToDelete(): boolean {
        return false;
    }

    addValidation(validation: app.interfaces.IValidate, uid: string): void {
        this.childComponentValidations.push({ validation, uid });
    }

    removeValidation(uid: string): void {
        this.childComponentValidations = this.childComponentValidations.filter(x => x.uid !== uid);
    }
}
