import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { WorkflowService } from '../../../services/workflow.service';
import { switchMap, finalize } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-components-managecustomer-closeworkflow',
  templateUrl: './CloseWorkflow.component.html',
  styleUrls: ['./CloseWorkflow.component.scss'],
})

export class CloseWorkflowComponent implements OnInit {
  @Input() customerId: number;

  isLoading: boolean = true;
  workflowToClose: Boo.Objects.Workflows.CloseableWorkflow;
  closeNote: string;
  workflows: Boo.Objects.Workflows.CloseableWorkflow[] = [];

  constructor(
    private workflowService: WorkflowService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.isLoading = false;
  }

  getWorkflows(): void {
    this.isLoading = true;
    this.workflowService.getClosableWorkflows(this.customerId).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(workflows => {
      if (!workflows.length) {
        this.workflows = [];
        return toastr.warning('This customer has no open workflows');
      }
      this.workflows = workflows;
    }, err => toastr.error(err));
  }

  openCloseWorkflowModal(content: TemplateRef<any>, workflow: Boo.Objects.Workflows.CloseableWorkflow): void {
    this.workflowToClose = workflow;
    this.modalService.open(content);
  }

  closeWorkflow(modalRef: NgbModalRef): void {
    if (!this.closeNote) {
      toastr.error('A note is required');
      return;
    }

    modalRef.dismiss();
    this.isLoading = true;
    this.workflowService.closeWorkflow(this.customerId, this.workflowToClose.WorkflowId, this.closeNote, this.workflowToClose.ClosingTaskId).pipe(
      switchMap(() => {
        toastr.success('Workflow closed');
        this.workflowToClose = null;
        this.closeNote = null;
        return this.workflowService.getClosableWorkflows(this.customerId);
      }),
      finalize(() => this.isLoading = false)
    ).subscribe(workflows => this.workflows = workflows, err => toastr.error(err));
  }

  closeModal(modalRef: NgbModalRef): void {
    this.closeNote = null;
    modalRef.dismiss();
  }
}
