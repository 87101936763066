<app-components-specialist-internal-tasks-qa-action-information
  [internalAction]="internalAction"></app-components-specialist-internal-tasks-qa-action-information>
<div [loadingOverlay]="isLoading" id="internal-task-qa-criteria-float-panel" class="float-panel-md float-panel">
  <div class="float-panel-tab">
    <button class="float-panel-btn-expand btn btn-lg btn-primary">
      <i class="fa fa-check"></i>
    </button>
  </div>
  <div class="float-panel-main card">
    <div class="float-panel-draggable card-header">
      <i class="fa fa-check"></i>
      Profile Submission Quality
      <i class="float-panel-btn-collapse fa fa-minus float-end"></i>
    </div>
    <div class="card-body">
      <app-components-qa-grading-area-panel [taskType]="taskType"
        [qaCriteriaGroups]="qaCriteriaGroups" #gradingArea>
      </app-components-qa-grading-area-panel>
    </div>
    <div class="card-footer">
      <div class="text-end">
        <button (click)="delete.next()" class="btn btn-danger">
          <i class="fa fa-check"></i>
          Delete
        </button>
        <button (click)="complete.next()" class="btn btn-success">
          <i class="fa fa-check"></i>
          Complete
        </button>
      </div>
    </div>
  </div>
</div>
<div [loadingOverlay]="isLoading">
  <div class="row">
    <div class="col-md-12">
      <ul class="list-group">
        <li class="list-group-item">
          <strong>Task Action Url</strong>
          <a [externalLink]="{ url: taskActionUrl, text:taskActionUrl}" class="float-end"></a>
        </li>
      </ul>
    </div>
  </div>
  <br />
  <div [hidden]="!targetKeywords" class="card">
    <div class="card-header card-header-small">
      Target Keywords
    </div>
    <ul class="list-group">
      <ng-container *ngFor="let $data of targetKeywords">
        <li class="list-group-item list-group-item-small cursor-pointer">
          <span>{{$data}}</span>
        </li>
      </ng-container>
    </ul>
  </div>
  <hr />
  <ng-container *ngIf="showGoogleBusinessProfile">
    <div class="row">
      <div class="col-md-6">
        <app-components-managecustomer-customeraccountgroup [options]="{
          customerId: customer.CustomerId,
          customerAccountTypes: [googleBusinessProfileCustomerAccountType],
          columns: 1,
          isEnabled: googleBusinessProfileIsEnabled
        }"></app-components-managecustomer-customeraccountgroup>
       </div>
    </div>
    <hr />
  </ng-container>
  <div>
    <ng-container *ngIf="localProfile">
      <app-components-shared-local-profiles [canAddLocalProfile]="false" [customer]="customer" [localProfileId]="localProfile.LocalProfileId"
        [readOnly]="true" [showRegistration]="true"></app-components-shared-local-profiles>
    </ng-container>
  </div>
  <div class="card">
    <div class="card-header card-header-small">
      Files
    </div>
    <div class="card-body">
      <div class="col-md-12">
        <app-table [items]="files" [exportFilename]="'qa-local-business-citation-files'">
          <ng-template #tableHeader>
            <tr>
              <th export sort field="Name">Name</th>
              <th export sort field="Comment">Comment</th>
              <th export sort field="InsertedDate">Upload Date</th>
              <th export field="S3Url"></th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-file>
            <tr>
              <td>{{file.Text}}</td>
              <td>
                <span [hidden]="!(file.Comment)" [ngbPopover]="file.Comment" popoverTitle="Comment"
                  placement="right auto" triggers="mouseenter:mouseleave">
                  <i class="far fa-file-alt"></i>
                  (Hover for Comment)
                </span>
              </td>
              <td>{{file.InsertedDate | dateText }}</td>
              <td style="width:50px;">
                <a [externalLink]="{ url: file.S3Url, text:'View File'}" class="btn btn-primary"></a>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
</div>