@if (addingKeywordResearch)
{
  <div class="row justify-content-start">
    <div class="col-auto">
      <ng-container *ngTemplateOutlet="service"></ng-container>
    </div>
    <div class="col-auto" *ngIf="!customer.IsCampaignNational">
      <ng-container *ngTemplateOutlet="location"></ng-container>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-primary" (click)="addKeywordResearch()"><i class="fas fa-plus"></i>Keyword Research</button>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-warning" (click)="toggleAddingKeywordResearch()">Cancel</button>
    </div>
  </div>
}
@else if(pageSuggestion.PendingKeywordResearch) {
  <div class="row justify-content-start align-items-center">
    <div class="col-auto">
      Pending Research:
      {{ pageSuggestion.PendingKeywordResearch.Service }}
        @if(pageSuggestion.PendingKeywordResearch.Location) {
          in {{ pageSuggestion.PendingKeywordResearch.Location }}
        }
    </div>
    <div class="col-auto">
      <button class="btn btn-primary me-1 btn-sm" type="button" (click)="toggleAddingKeywordResearch()" title="Edit Keyword Research"><i class="fas fa-pencil fa-no-margin"></i></button>
      <button class="btn btn-secondary btn-sm" type="button" (click)="removeKeywordResearch()" title="Remove Keyword Research"><i class="fas fa-times fa-no-margin"></i></button>
    </div>
  </div>
}
@else if(pageSuggestion.IsReadOnly) {
  <button class="btn btn-primary btn-sm display-title-when-disabled" type="button" disabled title="Research not allowed">
    <i class="fas fa-magnifying-glass"></i>Request Research
  </button>
}
@else {
  <button class="btn btn-primary btn-sm" type="button" (click)="toggleAddingKeywordResearch()">
    <i class="fas fa-magnifying-glass"></i>Request Research
  </button>
}

<ng-template #service>
  <div class="input-group mb-0">
    <span class="input-group-text">Service</span>
    <input #serviceInput type="text" class="form-control" [formControl]="serviceControl"
      [formValidation]="serviceControl" (keyup.enter)="addKeywordResearch()"/>
  </div>
</ng-template>

<ng-template #location>
  <div class="input-group mb-0">
    <span class="input-group-text">Location</span>
    <input #locationInput type="text" class="form-control" [formControl]="locationControl"
      [formValidation]="locationControl" (keyup.enter)="addKeywordResearch()"/>
  </div>
</ng-template>