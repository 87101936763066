@if(pendingKeywordResearch.length > 0) {
  @if(requestSubmitted) {
  <div class="col-md-12 mt-5">
    <div class="alert alert-success" role="alert">
      <i class="fas fa-check-circle"></i> <strong>Keyword Research request submitted</strong> - This ticket will close automatically.
    </div>
  </div>
  }
  @else {
  <div class="col-md-12 mt-5">
    <table class="table table-hover mb-4">
      <thead>
        <tr>
          <th>
            Pending Keyword Research
          </th>
          <th>
            Service
          </th>
          @if(!customer.IsCampaignNational) {
          <th>
            Location
          </th>
          }
        </tr>
      </thead>
      <tbody>
        @for( keywordResearch of pendingKeywordResearch; track keywordResearch) {
        <tr>
          <td>
            <button class="btn btn-secondary me-4 btn-sm" type="button"
              (click)="removeKeywordResearch(keywordResearch)" title="Remove Keyword Research">
              <i class="fas fa-times fa-no-margin"></i>
            </button>
            @if (keywordResearch.Url) {
            {{ keywordResearch.Url }}
            } @else {
            New Page To Be Created
            }
          </td>
          <td>
            {{keywordResearch.Service}}
          </td>
          @if(!customer.IsCampaignNational) {
          <td>
            {{keywordResearch.Location}}
          </td>
          }
        </tr>
        }
      </tbody>
      <tfoot>
        <td [attr.colspan]="customer.IsCampaignNational ? 2 : 3">
          <div class="row">
            <div class="col text-end">
              <button type="button" class="btn btn-primary" (click)="requestResearch()"><i
                  class="fas fa-magnifying-glass"></i>Process Keyword Research</button>
            </div>
          </div>
        </td>
      </tfoot>
    </table>
  </div>
  }
}