<span class="d-flex justify-content-between" onclick="event.stopPropagation()">
  <span class="dropdown-header">Suggested Keywords</span>
  <span class="dropdown-header">Showing {{filteredKeywordSuggestions.length}} of {{keywordSuggestions.length}}</span>
</span>
<li class="mb-2" onclick="event.stopPropagation()">
  <div class="row justify-content-start ms-2 me-2" [formGroup]="formGroup">
    <div class="col-auto">
      <div class="input-group mb-0 input-group-sm">
        <span class="input-group-text">Keyword</span>
        <input type="text" class="form-control form-control-sm filterInput" formControlName="keyword" />
      </div>
    </div>
    <div class="col-auto">
      <div class="input-group mb-0 input-group-sm">
        <span class="input-group-text">Vol</span>
        <select class="form-select metricFilterInput" formControlName="volume">
          <option value="Any">Any</option>
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>
      </div>
    </div>
    <div class="col-auto">
      <div class="input-group mb-0 input-group-sm">
        <span class="input-group-text">Cmp</span>
        <select class="form-select metricFilterInput" formControlName="competition">
          <option value="Any">Any</option>
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>
      </div>
    </div>
    <div class="col-auto">
      <div class="input-group mb-0 input-group-sm">
        <span class="input-group-text">Rank</span>
        <input type="number" class="form-control rankFilterInput" formControlName="fromRank" min="1" max="101" step="1" required />
        <span class="input-group-text">to</span>
        <input type="number" class="form-control rankFilterInput" formControlName="toRank" min="1" max="101" step="1" required />
      </div>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-danger btn-sm" (click)="resetFilter()"><i class="fa-solid fa-rotate-left fa-no-margin"></i></button>
    </div>
  </div>
</li>