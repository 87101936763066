<span class="me-4">
  <label>Campaign Type </label>
  <shared-components-customchoice 
    [(value)]="customer.IsCampaignNational"
    [choiceFalse]="'Local'"
    [choiceTrue]="'National'"
    (valueChange)="getKeywordConfig()"
    [disable]="restrictions.includes(customerCampaignRestrictionTypes_DisableCustomerCampaignType) || restrictions.includes(customerCampaignRestrictionTypes_ReadOnly)">
  </shared-components-customchoice>
</span>
<span class="ms-4">
  <span class="badge pill bg-secondary summary-pill">Target Pages: {{pageCount}}/{{keywordConfig?.Pages}}</span>
  <span class="badge pill bg-secondary summary-pill">Required Keywords: {{keywordCount}}/{{keywordConfig?.MainKeywords}}</span>
  <span class="badge pill bg-secondary summary-pill">Required Halo Keywords: {{haloCount}}/{{keywordConfig?.HaloKeywords}}</span>
</span>