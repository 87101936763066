<ng-container *ngIf="!archivedKeywords || archivedKeywords.length === 0; else showInactiveKeywords">
  <div class="row">
    <div class="col-md-12">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>
              Inactive Keywords
              <i *ngIf="readOnlyReasons" class="fas fa-lock text-danger ms-2" triggers="mouseenter:mouseleave" [ngbPopover]="readOnlyReasons"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>No inactive keywords</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
<ng-template #showInactiveKeywords>
  <div class="row">
    <div class="col-md-12">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>
              Inactive Keywords
              <i *ngIf="readOnlyReasons" class="fas fa-lock text-danger ms-2" triggers="mouseenter:mouseleave" [ngbPopover]="readOnlyReasons"></i>
            </th>
            <th>Created</th>
            <th>Archived</th>
            <th>Archived From</th>
          </tr>
        </thead>
        <tbody *ngFor="let archivedKeyword of archivedKeywords">
          <tr>
            <td>
              <button class="btn btn-secondary me-4 btn-sm" type="button" data-toggle="dropdown" [disabled]="restrictions.includes(customerCampaignRestrictionTypes_ReadOnly)">
                <i class="fas fa-solid fa-bars fa-no-margin"></i>
              </button>
              <ul class="dropdown-menu" role="menu">
                <ng-container *ngFor="let destinationWebsiteUrl of websiteUrls">
                  <li>
                    <button *ngIf="isActiveStatus(destinationWebsiteUrl)" class="btn-link dropdown-item"
                      (click)="moveArchivedKeywordToWebsiteUrl(archivedKeyword, destinationWebsiteUrl)"
                      [disabled]="destinationWebsiteUrl.IsReadOnly">{{ destinationWebsiteUrl.Url }}</button>
                  </li>
                </ng-container>
                <div class="dropdown-divider"></div>
                <li>
                  <button class="btn-link dropdown-item" (click)="moveArchivedKeywordToHalo(archivedKeyword)">Halo</button>
                </li>
              </ul>
              {{ archivedKeyword.KeywordPhrase }}
            </td>
            <td>
              {{ archivedKeyword.InsertedDate | dateText: 'MM/DD/YYYY' }}
            </td>
            <td>
              <ng-container *ngIf="archivedKeyword.LatestArchiveDate">{{ archivedKeyword.LatestArchiveDate | dateText: 'MM/DD/YYYY' }}</ng-container>
            </td>
            <td>
              {{ archivedKeyword.WebsiteUrl ? archivedKeyword.WebsiteUrl : 'Halo' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>