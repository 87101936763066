@if (requireNewPages) {
  @if (newPageRequestSubmitted) {
    <div class="alert alert-success text-start" role="alert">
      <i class="fas fa-check-circle"></i> <strong>New Page request submitted</strong> - This ticket will close automatically.
    </div>
  }
  @else {
    <button class="btn btn-warning btn-sm" type="button" (click)="requestNewPages()" title="Suggested pages do not match the customer URL">
      <i class="fa-solid fa-repeat"></i>Generate New Page Suggestions
    </button>
  }
}
@else if (addingPage) {
  <div class="row justify-content-end">
    <div class="col">
      <div class="input-group">
        <span class="input-group-text">{{ customer.Url }}</span>
        <input #pageInput type="text" class="form-control" [formControl]="pageControl" [formValidation]="pageControl" (keyup.enter)="addPage()" />
      </div>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-primary" (click)="addPage()"><i class="fas fa-plus"></i>Page</button>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-warning" (click)="toggleAddingPage(true)">Cancel</button>
    </div>
  </div>
}
@else {
  <button class="btn btn-primary btn-sm" type="button" data-toggle="dropdown">
    <i class="fas fa-plus"></i>Page
  </button>
  <ul class="dropdown-menu" role="menu">
    <li>
      <button class="btn-link dropdown-item" (click)="toggleAddingPage(true)">Existing Page</button>
    </li>
    <li>
      <button class="btn-link dropdown-item" (click)="addStub()">New Page</button>
    </li>
    @if (availablePageSuggestions.length) {
      <div class="dropdown-divider"></div>
      <h6 class="dropdown-header">Pages</h6>
      <ng-container *ngFor="let pageSuggestion of availablePageSuggestions | orderBy: pageSort">
        @if (!pageSuggestion.IsSelected) {
          <li>
            <button class="btn-link dropdown-item" (click)="addPageSuggestion(pageSuggestion)">
              {{ pageSuggestion.Url }}
            </button>
          </li>
        }
      </ng-container>
    }
  </ul>
}