import { BlogDomainPurchaseComponent } from './blog-domain-purchase/blog-domain-purchase.component';
import { SiteThemesCreateContentComponent } from './site-themes-create-content/site-themes-create-content.component';
import { SiteThemesReviewContentComponent } from './site-themes-review-content/site-themes-review-content.component';
import { BlogBuildComponent } from './blog-build/blog-build.component';
import { ClassifiedBusinessListingQaComponent } from './classified-business-listing-qa/classified-business-listing-qa.component';
import { LocalBusinessCitationQaComponent } from './local-business-citation-qa/local-business-citation-qa.component';
import { ContentReviewQaComponent } from './content-review-qa/content-review-qa.component';
import { OnsiteBloggingReviewQaComponent } from './onsite-blogging-review-qa/onsite-blogging-review-qa.component';
import { OnsiteCopyReviewQaComponent } from './onsite-copy-review-qa/onsite-copy-review-qa.component';
import { PremiumMonthlyCampainUpdateQaComponent } from './premium-monthly-campaign-update-qa/premium-monthly-campaign-update-qa.component';
import { PremiumPreliminaryAuditQaComponent } from './premium-preliminary-audit-qa/premium-preliminary-audit-qa.component';
import { ProactiveUnderperformingKeywordQaComponent } from './proactive-underperforming-keyword-qa/proactive-underperforming-keyword-qa.component';
import { PremiumBacklinkQaComponent } from './premium-backlink-qa/premium-backlink-qa.component';
import { OnsiteRecommendationReviewQaComponent } from './onsite-recommendation-review-qa/onsite-recommendation-review-qa.component'
import { Type } from '@angular/core';
import TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum;

export default class Workspaces {
  private static taskTypeWorkspaces: Map<TaskTypeEnum, Type<any>> = new Map<TaskTypeEnum, Type<any>>([
    [TaskTypeEnum.InternalTaskBlogDomainPurchase, BlogDomainPurchaseComponent],
    [TaskTypeEnum.InternalTaskSiteThemesCreateContent, SiteThemesCreateContentComponent],
    [TaskTypeEnum.InternalTaskSiteThemesReviewContent, SiteThemesReviewContentComponent],
    [TaskTypeEnum.InternalTaskBuildBlog, BlogBuildComponent],
    [TaskTypeEnum.InternalTaskQaClassifiedBusinessListings, ClassifiedBusinessListingQaComponent],
    [TaskTypeEnum.InternalTaskQaLocalBusinessCitations, LocalBusinessCitationQaComponent],
    [TaskTypeEnum.InternalTaskQaContentReviewBlog, ContentReviewQaComponent],
    [TaskTypeEnum.InternalTaskQaOnsiteBloggingReview, OnsiteBloggingReviewQaComponent],
    [TaskTypeEnum.InternalTaskQaOnsiteCopyReview, OnsiteCopyReviewQaComponent],
    [TaskTypeEnum.InternalTaskQaPremiumMonthlyCampaignUpdate, PremiumMonthlyCampainUpdateQaComponent],
    [TaskTypeEnum.InternalTaskQaPremiumPreliminaryAudit, PremiumPreliminaryAuditQaComponent],
    [TaskTypeEnum.InternalTaskQaProactiveUnderperformingKeyword, ProactiveUnderperformingKeywordQaComponent],
    [TaskTypeEnum.InternalTaskQaPremiumBacklink, PremiumBacklinkQaComponent],
    [TaskTypeEnum.InternalTaskQaOnsiteRecommendationReview, OnsiteRecommendationReviewQaComponent]
  ]);

  static getByTaskType(taskType: TaskTypeEnum): Type<any> {
    return this.taskTypeWorkspaces.get(taskType);
  };
}
