@if (updatingPage) {
  <div class="row justify-content-start ms-4">
    <div class="col-6">
      <input #pageInput type="text" class="form-control" [formControl]="pageControl" [formValidation]="pageControl" (keyup.enter)="updatePage()" />
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-primary btn-sm" (click)="updatePage()">Update</button>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-warning btn-sm" (click)="toggleUpdatingPage()">Cancel</button>
    </div>
  </div>
} @else {
  <span class="pull-right mb-0 ms-4">
    @if (readOnlyReasons) {
      <i class="fas fa-lock text-danger" triggers="mouseenter:mouseleave" [ngbPopover]="readOnlyReasons"></i>
    } @else if (websiteUrl?.IsRestrictedFromStrategyUpdate) {
      <i class="fas fa-lock text-tertiary" triggers="mouseenter:mouseleave" [ngbPopover]="websiteUrl?.RestrictionReason"></i>
    }
    <a class="fas fa-external-link-square-alt" [attr.href]="websiteUrl.Url" target="_blank" rel="noreferrer"></a>
    <label class="fw-boldtext-break">{{websiteUrl.Url}}</label>
    <button type="button" class="btn btn-primary btn-sm ms-2" (click)="toggleUpdatingPage()"
      [disabled]="websiteUrl?.IsReadOnly || restrictions.includes(customerCampaignRestrictionTypes_ReadOnly) || restrictions.includes(customerCampaignRestrictionTypes_DisablePageUpdate)"><i class="fa fa-edit fa-no-margin"></i></button>
  </span>
}
