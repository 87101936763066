@if (requestSubmitted) {
  <div class="alert alert-success" role="alert">
    <i class="fas fa-check-circle"></i><strong>Generating SEO Action Plan</strong> - Check the Files section shortly.
  </div>
}
@else if (isEnabled) {
  <button type="button" class="btn btn-primary btn-sm" (click)="generateReport()"><i class="fa-solid fa-bolt"></i>Generate SEO Action Plan</button>
}
@else {
  <button type="button" class="btn btn-primary btn-sm" style="pointer-events: auto;" disabled title="Keyword research selections must be valid to generate this report"><i class="fa-solid fa-bolt"></i>Generate SEO Action Plan</button>
}