import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { WebsitesModule } from '../../websites/websites.module';
import { SharedComponentsModule } from '../../shared/shared-components.module';
import { BusinessDirectoriesComponent } from './BusinessDirectories/BusinessDirectories.component';
import { ChooseLocalProfileCategoryComponent } from './ChooseLocalProfileCategory/ChooseLocalProfileCategory.component';
import { ClassifiedBusinessListingsComponent } from './ClassifiedBusinessListings/ClassifiedBusinessListings.component';
import { CommentComponent } from './Comment/Comment.component';
import { CommentsComponent } from './Comments/Comments.component';
import { CustomManualComponent } from './CustomManual/CustomManual.component';
import { OnsiteRecommendationComponent } from './components/OnsiteRecommendation/OnsiteRecommendation.component';
import { ContentReviewComponent } from './ContentReview/ContentReview.component';
import { StandardBacklinkComponent } from './StandardBacklink/standard-backlink.component';
import { CustomerInformationComponent } from './CustomerInformation/CustomerInformation.component';
import { SeoContentSurveyComponent } from './SeoContentSurvey/SeoContentSurvey.component';
import { CustomerTargetUrlsComponent } from './customer-target-urls/customer-target-urls.component';
import { CustomerTaskTypesComponent } from './customer-task-types/customer-task-types.component';
import { CustomImplementationActionComponent } from './CustomImplementationAction/CustomImplementationAction.component';
import { FilesComponent } from './Files/Files.component';
import { InformationComponent } from './Information/Information.component';
import { LocalBusinessCitationsComponent } from './LocalBusinessCitations/LocalBusinessCitations.component';
import { OnsiteBloggingReviewComponent } from './OnsiteBloggingReview/OnsiteBloggingReview.component';
import { OnsiteBloggingRevisionComponent } from './OnsiteBloggingRevision/OnsiteBloggingRevision.component';
import { OnsiteCopyCreateContentComponent } from './OnsiteCopyCreateContent/OnsiteCopyCreateContent.component';
import { OnsiteCopyReviewComponent } from './OnsiteCopyReview/OnsiteCopyReview.component';
import { OnsiteBloggingImplementationComponent } from './OnsiteImplementation/OnsiteBloggingImplementation/OnsiteBloggingImplementation.component';
import { OnsiteCopyImplementationComponent } from './OnsiteImplementation/OnsiteCopyImplementation/OnsiteCopyImplementation.component';
import { OnsiteImplementationComponent } from './OnsiteImplementation/OnsiteImplementation/OnsiteImplementation.component';
import { OnsiteRecommendationImplementationComponent } from './OnsiteImplementation/OnsiteRecommendationImplementation/OnsiteRecommendationImplementation.component';
import { OnsiteBloggingImplementationReviewComponent } from './OnsiteImplementationReview/OnsiteBloggingImplementationReview/OnsiteBloggingImplementationReview.component';
import { OnsiteCopyImplementationReviewComponent } from './OnsiteImplementationReview/OnsiteCopyImplementationReview/OnsiteCopyImplementationReview.component';
import { OnsiteImplementationReviewComponent } from './OnsiteImplementationReview/OnsiteImplementationReview/OnsiteImplementationReview.component';
import { OnsiteRecommendationImplementationReviewComponent } from './OnsiteImplementationReview/OnsiteRecommendationImplementationReview/OnsiteRecommendationImplementationReview.component';
import { GoogleAnalyticsAccessComponent } from './GoogleAnalyticsAccess/GoogleAnalyticsAccess.component';
import { OnsiteRecommendationComponent as OnsiteRecommendationComponent2 } from './OnsiteRecommendation/OnsiteRecommendation.component';
import { OnsiteRecommendationReviewComponent } from './OnsiteRecommendationReview/OnsiteRecommendationReview.component';
import { BusinessProfileValidationAndAugmentationComponent } from './BusinessProfileValidationAndAugmentation/BusinessProfileValidationAndAugmentation.component';
import { StandaloneBusinessProfileValidationAndAugmentationComponent } from './StandaloneBusinessProfileValidationAndAugmentation/StandaloneBusinessProfileValidationAndAugmentation.component';
import { PreAuditComponent } from './PreAudit/PreAudit.component';
import { ProactiveUnderPerformingKeywordComponent } from './ProactiveUnderPerformingKeyword/ProactiveUnderPerformingKeyword.component';
import { ResolveBusinessProfileIssuesComponent } from './ResolveBusinessProfileIssues/ResolveBusinessProfileIssues.component';
import { WebsiteBookmarkingComponent } from './WebsiteBookmarking/WebsiteBookmarking.component';
import { ChangedKeywordsComponent } from './changed-keywords/changed-keywords.component';
import { PremiumMonthlyCampaignUpdateComponent } from './premium-monthly-campaign-update/premium-monthly-campaign-update.component';
import { RejectionComponent } from './rejections/rejection.component';
import { OnsiteBloggingCreateContentComponent } from './onsite-blogging-create-content/onsite-blogging-create-content.component';
import { ManageCustomerModule } from '../../managecustomer/manage-customer.module';

@NgModule({
  declarations: [
    BusinessDirectoriesComponent,
    ChooseLocalProfileCategoryComponent,
    ClassifiedBusinessListingsComponent,
    CommentComponent,
    CommentsComponent,
    CustomManualComponent,
    OnsiteRecommendationComponent,
    ContentReviewComponent,
    StandardBacklinkComponent,
    CustomerInformationComponent,
    SeoContentSurveyComponent,
    CustomerTargetUrlsComponent,
    CustomerTaskTypesComponent,
    CustomImplementationActionComponent,
    FilesComponent,
    InformationComponent,
    LocalBusinessCitationsComponent,
    OnsiteBloggingReviewComponent,
    OnsiteBloggingRevisionComponent,
    OnsiteCopyCreateContentComponent,
    OnsiteCopyReviewComponent,
    OnsiteBloggingImplementationComponent,
    OnsiteCopyImplementationComponent,
    OnsiteImplementationComponent,
    OnsiteRecommendationImplementationComponent,
    OnsiteBloggingImplementationReviewComponent,
    OnsiteCopyImplementationReviewComponent,
    OnsiteImplementationReviewComponent,
    OnsiteRecommendationImplementationReviewComponent,
    GoogleAnalyticsAccessComponent,
    OnsiteRecommendationComponent2,
    OnsiteRecommendationReviewComponent,
    BusinessProfileValidationAndAugmentationComponent,
    StandaloneBusinessProfileValidationAndAugmentationComponent,
    PreAuditComponent,
    ProactiveUnderPerformingKeywordComponent,
    ResolveBusinessProfileIssuesComponent,
    WebsiteBookmarkingComponent,
    ChangedKeywordsComponent,
    PremiumMonthlyCampaignUpdateComponent,
    RejectionComponent,
    OnsiteBloggingCreateContentComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    WebsitesModule,
    SharedComponentsModule,
    ManageCustomerModule
  ],
  exports: [
    CustomerTargetUrlsComponent,
    CustomerTaskTypesComponent
  ]
})
export class ActionComponentsModule { }
