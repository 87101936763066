import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-components-shared-keyword-research-seo-action-plan',
  templateUrl: './seo-action-plan.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class SeoActionPlanComponent {
  @Input() isEnabled: boolean;
  @Output() seoActionPlanRequested = new EventEmitter<void>();

  requestSubmitted = false;

  generateReport(): void {
    this.requestSubmitted = true;
    this.seoActionPlanRequested.emit();
    setTimeout(() => { this.requestSubmitted = false; }, 60000); // Disable button for 1 minute
  }
}
