<div [loadingOverlay]="isLoading()">
  <div class="row">
    <ng-container *ngIf="citationUrl()">
      <div class="col-md-12">
        <ul class="list-group">
          <li class="list-group-item">
            <strong>Citation Url</strong>
            <a [externalLink]="{ url: citationUrl(), text:citationUrl()}" class="float-end" rel="noreferrer"></a>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
  <div [hidden]="!showCustomLocationSection()">
    <div class="alert alert-warning">All general purpose map citation / business profile sites have been submitted to.
      To find additionalkeyword/area specific locations, do a search in Google based on the keyword(s) andarea(s). Look
      at the competitor citations and compare those with the list of domainsalready submitted to below. Choose a
      location that has not been submitted to andenter the URL to which the information will be submitted. Complete the
      citationat the chosen URL and complete the action.</div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label" for="urlSubmittedTo">URL Submitted To</label>
          <input [dataValidation]="{ placement: 'right', value: urlSubmittedTo }" [ngModel]="urlSubmittedTo()"
            (ngModelChange)="urlSubmittedTo($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
            class="form-control" type="text" id="urlSubmittedTo" placeholder="Url Submitted To" />
        </div>
        <div [hidden]="!showAccountsUsed()" class="form-group">
          <label class="control-label" for="domainsSubmittedTo">Domains Already Submitted To</label>
          <textarea [ngModel]="previousAccountsUsed()" (ngModelChange)="previousAccountsUsed($event)"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }" rows="6" class="form-control"
            id="domainsSubmittedTo"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div [hidden]="!(externalEditCount() > 0)" class="alert alert-warning">
    <h5>THIS IS A REVISION</h5>
    This customer's local profile information has previously been submitted to this site by Boostability but has since
    changed.
    Please update the existing listing to match the Name, Address, Phone and Website as displayed on this task.
    If no listing for this customer exists on this site, please create a new listing.
  </div>
  <div class="alert alert-info">
    <h5>REMEMBER</h5>
    <ul>
      <li>Please make sure to use the same information below when creating your new business profile.</li>
      <li>
        Make sure the address used is accurate. The address
        <strong>MUST</strong>
        be consistent across all
        business profile sites.
      </li>
    </ul>
  </div>
  <br />
  <div [hidden]="!targetKeywords()" class="card">
    <div class="card-header card-header-small">
      Target Keywords
    </div>
    <ul class="list-group">
      <ng-container *ngFor="let $data of targetKeywords()">
        <li class="list-group-item list-group-item-small cursor-pointer">
          <span>{{$data}}</span>
        </li>
      </ng-container>
    </ul>
  </div>
  <div [hidden]="!(showCustomLocationSection() === false)" class="form-group">
    <label class="control-label">Task Action Url</label>
    <input [dataValidation]="{ placement: 'top', value: taskActionUrl }" [ngModel]="taskActionUrl()"
      (ngModelChange)="taskActionUrl($event)" [ngModelOptions]="{ standalone: true }" type="text" class="form-control"
      name="taskActionUrl" />
    <p [hidden]="!isGoogleLinkLocation" class="help-block">In order to complete this Google action, you will need to
      update the Task Action URL field with the final URL that was returned after you submitted the Google listing. For
      example, the first part of the URL will appear as follows:
      https://business.google.com/b/101316174892870898393/add/verify/l/05767655044161439032</p>
  </div>
  <hr />
  <ng-container *ngIf="showGoogleBusinessProfile">
    <div class="row">
      <div class="col-md-6">
        <app-components-managecustomer-customeraccountgroup [options]="{
          customerId: customer().CustomerId,
          customerAccountTypes: [googleBusinessProfileCustomerAccountType],
          columns: 1,
          isEnabled: googleBusinessProfileIsEnabled()
        }"></app-components-managecustomer-customeraccountgroup>
       </div>
    </div>
    <hr />
  </ng-container>
  <app-components-shared-local-profiles [customer]="customer()" [canAddLocalProfile]="false" [localProfileId]="localProfile.LocalProfileId"
    [readOnly]="true" [showRegistration]="true" [registrationPhoneNumber]="registrationPhoneNumber()"></app-components-shared-local-profiles>
  <div class="card">
    <div class="card-header card-header-small">
      Files
    </div>
    <div class="card-body">
      <div class="col-md-12">
        <app-table [items]="files" [exportFilename]="'local-business-citations-files'">
          <ng-template #tableHeader>
            <tr>
              <th export sort field="Name">Name</th>
              <th export sort field="Comment">Comment</th>
              <th export sort field="InsertedDate">Upload Date</th>
              <th export field="S3Url"></th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-file>
            <tr>
              <td>{{file.Name}}</td>
              <td>
                <span [hidden]="!(file.Comment)" [ngbPopover]="file.Comment" popoverTitle="Comment"
                  placement="right auto" triggers="mouseenter:mouseleave">
                  <i class="far fa-file-alt"></i>
                  (Hover for Comment)
                </span>
              </td>
              <td>{{file.InsertedDate | dateText }}</td>
              <td style="width:50px;">
                <a [externalLink]="{ url: file.S3Url, text:'View File'}" class="btn btn-primary"></a>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
  <div class="alert alert-info" style="margin-top:15px;">
    <p>
      <strong>You may need to contact the Customer to verify the Business Profile listing.</strong>
    </p>
    <p>{{contactInfo()}}</p>
  </div>
</div>