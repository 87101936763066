<div *ngIf="!loading">
  <div class="d-flex align-items-center mb-4">
    <app-components-shared-keyword-research-summary
      [keywordResearchType]="keywordResearchType"
      [customer]="customer"
      [pageSuggestions]="pageSuggestions"
      [haloKeywordSuggestions]="haloKeywordSuggestions"
      [keywordConfig]="keywordConfig"
      (campaignTypeChanged)="getKeywordConfig()">
    </app-components-shared-keyword-research-summary>
    <div class="ms-auto">
      <ng-container *ngIf="validationMessage">
        <span class="text-danger me-3">
          {{ validationMessage }}
        </span>
      </ng-container>
      <app-components-shared-keyword-research-seo-action-plan
      [isEnabled]="isValid()"
      (seoActionPlanRequested)="generateReport()">
    </app-components-shared-keyword-research-seo-action-plan>
    </div>
  </div>

  <app-tab-panel [displayMode]="tabPanelDisplayMode.horizontal">
    <app-tab [title]="'Pages / Keywords'">
      <div *tabContent>
        <app-components-shared-keyword-research-pages-and-keywords
          [keywordResearchType]="keywordResearchType"
          [customer]="customer"
          [pageSuggestions]="pageSuggestions"
          [haloKeywordSuggestions]="haloKeywordSuggestions"
          [keywordConfig]="keywordConfig"
          (keywordReseachRequested)="researchRequested()"
          (newPagesRequested)="pagesRequested()">
        </app-components-shared-keyword-research-pages-and-keywords>
      </div>
    </app-tab>
    <app-tab [title]="'Halo Keywords'">
      <div *tabContent>
        <app-components-shared-keyword-research-halo-keywords
          [customer]="customer"
          [pageSuggestions]="pageSuggestions"
          [haloKeywordSuggestions]="haloKeywordSuggestions"
          [keywordConfig]="keywordConfig">
        </app-components-shared-keyword-research-halo-keywords>
      </div>
    </app-tab>
  </app-tab-panel>
</div>