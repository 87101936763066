<div *ngIf="!loading">
  <div class="d-flex align-items-center mb-4">
    <app-components-shared-campaign-summary
      [customer]="customer"
      [websiteUrls]="websiteUrls"
      [haloKeywords]="haloKeywords"
      [restrictions]="restrictions">
    </app-components-shared-campaign-summary>
    <div class="ms-auto">
      @if (validationMessage) {
        <span class="text-danger me-3">
          {{ validationMessage }}
        </span>
      } @else if (modifiedCount > 0) {
        <span class="text-warning me-3">
          Unsaved changes
        </span>
      }
      <button class="btn btn-success" (click)="save()" [disabled]="restrictions.includes(customerCampaignRestrictionTypes_ReadOnly)">
        Save
      </button>
    </div>
  </div>
  <app-tab-panel [displayMode]="tabPanelDisplayMode.horizontal">
    <app-tab [title]="'Pages / Keywords'">
      <div *tabContent>
        <app-components-shared-pages-and-keywords
          [customer]="customer"
          [websiteUrls]="websiteUrls"
          [haloKeywords]="haloKeywords"
          [archivedWebsiteUrls]="archivedWebsiteUrls"
          [archivedKeywords]="archivedKeywords"
          [readOnlyReasons]="readOnlyReasons"
          [firstPageKeywordPhrases]="firstPageKeywordPhrases"
          [restrictions]="restrictions"
          [reviewSource]="reviewSource">
        </app-components-shared-pages-and-keywords>
      </div>
    </app-tab>
    <ng-container *ngIf="!restrictions.includes(customerCampaignRestrictionTypes_HideHaloTab)">
      <app-tab [title]="'Halo Keywords'">
        <div *tabContent>
          <app-components-shared-halo-keywords
            [customer]="customer"
            [websiteUrls]="websiteUrls"
            [haloKeywords]="haloKeywords"
            [archivedKeywords]="archivedKeywords"
            [readOnlyReasons]="readOnlyReasons"
            [restrictions]="restrictions">
          </app-components-shared-halo-keywords>
        </div>
      </app-tab>
    </ng-container>
    <ng-container *ngIf="!restrictions.includes(customerCampaignRestrictionTypes_HideArchivedTab)">
      <app-tab [title]="'Archived'">
        <div *tabContent>
          <app-components-shared-archived-pages
            [customer]="customer"
            [websiteUrls]="websiteUrls"
            [archivedWebsiteUrls]="archivedWebsiteUrls"
            [readOnlyReasons]="readOnlyReasons"
            [restrictions]="restrictions">
          </app-components-shared-archived-pages>
          <app-components-shared-archived-keywords
            [customer]="customer"
            [websiteUrls]="websiteUrls"
            [haloKeywords]="haloKeywords"
            [archivedKeywords]="archivedKeywords"
            [readOnlyReasons]="readOnlyReasons"
            [firstPageKeywordPhrases]="firstPageKeywordPhrases"
            [restrictions]="restrictions">
          </app-components-shared-archived-keywords>
        </div>
      </app-tab>
    </ng-container>
  </app-tab-panel>

  @if (!restrictions.includes(customerCampaignRestrictionTypes_HideStrategyUpdateRequestButton))
  {
    @if (strategyUpdateRequested) {
      <div class="alert alert-success" role="alert">
        <i class="fas fa-check-circle"></i><strong>Strategy Update requested</strong> - The ticket will open automatically.
      </div>
    } @else if (strategyUpdateRequestEnabled) {
      <button class="btn btn-success" (click)="requestStrategyUpdate()">
        Request Strategy Update
      </button>
    } @else {
      <button class="btn btn-success display-title-when-disabled" disabled [title]="strategyUpdateValidationMessage">
        Request Strategy Update
      </button>
    }
  }
</div>