import { Component, OnDestroy} from '@angular/core';
import { SessionStorageService } from '../../../services/session-storage.service';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-components-verticalselector',
	templateUrl: './VerticalSelector.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: VerticalSelectorComponent,
            multi: true
        }
    ]
})
export class VerticalSelectorComponent implements ControlValueAccessor, OnDestroy {
    verticalId: number;
    verticals: Boo.Objects.Vertical[];
    vertical: FormControl<number> = new FormControl<number>(null);
    verticalSelectionChanged: Subscription;
    onTouched = () => { };

    constructor(private sessionStorageService: SessionStorageService) {
        this.sessionStorageService.getStaticData()
        .subscribe(data => {
            this.verticals = data.Verticals;
            this.verticals.sort((a, b) => {
                if (a.ParentVerticalId === b.ParentVerticalId) {
                    return a.Name.toLowerCase().localeCompare(b.Name.toLowerCase());
                } else {
                    let parentA = a.ParentVerticalId ? this.verticals.find(x => x.VerticalId === a.ParentVerticalId) : a;
                    let parentB = b.ParentVerticalId ? this.verticals.find(x => x.VerticalId === b.ParentVerticalId) : b;
                    if (parentA === a) {
                        return -1;
                    } else if (parentB === b) {
                        return 1;
                    }

                    return parentA.Name.toLowerCase().localeCompare(parentB.Name.toLowerCase());
                }
            });
        });
    }    

    ngOnDestroy(): void {
        if (this.verticalSelectionChanged) {
            this.verticalSelectionChanged.unsubscribe();
        }
    }

    writeValue(obj: any): void {
        !obj ? this.vertical.setValue(null) : this.vertical.setValue(obj);
    }

    registerOnChange(fn: any): void {
        this.verticalSelectionChanged = this.vertical.valueChanges.subscribe(fn);
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.vertical.disable();
        } else {
            this.vertical.enable();
        }
    }

    groupByFn = (item: Boo.Objects.Vertical) =>
        item.ParentVerticalId ? item.ParentVerticalId : item.VerticalId;

    groupValueFn = (_: string, children: any[]) =>
        this.verticals.find(x => x.VerticalId === children[0].ParentVerticalId) ?? this.verticals.find(x => x.VerticalId === children[0].VerticalId);

    searchFn = (searchTerm: string, item: Boo.Objects.Vertical) =>
        item.Name.toLowerCase().includes(searchTerm.toLowerCase()) || this.verticals.find(x => x.VerticalId === item.ParentVerticalId)?.Name.toLowerCase()?.includes(searchTerm.toLowerCase());
}
