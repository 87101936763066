import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import CustomValidators from '../../../../shared/custom-validators';
import { KeywordResearchTypes, PageSuggestion, PageSuggestionSources } from '../../../../shared/models/keywords/keywords';
import { KeywordResearchService } from '../../../../services/keyword-research.service';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-shared-keyword-research-add-page',
  templateUrl: './add-page.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class AddPageComponent implements OnInit {
  @Input() keywordResearchType: KeywordResearchTypes;
  @Input() customer: Boo.Objects.Customer;
  @Input() pageSuggestions: PageSuggestion[];
  @Output() newPagesRequested = new EventEmitter<void>();

  @ViewChild('pageInput') pageInput: ElementRef;

  pageControl: FormControl<string>;
  addingPage: boolean = false;
  addingLivePage: boolean = false;
  requireNewPages: boolean = false;
  newPageRequestSubmitted: boolean = false;

  constructor(
    private customerCampaignService: CustomerCampaignService,
    private keywordResearchService: KeywordResearchService) { }

  ngOnInit(): void {
    this.setupForm();

     // Require new pages if the website generated page domains do not match the customer url domain
     this.requireNewPages = this.pageSuggestions.filter(x => x.SourceId === PageSuggestionSources.Website).some(x => !this.customerCampaignService.domainMatches(this.customer.Url, x.Url))
      && this.keywordResearchType === KeywordResearchTypes.SeoOnboarding;
  }

  toggleAddingPage(isLive: boolean): void {
    this.addingPage = !this.addingPage;
    this.addingLivePage = isLive;

    if (this.addingPage) {
      this.focus();
    } else {
      this.pageControl.reset('');
    }
  }

  setupForm(): void {
    this.pageControl = new FormControl('', [
      CustomValidators.validUrl(this.customer.Url),
      CustomValidators.stringsNotAllowedMethod(this.getUnavailableUrls.bind(this), true, 'This URL is already in use.'),
      CustomValidators.domainMustMatchPrefix(this.customer.Url, this.customer.Url, `Domain must match the customer's domain.`),
      CustomValidators.maxLengthPrefix(this.customer.Url, 512)
    ]);
  }

  getUnavailableUrls(): string[] {
    return this.pageSuggestions ? this.pageSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).map(x => x.Url.replace(this.customer.Url, '')) : [];
  }

  addPage(): void {
    this.pageControl.markAsDirty();
    this.pageControl.updateValueAndValidity();

    if (!this.pageControl.valid) {
      toastr.error("Please correct errors before adding a page.");
      return;
    }

    let url = this.customer.Url.trim() + this.pageControl.value.trim();
    let existingPageSuggestion = this.pageSuggestions.find(x => x.Url.toLowerCase().trim() === url.toLowerCase() && x.IsCampaignNational === this.customer.IsCampaignNational);
    if (existingPageSuggestion) {
      existingPageSuggestion.IsSelected = true;
      existingPageSuggestion.IsModified = true;
    } else {
      this.pageSuggestions.push({
        PageSuggestionId: 0,
        CustomerId: this.customer.CustomerId,
        SourceId: PageSuggestionSources.User,
        IsCampaignNational: this.customer.IsCampaignNational,
        Url: url,
        IsLive: this.addingLivePage,
        IsSelected: true,
        KeywordSuggestions: [],
        KeywordResearch: [],
        PendingKeywordResearch: null,
        InsertedDate: null,
        ArchivedDate: null,
        ReadOnlyReason: null,
        IsModified: true
      } as PageSuggestion);
    }

    this.toggleAddingPage(true);
  }

  addStub() {
    this.pageSuggestions.push({
      PageSuggestionId: 0,
      CustomerId: this.customer.CustomerId,
      SourceId: PageSuggestionSources.User,
      IsCampaignNational: this.customer.IsCampaignNational,
      Url: '',
      IsLive: false,
      IsSelected: true,
      KeywordSuggestions: [],
      KeywordResearch: [],
      PendingKeywordResearch: null,
      InsertedDate: null,
      ArchivedDate: null,
      ReadOnlyReason: null,
      IsModified: true
    } as PageSuggestion);
  }

  pageSort = (a: PageSuggestion, b: PageSuggestion): number => this.keywordResearchService.pageSort(a, b);

  addPageSuggestion(pageSuggestion: PageSuggestion) {
    pageSuggestion.IsSelected = true;
    pageSuggestion.IsModified = true;
  }

  requestNewPages(): void {
    if (this.keywordResearchType !== KeywordResearchTypes.SeoOnboarding) {
      return;
    }

    bootbox.confirm(`<p>Are you sure you want to generate new pages?</p><p class='text-danger'>WARNING: All existing keyword research will be discarded and the ticket will be closed without saving.</p>`, (result: boolean) => {
      if (!result) {
          return;
      }

      this.newPageRequestSubmitted = true;
      this.newPagesRequested.emit();
    });
  }

  get availablePageSuggestions(): PageSuggestion[] {
    return this.pageSuggestions ? this.pageSuggestions.filter(x => !x.IsSelected && [PageSuggestionSources.Website, PageSuggestionSources.CustomerCampaign].includes(x.SourceId) && x.IsCampaignNational === this.customer.IsCampaignNational) : [];
  }

  private focus() {
    setTimeout(() => {
      if (this.pageInput) {
        this.pageInput.nativeElement.focus();
      }
    }, 0);
  }
}