/* tslint:disable */

import { Component, ViewChild } from '@angular/core';
import app from 'App';
import timer from 'app/managecustomer/Timer';
import permissions from 'app/models/Permissions';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { ViewLocatorService } from '../../services/view-locator.service';
import { CustomerService } from '../../services/customer.service';
import { CustomerTouchService } from '../../services/customer-touch.service';
import { StoreService } from '../../services/store.service';
import { WebsiteService } from '../../services/website.service';
import ProductEnum = Boo.Objects.Enums.ProductEnum;
import { SessionStorageService } from '../../services/session-storage.service';
import Utils from '../../shared/utils';
import { forkJoin } from 'rxjs';
import Constants from '../../shared/constants';
import { CustomerInformationComponent } from './CustomerInformation/CustomerInformation.component';
import System from 'framework/System';

@Component({
    selector: 'app-components-managecustomer',
    templateUrl: './ManageCustomer.component.html'
})
export class ManageCustomerComponent {
    @ViewChild(CustomerInformationComponent) customerInformationComponent: CustomerInformationComponent;

    public ko = ko;

    constructor(
      private viewLocatorService: ViewLocatorService,
      private customerService: CustomerService,
      private customerTouchService: CustomerTouchService,
      private storeService: StoreService,
      private websiteService: WebsiteService,
      private sessionStorageService: SessionStorageService) { }

    public canActivate(user: Boo.Objects.User): boolean {
        return true;
    }

    private dfd: any;
    // Fields
    private notesAndTicketsPublicApi: any;
    public notesAndTicketsPublicApiCallback(api: any): void { this.notesAndTicketsPublicApi = api; }
    // Note: Permission should be the name of the view permission or true (if no permission is required).
    private childMenu = [
        { title: 'Notes and Tickets', selector: 'app-components-managecustomer-notesandtickets', isActive: ko.observable(false), permission: true, apiCallback: <any>null },
        { title: 'Basic Information', selector: 'app-components-managecustomer-basicinformation', isActive: ko.observable(false), permission: true, apiCallback: null },
        { title: 'To Do History', selector: 'app-components-managecustomer-customertodohistory', isActive: ko.observable(false), permission: true, apiCallback: null },
        { title: 'SEO Content Survey', selector: 'app-components-managecustomer-seocontentsurvey', isActive: ko.observable(false), permission: true, apiCallback: null },
        { title: 'Customer Contact', selector: 'app-components-managecustomer-customerusers', isActive: ko.observable(false), permission: true, apiCallback: null },
        { title: 'Logins', selector: 'app-components-managecustomer-logins', isActive: ko.observable(false), permission: true, apiCallback: null },
        { title: 'Customer Campaign', selector: 'app-components-managecustomer-customer-campaign', isActive: ko.observable(false), permission: 'CanManageCustomerCampaign', apiCallback: null },
        { title: 'Local Profile', selector: 'app-components-managecustomer-local-profiles', isActive: ko.observable(false), permission: true, apiCallback: null },
        { title: 'Websites', selector: 'app-components-websites', isActive: ko.observable(false), permission: true, apiCallback: null },
        { title: 'Work History', selector: 'app-components-managecustomer-customerworkhistory', isActive: ko.observable(false), permission: true, apiCallback: null },
        { title: 'Files', selector: 'app-components-managecustomer-files', isActive: ko.observable(false), permission: true, apiCallback: null },
        { title: 'Published Custom Blogs', selector: 'app-components-managecustomer-blogposts', isActive: ko.observable(false), permission: 'CanRepublishBlogs', apiCallback: <any>null },
        { title: 'Allocate Work', selector: 'app-components-managecustomer-allocatework', isActive: ko.observable(false), permission: 'CanUpdateAdvancedSettings', apiCallback: null },
    ];

    // Knockout Properties
    public user = ko.observable();
    public partnerUser = ko.observable();
    public partner: KnockoutObservable<Boo.Objects.Partner> = ko.observable();
    public isLoading = ko.observable(false);
    public isMenuLoading = ko.observable(false);
    public customer = ko.observable();
    public customerId: KnockoutObservable<number> = ko.observable();
    public customerUser = ko.observable();
    public currentView = ko.observable();
    public currentViewModel = ko.observable();
    public activeChild = ko.observable();
    public isTimeoutConfirmVisible = ko.observable(false);
    public ticketToEdit = ko.observable();
    public shouldLogCustomerTouch = true;
    // Public properties
    public customerTimoutInterval = {};
    public title: string;
    showStoreLink: boolean = true;
    // Observable arrays
    public children = ko.observableArray(this.childMenu);
    // Computed
    public partnerName = ko.computed(() => {
        if (this.customer() && (this.customer() as any).Partner) {
            return (this.customer() as any).Partner.Name();
        }
        return '';
    });
    // Private Functions
    private openTicket(ticketId: any): void {
        // wait a maximum of 5 seconds to load the edit ticket priority view
        let runTimes = 0;
        const ticketTimer = setInterval(() => {
            runTimes++;
            if (!PriorityViewModel.isLoadingPriorityView) {
                clearInterval(ticketTimer);
                const ticketOptions = {
                    ticketId: ticketId,
                    customer: this.customer()
                };
                setTimeout(() => {
                    PriorityViewModel.show('app-components-ticket', ticketOptions).always((result: any) => {
                        if (result.modifiedDataFlags && result.modifiedDataFlags.customer) {
                            this.refreshCustomer();
                        }
                        if (Object.is((this.activeChild() as any).apiCallback, this.notesAndTicketsPublicApiCallback.bind(this))) {
                            this.notesAndTicketsPublicApi.reloadNotes();
                            this.notesAndTicketsPublicApi.reloadTickets();
                        }
                    });
                },
                    500);
            }
            if (runTimes >= 10) {
                clearInterval(ticketTimer);
            }
        },
            500);
    }
    private removeTab(title: any): void {
        const tab = _.findWhere(this.children(), { title: title });
        if (tab) {
            this.children.remove(tab);
        }
    }
    // Public Functions
    public openTab(title: string): void {
        const item = _.findWhere(this.children(), { title: title });
        this.activateItem(item);
    }

    public close(closeAll?: any) {
        if (this.dfd) {
            if (closeAll === true) {
                this.dfd.reject({ closeAll: true }); // The closeAll property here alerts the cleanUpPriorityView to close all priority views
            } else {
                this.dfd.reject();
            }
        }
    }

    public startTimer() {
        // Start the timer utility and start the interval to keep track of elapsed time
        timer.start();
        this.customerTimoutInterval = setInterval(() => this.checkUIActivity(), 1000);
    }

    public checkUIActivity(): any {
        const elapsedMinutes = timer.getElapsedMinutesSinceLastUIActivity();
        if (elapsedMinutes === 60) {
            if (this.isTimeoutConfirmVisible() === true) {
                return;
            }
            this.isTimeoutConfirmVisible(true);
            // This bandaids an issue where over time the customer has become undefined. I could not reproduce the situation to make the customer undefined.
            if (!this.customer()) {
                toastr.error('An error occurred and the customer will now be closed. Please reopen the customer to continue work.');
                this.shouldLogCustomerTouch = false;
                this.close(true);
                return;
            }
            const confirmMessage = `You have been inactive for over 60 minutes on Customer (${(this.customer() as any).CustomerId()})</br> Name: ${(this.customer() as any).Name()}</br> URL: ${(this.customer() as any).Url()}</br></br>Click 'OK' to continue working on this customer.</br> Click 'Cancel' to close this customer.`;
            const inactiveTimeStamp = moment();
            bootbox.confirm(confirmMessage,
                (result: any) => {
                    this.isTimeoutConfirmVisible(false);
                    if (result === true) {
                        const inactiveMilliseconds = moment().diff(inactiveTimeStamp);
                        timer.addInactiveMilliseconds(inactiveMilliseconds);
                        timer.resetUIActivityTime();
                    } else {
                        this.shouldLogCustomerTouch = false;
                        this.close(true);
                    }
                });
        }
    }

    public logCustomerTouch(scope: any) {
        if (this.shouldLogCustomerTouch === false) {
            return;
        }
        if (timer.getElapsedSecondsSinceLastCustomerTouch() <= 5) {
            return;
        }
        if (_.isString(scope) === false) {
            return;
        }
        if (!this.customer()) {
            return;
        }
        Utils.wrapDfd(this.customerTouchService.insert((this.customer() as any).CustomerId(), timer.getElapsedSecondsSinceLastCustomerTouch(), scope))
            .then(() => {
                timer.resetCustomerTouchTime();
            })
            .fail((displayMessage) => {
                toastr.error(displayMessage, 'Error');
            });
    }

    public activateItem(item: any): void {
        let currentlySelectedItemTitle;
        _.each(this.children(),
            (innerItem) => {
                if (innerItem.isActive() === true) {
                    currentlySelectedItemTitle = innerItem.title;
                }
                innerItem.isActive(false);
            });
        this.logCustomerTouch(currentlySelectedItemTitle);
        item.isActive(true);
        this.activeChild(item);
    }

    public setChildPageVisibility(partnerUser: Boo.Objects.PartnerUser, partner: any, websites: any) {
        if (!partnerUser) {
            toastr.error('There was an error while opening the customer.', 'Error');
            if (this.dfd) {
                this.dfd.reject();
            }
            return;
        }
        if (websites.length <= 0) {
            this.removeTab('Websites');
        }

        const hasOnlyEverHadBusinessProfileManagement = this.customerService.hasOnlyEverHadProduct(this.customer() as any, ProductEnum.BusinessProfileManagement);
        if (hasOnlyEverHadBusinessProfileManagement)
        {
            this.removeTab('SEO Content Survey');
            this.removeTab('Keywords and Urls');
            this.removeTab('Customer Campaign');
            this.removeTab('Files');
            this.removeTab('Published Custom Blogs');
            this.removeTab('Allocate Work');
        }

        if (partnerUser.IsReadonly) {
            this.showStoreLink = false;
        }
    }

    public refreshCustomer(callback?: any): void {
        this.isMenuLoading(true);
        forkJoin([
          this.sessionStorageService.getPartner(),
          this.sessionStorageService.getPartnerUser(),
          this.customerService.get(this.customerId()),
          this.websiteService.getManyByCustomerId(this.customerId())
        ])
        .subscribe(([partner, partnerUser, customer, websites]) => {
          this.customer(ko.mapping.fromJS(customer, Constants.CustomerMapping));
          this.customerUser(_.find((this.customer() as any).Users(), (User: any) => { return User.UserLevelId() === window.launchpad.config.keys.analyticsUserLevelId && User.CanContact(); }));
          this.partner(customer.Partner);
          this.partnerUser(partnerUser);
          this.children(this.childMenu);
          this.setChildPageVisibility(partnerUser, partner, websites);
          this.isMenuLoading(false);
          this.customerInformationComponent.setContactEmail();
          if (callback) {
              callback();
          }
        });
    }

    public getRefreshedCustomer(): KnockoutObservable<any> {
      return this.customer;
    }

    public deactivate() {
        this.logCustomerTouch('Closing Customer');
        clearInterval(this.customerTimoutInterval as any);
        app.hasAdultContent(false);
        app.isHighProfile(false);
        app.highProfileReason('');
    }

    public activate(options: any) {
        if (!options || !options.customerId) {
            toastr.error('Unable to Open Manage Customer');
            this.close();
            return;
        }
        this.viewLocatorService.setDisplayedRoute(`customerservice/managecustomer/${options.customerId}`);
        this.isLoading(true);
        this.customerId(options.customerId);
        this.title = `Manage Customer (${this.customerId()})`;

        return $.when<any>(
          Utils.wrapDfd(this.sessionStorageService.getUser()),
          Utils.wrapDfd(this.sessionStorageService.getPartner()),
          Utils.wrapDfd(this.sessionStorageService.getPartnerUser()),
          Utils.wrapDfd(this.customerService.get(this.customerId())),
          Utils.wrapDfd(this.websiteService.getManyByCustomerId(this.customerId())))
          .then((user, partner, partnerUser, customer, websites) => {
            if (!customer || customer.StatusId < 2) {
                return System.autoRejectedPromise('Cannot open customer.');
            }
            // #region Make sure the User has access to open this customer
            const teams: any = [];
            const hasCustomerServiceUser = _.any(user.PartnerUsers, (pu: any) => { return pu.UserLevelId === 6; }); // User has a customer service user
            _.each(user.PartnerUsers,
                (pUser: any) => {
                    if (pUser.Teams) {
                        _.each(pUser.Teams,
                            (pUTeam) => {
                                teams.push(pUTeam);
                            });
                    }
                });
            if (partner.PartnerId === 1) {
                if (customer.Team) {
                    const hasAllTeams = launchpad.hasPermission(partner, user.PartnerUsers, permissions.HasAllTeams, user);
                    if (!hasAllTeams &&
                        !_.find(teams,
                            (uTeam: any) => {
                                return uTeam.TeamId === customer.Team.TeamId;
                            })) {
                        let message = "You don't have access to open this customer.";
                        if (!hasCustomerServiceUser) {
                            message += '<br/><br/>Add a Customer Service User and try again.';
                        }
                        
                        return System.autoRejectedPromise(message);
                    }
                }
            } else if (!hasCustomerServiceUser) {
                toastr.warning("You currently do not have a <strong>Customer Service</strong> user. This will prevent you from making updates to this customer's account.");
            }
            app.isHighProfile(customer.IsHighProfile);
            app.highProfileReason('');
            if (customer.IsHighProfile) {
                bootbox.alert(`<h4>This customer is high profile.</h4>  <div class="form-group"><label>Reason</label><div>${customer.HighProfileReason}</div></div>`);
                app.highProfileReason(customer.HighProfileReason);
            }
            const showAdultContentWarning = customer.HasAdultContent && !partnerUser.CanViewAdultContent;
            if (showAdultContentWarning) {
                const adultMsg = '<h4>This customer\' website contains adult content</h4>  <div>ie - nudity, sexual content, provocative images<div>';
                bootbox.confirm(adultMsg,
                    (result: any) => {
                        if (result === false) {
                            this.close(true);
                        }
                    });
            }
            app.hasAdultContent(showAdultContentWarning);
            // #endregion

            this.customer(ko.mapping.fromJS(customer, Constants.CustomerMapping));
            this.customerUser(_.find((this.customer() as any).Users(), (User: any) => { return User.UserLevelId() === window.launchpad.config.keys.analyticsUserLevelId && User.CanContact(); }));
            this.user(user);
            this.partner(customer.Partner);
            this.partnerUser(partnerUser);
            this.setChildPageVisibility(partnerUser, partner, websites);
            // Users can open "EditTicket" from help next customer.
            // The app needs to open managecustomer prior to opening and editing the ticket
            // To handle this the ticket needs to be passed from help next customer to managecustomer to notesAndTickets to editTicket
            if (_.isNumber(options.ticketIdToEdit)) {
                this.openTicket(options.ticketIdToEdit);
            }
            // Activate the Notes and Tickets Tab
            this.activateItem(this.children()[0]);
            // Start the timing operations
            this.startTimer();
        }).fail((displayMessage) => {
            this.close();
            toastr.error(displayMessage);
        }).always(() => {
            this.isLoading(false);
        });
    }

    public manageCustomerPublicApi = {
        floatingNoteSaved: () => {
            if (Object.is((this.activeChild() as any).apiCallback, this.notesAndTicketsPublicApiCallback.bind(this))) {
                this.notesAndTicketsPublicApi.reloadNotes();
            }
        }
    };

    public redirectToConfigureProducts() {
      Utils.wrapDfd(this.storeService.sso((this.customer() as any).CustomerId(), '/place-order'))
        .then(url => window.location.href = url)
        .fail(err => toastr.error(err));
    }
}
