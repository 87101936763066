import { Component } from '@angular/core';
import PresignedUrlRequest from 'app/models/typescript/PresignedUrlRequest';
import Check from 'framework/Check';
import FileUploader from 'framework/FileUploader';
import { FileExtensions } from '../../../shared/file-extensions';

@Component({
  selector: 'app-components-shared-uploadfile',
  templateUrl: './UploadFile.component.html'
})
export class UploadFileComponent implements IActivatable {
  base64: string;
  file: File;
  isLoading = false;
  invalidFile = false;
  acceptedFileExtensions: string[] = FileExtensions.defaults();
  displayFileExtensions: string;
  protected priorityViewApi: app.interfaces.IPriorityViewPublicApi;
  private explicitFilename: string;
  private validFileRegex: RegExp;
  private acceptAllFileTypes: boolean;
  private maxUploadInMB: number = 15;
  private preSignedURLRequest: PresignedUrlRequest;

  constructor(private fileUploader: FileUploader) { }

  public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: app.interfaces.IUploadFileActivateParams): boolean {
    Check.isNotEmpty(params.preSignedURLRequest, 'A pre-signed URL request is required.');

    this.priorityViewApi = params.priorityViewApi || null;
    this.preSignedURLRequest = ko.utils.unwrapObservable(params.preSignedURLRequest);
    this.explicitFilename = params.explicitFilename || null;
    this.acceptAllFileTypes = params.acceptAllFileTypes || false;
    this.maxUploadInMB = params.maxUploadInMB ?? this.maxUploadInMB;
    this.acceptedFileExtensions = params.acceptedFileExtensions || this.acceptedFileExtensions;
    this.validFileRegex = new RegExp(`\.(${this.formatFileExtensionsForRegex(this.acceptedFileExtensions)})$`, 'i');
    this.displayFileExtensions = this.acceptAllFileTypes ? '*' : this.acceptedFileExtensions.join(', ');

    return true;
  }

  updateFile(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.invalidFile = false;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.base64 = e.target.result;
        this.file = new File([this.createBlob(this.base64)], file.name, { type: file.type });
      };
      reader.readAsDataURL(file);
    }
  }

  close(): void {
    if (this.priorityViewApi) {
      this.priorityViewApi.reject();
    }
  }

  upload(): void {
    this.invalidFile = false;

    if (!this.file) {
      toastr.error('No file selected.');
      this.invalidFile = true;
      return;
    }

    if (!this.validFileType()) {
      toastr.error(`Invalid file type. Only ${this.acceptedFileExtensions.join(', ')} are allowed.`);
      this.invalidFile = true;
      return;
    }

    if (!this.validFileSize()) {
      toastr.error(`File size must be less than ${this.maxUploadInMB}MB`);
      this.invalidFile = true;
      return;
    }

    this.preSignedURLRequest.Filename = this.explicitFilename ? this.explicitFilename : this.file.name.replace(/ /g, '_');
    if (this.file.type) {
      this.preSignedURLRequest.ContentType = this.file.type;
    }

    this.isLoading = true;
    this.fileUploader.upload(this.file, this.preSignedURLRequest.Folder, this.file.name, this.preSignedURLRequest.BucketName)
      .then((preSignedUrl: Boo.Objects.Amazon.PreSignedUrl) => {
        if (this.priorityViewApi) {
          this.priorityViewApi.resolve(preSignedUrl);
        }
      })
      .fail((message: any) => {
        if (this.priorityViewApi) {
          this.priorityViewApi.reject('Unable to upload file.');
        } else {
          toastr.error('Unable to upload file.');
        }
      })
      .always(() => {
        this.isLoading = false;
      });
  }

  validFileType(): boolean {
    return this.acceptAllFileTypes || this.validFileRegex.test(this.file.name);
  }

  private validFileSize(): boolean {
    return this.file && (this.file.size / 1024 / 1024) <= this.maxUploadInMB;
  }

  private createBlob(base64Data: string): Blob {
    let byteString: string;
    if (base64Data.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(base64Data.split(',')[1]);
    } else {
      byteString = decodeURI(base64Data.split(',')[1]);
    }

    let mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];

    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  private formatFileExtensionsForRegex(acceptedExtensions: string[]): string {
    return acceptedExtensions
      .map(ext => ext.replace('.', ''))
      .join('|');
  }
}
