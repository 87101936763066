import TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum;

export const taskTypeActionFeatures = {
  workblockable: [
    TaskTypeEnum.LocalBusinessCitations,
    TaskTypeEnum.OnsiteRecommendationCreateContent,
    TaskTypeEnum.OnsiteRecommendationImplementation,
    TaskTypeEnum.OnsiteBloggingPostContent,
    TaskTypeEnum.OnsiteBloggingSetUpBlog,
    TaskTypeEnum.OnsiteBlogImplementationManual,
    TaskTypeEnum.OnsiteCopyCreateContentStandard,
    TaskTypeEnum.OnsiteCopyPublishCopy,
    TaskTypeEnum.GoogleSearchConsoleCreationAndInstallation,
    TaskTypeEnum.DomainTransfer,
    TaskTypeEnum.SiteSpeedOptimization,
    TaskTypeEnum.RobotsTxtFileCreationAndImplementation,
    TaskTypeEnum.XMLSitemapCreationAndImplementation,
    TaskTypeEnum.GoogleAnalyticsCreationAndImplementation,
    TaskTypeEnum.SchemaTagImplementation,
    TaskTypeEnum.ThreeHundredOneRedirectMappingAndImplementation,
    TaskTypeEnum.CanonicalDomainImplementation,
    TaskTypeEnum.CanonicalTagImplementation,
    TaskTypeEnum.GoogleMapsIntegration,
    TaskTypeEnum.OnsiteBrokenLinkRepair,
    TaskTypeEnum.ClickTrackingImplementation,
    TaskTypeEnum.CompetitiveAudit,
    TaskTypeEnum.WebPageCreation,
    TaskTypeEnum.WebPageDeletion,
    TaskTypeEnum.MenuEdits,
    TaskTypeEnum.InsertNofollowTagsIntoOnsiteLinks,
    TaskTypeEnum.LoginCredentialTesting,
    TaskTypeEnum.OnsiteCopyImplementationManual,
    TaskTypeEnum.OnsiteRecommendationImplementationManual,
    TaskTypeEnum.VideoImageImplementation,
    TaskTypeEnum.OnsiteBlogInstallationStandardCMS,
    TaskTypeEnum.BlogMatching,
    TaskTypeEnum.PremiumPreliminaryAudit,
    TaskTypeEnum.PremiumMonthlyCampaignUpdate,
    TaskTypeEnum.PreliminaryAudit,
    TaskTypeEnum.MonthlyCampaignUpdate,
    TaskTypeEnum.RemoveExcessH1Tags,
    TaskTypeEnum.RobotsTxtCreation,
    TaskTypeEnum.SitemapXmlCreation,
    TaskTypeEnum.PreAudit,
    TaskTypeEnum.KeywordResearchBasic,
    TaskTypeEnum.KeywordResearchStandard,
    TaskTypeEnum.KeywordResearchPremium,
    TaskTypeEnum.KeywordResearchRedoBasic,
    TaskTypeEnum.KeywordResearchRedoStandard,
    TaskTypeEnum.KeywordResearchRedoPremium,
    TaskTypeEnum.CreateBoostSitesLandingPage,
    TaskTypeEnum.BoostSitesBuild,
    TaskTypeEnum.ProactiveUnderPerformingKeyword,
    TaskTypeEnum.ProactiveUnderPerformingKeywordMoneyBack,
    TaskTypeEnum.OnsiteBloggingRevisionStandard,
    TaskTypeEnum.OnsiteBloggingRevisionPremium,
    TaskTypeEnum.UrlEditTask,
    TaskTypeEnum.ClassifiedBusinessListings,
    TaskTypeEnum.BusinessProfileValidationAndAugmentation,
    TaskTypeEnum.ResolveBusinessProfileIssues,
    TaskTypeEnum.KeywordResearchStrategyUpdateResearch,
    TaskTypeEnum.KeywordResearchStrategyUpdateRedo,
    TaskTypeEnum.StandaloneBusinessProfileValidationAndAugmentation
  ],

  canNotWorkblockTaskType: [
    TaskTypeEnum.BusinessProfileValidationAndAugmentation,
    TaskTypeEnum.ResolveBusinessProfileIssues
  ],

  rejectable: [
    TaskTypeEnum.OnsiteCopyReviewStandard,
    TaskTypeEnum.OnsiteCopyPreview,
    TaskTypeEnum.OnsiteRecommendationReview,
    TaskTypeEnum.OnsiteBloggingCustomerPreview,
    TaskTypeEnum.OnsiteImplementationReview,
    TaskTypeEnum.OnsiteCopyImplementationReview,
    TaskTypeEnum.OnsiteBloggingImplementationReview,
    TaskTypeEnum.OnsiteRecommendationImplementationReview
  ],

  rejectableWithoutReason: [
    TaskTypeEnum.OnsiteBloggingReviewStandard,
    TaskTypeEnum.OnsiteBloggingReviewPremium,
    TaskTypeEnum.ContentReviewBlog
  ],

  rejectableByRejectionType: [
    TaskTypeEnum.OnsiteImplementationReview,
    TaskTypeEnum.OnsiteBloggingImplementationReview,
    TaskTypeEnum.OnsiteCopyImplementationReview,
    TaskTypeEnum.OnsiteRecommendationImplementationReview
  ],

  hasTimer: [
    TaskTypeEnum.OnsiteCopyPublishCopy,
    TaskTypeEnum.GoogleSearchConsoleCreationAndInstallation,
    TaskTypeEnum.DomainTransfer,
    TaskTypeEnum.SiteSpeedOptimization,
    TaskTypeEnum.RobotsTxtFileCreationAndImplementation,
    TaskTypeEnum.XMLSitemapCreationAndImplementation,
    TaskTypeEnum.GoogleAnalyticsCreationAndImplementation,
    TaskTypeEnum.SchemaTagImplementation,
    TaskTypeEnum.ThreeHundredOneRedirectMappingAndImplementation,
    TaskTypeEnum.CanonicalDomainImplementation,
    TaskTypeEnum.CanonicalTagImplementation,
    TaskTypeEnum.GoogleMapsIntegration,
    TaskTypeEnum.OnsiteBrokenLinkRepair,
    TaskTypeEnum.ClickTrackingImplementation,
    TaskTypeEnum.WebPageCreation,
    TaskTypeEnum.WebPageDeletion,
    TaskTypeEnum.MenuEdits,
    TaskTypeEnum.InsertNofollowTagsIntoOnsiteLinks,
    TaskTypeEnum.LoginCredentialTesting,
    TaskTypeEnum.OnsiteCopyImplementationManual,
    TaskTypeEnum.OnsiteRecommendationImplementationManual,
    TaskTypeEnum.VideoImageImplementation,
    TaskTypeEnum.OnsiteBlogInstallationStandardCMS,
    TaskTypeEnum.BlogMatching,
    TaskTypeEnum.RemoveExcessH1Tags,
    TaskTypeEnum.RobotsTxtCreation,
    TaskTypeEnum.SitemapXmlCreation,
    TaskTypeEnum.PreAudit,
    TaskTypeEnum.KeywordResearchBasic,
    TaskTypeEnum.KeywordResearchPremium,
    TaskTypeEnum.KeywordResearchStandard,
    TaskTypeEnum.KeywordResearchRedoBasic,
    TaskTypeEnum.KeywordResearchRedoPremium,
    TaskTypeEnum.KeywordResearchRedoStandard,
    TaskTypeEnum.KeywordResearchStrategyUpdateResearch,
    TaskTypeEnum.KeywordResearchStrategyUpdateRedo,
    TaskTypeEnum.ProactiveUnderPerformingKeyword,
    TaskTypeEnum.ProactiveUnderPerformingKeywordMoneyBack
  ],

  canNotUnlockAndUnassign: [
    TaskTypeEnum.OnsiteBloggingPostContent,
    TaskTypeEnum.OnsiteCopyPreview,
    TaskTypeEnum.OnsiteCopyPublishCopy,
    TaskTypeEnum.LinkAudit,
    TaskTypeEnum.AdvancedAudit,
    TaskTypeEnum.LinkDetoxAudit,
    TaskTypeEnum.ContentAudit,
    TaskTypeEnum.BasicLinkRemoval,
    TaskTypeEnum.StandardLinkRemoval,
    TaskTypeEnum.PremiumLinkRemoval,
    TaskTypeEnum.GooglePlacesOptimization,
    TaskTypeEnum.ExhaustiveAudit,
    TaskTypeEnum.PremiumRoadMapCreation,
    TaskTypeEnum.DuplicateCopyFixImplementation,
    TaskTypeEnum.PremiumSEOAudit
  ],

  showCustomerCountry: [
    TaskTypeEnum.LocalBusinessCitations,
    TaskTypeEnum.OnsiteRecommendationCreateContent,
    TaskTypeEnum.OnsiteRecommendationReview,
    TaskTypeEnum.ProactiveUnderPerformingKeyword,
    TaskTypeEnum.ProactiveUnderPerformingKeywordMoneyBack
  ],

  showCustomerName: [
    TaskTypeEnum.ProactiveUnderPerformingKeyword,
    TaskTypeEnum.ProactiveUnderPerformingKeywordMoneyBack,
    TaskTypeEnum.BusinessProfileValidationAndAugmentation,
    TaskTypeEnum.StandaloneBusinessProfileValidationAndAugmentation
  ],

  showPartnerName: [
    TaskTypeEnum.PremiumMonthlyCampaignUpdate
  ],

  showTaskId: [
    TaskTypeEnum.PremiumSEOAudit,
    TaskTypeEnum.DomainTransfer,
    TaskTypeEnum.SchemaTagImplementation,
    TaskTypeEnum.GoogleMapsIntegration,
    TaskTypeEnum.ClickTrackingImplementation,
    TaskTypeEnum.LoginCredentialTesting,
    TaskTypeEnum.VideoImageImplementation,
    TaskTypeEnum.OnsiteBlogInstallationStandardCMS,
    TaskTypeEnum.BlogMatching,
    TaskTypeEnum.PremiumPreliminaryAudit,
    TaskTypeEnum.LinkAudit,
    TaskTypeEnum.CompetitiveAudit,
    TaskTypeEnum.AdvancedAudit,
    TaskTypeEnum.LinkDetoxAudit,
    TaskTypeEnum.ContentAudit,
    TaskTypeEnum.BasicLinkRemoval,
    TaskTypeEnum.StandardLinkRemoval,
    TaskTypeEnum.PremiumLinkRemoval,
    TaskTypeEnum.PremiumMonthlyCampaignUpdate,
    TaskTypeEnum.GooglePlacesOptimization,
    TaskTypeEnum.PreliminaryAudit,
    TaskTypeEnum.MonthlyCampaignUpdate,
    TaskTypeEnum.ExhaustiveAudit,
    TaskTypeEnum.PremiumRoadMapCreation,
    TaskTypeEnum.DuplicateCopyFixImplementation,
    TaskTypeEnum.RobotsTxtCreation,
    TaskTypeEnum.SitemapXmlCreation
  ],
  deletable: [
    TaskTypeEnum.BusinessDirectories,
    TaskTypeEnum.WebsiteBookmarking,
    TaskTypeEnum.LocalBusinessCitations,
    TaskTypeEnum.ClassifiedBusinessListings
  ],
  rejectUsingDelete: [
    TaskTypeEnum.ContentReviewBlog
  ]
}
