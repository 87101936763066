import { Component } from '@angular/core';
import MerchantFieldLabelMap from 'app/models/typescript/MerchantFieldLabelMap';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { CustomerService } from '../../../services/customer.service';
import { StoreService } from '../../../services/store.service';
import { PartnerCountryService } from '../../../services/partner-country.service';
import { MerchantFieldsService } from '../../../services/merchant-fields.service';
import { LeadSourceService } from '../../../services/lead-source.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';
import { finalize, forkJoin, from, mergeMap, Observable, switchMap } from 'rxjs';
import System from 'framework/System';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import CustomValidators from '../../../shared/custom-validators';

@Component({
  selector: 'app-components-sales-addcustomer',
  templateUrl: './AddCustomer.component.html'
})
export class AddCustomerComponent implements IActivatable {
    isLoading = false;
    viewAdvancedMerchantInfo: boolean = false;
    partner: Boo.Objects.Partner;
    user: Boo.Objects.User;
    countries: Boo.Objects.Country[] = null;
    countryLanguages: Boo.Objects.CountryLanguage[] = null;
    languages: Boo.Objects.Language[] = null;
    leadSources: Boo.Objects.LeadSource[] = null;
    formGroup: any;
    merchantFieldLabelMap: MerchantFieldLabelMap = null;

    private addedCustomer: Boo.Objects.Customer;

    constructor(
      private customerService: CustomerService,
      private storeService: StoreService,
      private partnerCountryService: PartnerCountryService,
      private merchantFieldsService: MerchantFieldsService,
      private leadSourceService: LeadSourceService,
      private sessionStorageService: SessionStorageService) { }

    activate(): JQueryPromise<void> {
        return System.emptyPromise();
    }

    canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner): boolean {
        this.partner = partner;
        this.user = user;
        this.isLoading = true;
        this.initNewCustomer();
        forkJoin([
            this.leadSourceService.get(),
            this.partnerCountryService.get(this.partner.PartnerId),
            this.sessionStorageService.getStaticData(),
            from(this.merchantFieldsService.generateLabelMapping(this.partner.PartnerId))
        ]).subscribe(([leadSources, partnerCountries, staticData, map]) => {
                this.leadSources = leadSources;
                this.languages = staticData.Languages;
                this.countryLanguages = staticData.CountryLanguages;
                this.OnCountrySelected(this.partner.CountryId);
                this.countries = staticData.Countries.filter(x => partnerCountries.some(pc => pc.CountryId === x.CountryId)
                    && x.SupportStatusId === launchpad.config.supportStatus.supported);
                this.merchantFieldLabelMap = map;
                this.initValidation();
                this.isLoading = false;
        });

        return user.PartnerUsers.some((item) => {
            return item.UserLevelId === Boo.Objects.Enums.UserLevelEnum.Administrator || // Admin
                item.UserLevelId === Boo.Objects.Enums.UserLevelEnum.Manager || // Manager
                item.UserLevelId === Boo.Objects.Enums.UserLevelEnum.Sales; // Sales
        });
    }

    OnCountrySelected(countryId: number){
        this.languages = this.countryLanguages.filter(x => x.CountryId === countryId).map(x => x.Language);
        const languageFormControl = this.formGroup.get('LanguageId');
        if (!this.languages.some((l) => l.LanguageId === languageFormControl.value)) {
            languageFormControl.reset();
        }
    }

    save(): void {
        this.isLoading = true;
        Utils.showAllMessages(this.formGroup);
        if (!this.formGroup.valid) {
            this.isLoading = false;
            return;
        }

        this.createCustomer().pipe(
            switchMap((newCustomer) => {
                toastr.success(`Customer ${newCustomer.CustomerId} added`);
                return this.customerService.get(newCustomer.CustomerId);
            }),
            switchMap((customer) => {
                this.addedCustomer = customer;
                return from(PriorityViewModel.show(
                    'app-components-managecustomer-customerusers',
                    {
                        customer: ko.mapping.fromJS(this.addedCustomer),
                        user: ko.observable(this.user),
                        partner: ko.observable(this.partner)
                    },
                    false));
            }),
            switchMap(() => this.storeService.sso(this.addedCustomer.CustomerId, `/place-order`, `#/sales/addcustomer`)),
            finalize(() => this.isLoading = false)
        )
        .subscribe({
            next: url => window.location.href = url,
            error: err => toastr.error(err)
        });
    }

    showAdvancedMerchantInfo(): void {
        this.viewAdvancedMerchantInfo = !this.viewAdvancedMerchantInfo;
    }

    addLeadSource = (name) => {
        this.leadSourceService.save({
            Name: name,
            LeadSourceId: 0,
            CategoryId: null,
            CategoryName: null,
            CostPerSeat: null,
            RetailPerHour: null,
            RevsharePercent: null,
            RevsharePercentSetup: null,
            WholesalePerHour: null,
        }).pipe(
            switchMap(() => this.leadSourceService.get())
        ).subscribe({
            next:(leadSources) => {
                this.leadSources = leadSources;
                this.formGroup.get('LeadSourceId').setValue(leadSources.find(x => x.Name === name).LeadSourceId);
                toastr.success(`Lead Source "${name}" added`);
            },
            error: err => toastr.error(err)
        });
        return;
    }

    private createCustomer(): Observable<Boo.Objects.Customer> {
        const newCustomer: ICustomerCreate = this.formGroup.value;
        const customer = <Boo.Objects.Customer>{
            Name: newCustomer.Company,
            PartnerId: this.partner.PartnerId,
            Email: newCustomer.Email,
            Phone: newCustomer.Phone,
            LanguageId: newCustomer.LanguageId,
            CountryId: newCustomer.CountryId,
            Url: newCustomer.Url,
            ReferredBy: newCustomer.ReferredBy,
            LeadSourceId: newCustomer.LeadSourceId
        };

        const merchantFields = <Boo.Objects.CustomerMerchantFields>{
            MerchantId: newCustomer.MerchantId,
            MerchantSource: newCustomer.MerchantSource,
            MerchantProposalId: newCustomer.MerchantProposalId,
            MerchantCategory: newCustomer.MerchantCategory,
            MerchantCategoryId: newCustomer.MerchantCategoryId,
            MerchantSubCategory: newCustomer.MerchantSubCategory,
            MerchantSubCategoryId: newCustomer.MerchantSubCategoryId,
            MerchantConsultant: newCustomer.MerchantConsultant,
            MerchantConsultantContact: newCustomer.MerchantConsultantContact,
            MerchantUdac: newCustomer.MerchantUdac,
            MerchantProductCode: newCustomer.MerchantProductCode,
            MerchantItemId: newCustomer.MerchantItemId,
            MerchantMiscId: newCustomer.MerchantMiscId,
            MerchantRegion: newCustomer.MerchantRegion,
            MerchantCustomerValue: newCustomer.MerchantCustomerValue,
            MerchantSitePublishDate: newCustomer.MerchantSitePublishDate,
            MerchantContractEndDate: newCustomer.MerchantContractEndDate,
            MerchantServiceLevel: newCustomer.MerchantServiceLevel,
            MerchantCampaignManager: newCustomer.MerchantCampaignManager,
            MerchantCampaignManagerContact: newCustomer.MerchantCampaignManagerContact
        };

        const user = <Boo.Objects.User>{
            FirstName: newCustomer.FirstName,
            LastName: newCustomer.LastName,
            Username: newCustomer.Email
        };

        return this.customerService.create(user, customer, merchantFields);
    }

    private initValidation(): void {
        this.formGroup.get('Company').setValidators([Validators.required]);
        this.formGroup.get('FirstName').setValidators([Validators.required]);
        this.formGroup.get('LastName').setValidators([Validators.required]);
        this.formGroup.get('Email').setValidators([Validators.required, CustomValidators.email]);
        this.formGroup.get('Phone').setValidators([CustomValidators.phoneNumberValidator(() => this.countryAbbreviation()), Validators.required]);
        this.formGroup.get('LanguageId').setValidators([Validators.required]);
        this.formGroup.get('CountryId').setValidators([Validators.required]);
        this.formGroup.get('Url').setValidators([Validators.required, CustomValidators.validUrl()]);
    }

    private initNewCustomer(): void {
        const fb = new FormBuilder();
        this.formGroup = fb.group({
            Company: '',
            FirstName: '',
            LastName: '',
            Email: '',
            Phone: '',
            LanguageId: this.partner.DefaultLanguageId,
            CountryId: this.partner.CountryId,
            Url: '',
            MerchantId: '',
            ReferredBy: '',
            LeadSourceId: null,
            MerchantSource: '',
            MerchantProposalId: '',
            MerchantCategory: '',
            MerchantCategoryId: '',
            MerchantSubCategory: '',
            MerchantSubCategoryId: '',
            MerchantConsultant: '',
            MerchantConsultantContact: '',
            MerchantUdac: '',
            MerchantProductCode: '',
            MerchantItemId: '',
            MerchantMiscId: '',
            MerchantRegion: '',
            MerchantCustomerValue: '',
            MerchantSitePublishDate: undefined,
            MerchantContractEndDate: '',
            MerchantServiceLevel: '',
            MerchantCampaignManager: '',
            MerchantCampaignManagerContact: ''
        });
        this.formGroup.get('CountryId').valueChanges.subscribe((countryId) => this.OnCountrySelected(countryId));
    }

    private countryAbbreviation() {
        const country = this.countries?.find((x) => x.CountryId === this.formGroup.get('CountryId').value);
        return country?.Abbreviation ?? 'US';
    }
}

interface ICustomerCreate {
    Company: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Phone: string;
    LanguageId: number;
    CountryId: number;
    Url: string;
    MerchantId: string;
    ReferredBy: string;
    LeadSourceId: number;
    MerchantSource: string;
    MerchantProposalId: string;
    MerchantCategory: string;
    MerchantCategoryId: string;
    MerchantSubCategory: string;
    MerchantSubCategoryId: string;
    MerchantConsultant: string;
    MerchantUdac: string;
    MerchantProductCode: string;
    MerchantItemId: string;
    MerchantMiscId: string;
    MerchantSitePublishDate: Date;
    MerchantConsultantContact: string;
    MerchantRegion: string;
    MerchantCustomerValue: string;
    MerchantContractEndDate: string;
    MerchantServiceLevel: string;
    MerchantCampaignManager: string;
    MerchantCampaignManagerContact: string;
}
