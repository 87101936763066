import { Component, Input, OnInit } from '@angular/core';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';
import { HaloKeywordSuggestion, KeywordConfig, KeywordResearch, KeywordResearchTypes, KeywordSuggestion, PageSuggestion, PageSuggestionSources } from '../../../../shared/models/keywords/keywords';
import { KeywordResearchService } from '../../../../services/keyword-research.service';

@Component({
  selector: 'app-components-shared-keyword-research-page',
  templateUrl: './page.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class PageComponent implements OnInit {
  @Input() keywordResearchType: KeywordResearchTypes;
  @Input() customer: Boo.Objects.Customer;
  @Input() pendingKeywordResearch: KeywordResearch[];
  @Input() pageSuggestion: PageSuggestion;
  @Input() pageSuggestions: PageSuggestion[];
  @Input() haloKeywordSuggestions: HaloKeywordSuggestion[];
  @Input() keywordConfig: KeywordConfig;

  kwrSearchTerm: string = '';

  constructor(
    private customerCampaignService: CustomerCampaignService,
    private keywordResearchService: KeywordResearchService) { }

  ngOnInit(): void {
    this.kwrSearchTerm = this.pageSuggestion.KeywordResearch
      .sort((a, b) => new Date(b.InsertedDate).getTime() - new Date(a.InsertedDate).getTime())[0]?.KeywordPhrase.toLowerCase() ?? '';
  }

  keywordSort = (a: KeywordSuggestion, b: KeywordSuggestion): number => this.keywordResearchService.keywordSort(a, b);

  removePage() {
    if (this.pageSuggestion.SourceId === PageSuggestionSources.User && this.pageSuggestion.PageSuggestionId === 0) {
      this.pageSuggestions.splice(this.pageSuggestions.indexOf(this.pageSuggestion), 1);
    } else {
      this.pageSuggestion.IsSelected = false;
      this.pageSuggestion.IsModified = true;
      this.pageSuggestion.PendingKeywordResearch = null;
    }
  }

  get selectedKeywordSuggestions(): KeywordSuggestion[] {
    return this.pageSuggestion.KeywordSuggestions.filter(x => x.IsSelected);
  }

  get validationMessage(): string {
    if (this.pageSuggestion.Url && this.pageSuggestion.IsCampaignNational === this.customer.IsCampaignNational && !this.customerCampaignService.domainMatches(this.customer.Url, this.pageSuggestion.Url)) {
      return 'The page domain must match the customer domain.';
    }

    let requiredPages = this.keywordConfig?.Pages || 1;
    let requiredKeywordsPerPage = (this.keywordConfig?.MainKeywords || 1) / requiredPages;

    if (this.pageSuggestion.KeywordSuggestions.filter(y => y.IsSelected && y.IsCampaignNational === this.customer.IsCampaignNational).length < requiredKeywordsPerPage) {
      return 'Page must have at least ' + requiredKeywordsPerPage + ' keywords.';
    }

    return '';
  }
}
