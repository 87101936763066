import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import CustomValidators from '../../../../shared/custom-validators';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';
import { HaloKeywordSuggestion, KeywordSuggestionFilter, KeywordSuggestion, KeywordSuggestionSources, PageSuggestion } from '../../../../shared/models/keywords/keywords';
import { KeywordResearchService } from '../../../../services/keyword-research.service';

@Component({
  selector: 'app-components-shared-keyword-research-add-halo-keyword',
  templateUrl: './add-halo-keyword.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class AddHaloKeywordComponent implements OnInit {
  @Input() customer: Boo.Objects.Customer;
  @Input() pageSuggestions: PageSuggestion[];
  @Input() haloKeywordSuggestions: HaloKeywordSuggestion[];

  @ViewChild('keywordInput') keywordInput: ElementRef;
  @ViewChild('areaInput') areaInput: ElementRef;

  keywordSuggestionFilter: KeywordSuggestionFilter;
  keywordControl: FormControl<string>;
  areaControl: FormControl<string>;
  formGroup: FormGroup;
  addingKeyword: boolean = false;
  isAreaFirst: boolean = false;
  canAddKeywords: boolean = false;
  rankThreshold: number;

  constructor(
    private customerCampaignService: CustomerCampaignService,
    private keywordResearchService: KeywordResearchService) { }

  ngOnInit(): void {
    this.rankThreshold = this.keywordResearchService.rankThreshold;
    this.setupForm();

    // Keywords can only be added once research has been performed for at least one page suggestion
    this.canAddKeywords = this.pageSuggestions.filter(x => x.IsCampaignNational === this.customer.IsCampaignNational)
      .flatMap(x => x.KeywordSuggestions)
      .some(x => x.SourceId === KeywordSuggestionSources.KeywordTool);
  }

  toggleAddingKeyword(): void {
    this.addingKeyword = !this.addingKeyword;

    if (this.addingKeyword) {
      this.isAreaFirst = false;
      this.keywordControl.reset('');
      this.areaControl.reset('');
      this.focus();
    }
  }

  setupForm(): void {
    this.keywordControl = new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(128),
      CustomValidators.mustNotBeWhitespace()
    ]);
    this.areaControl = new FormControl<string>('', [
      CustomValidators.requiredIfValidator(() => !this.customer.IsCampaignNational),
      Validators.maxLength(256),
      CustomValidators.mustNotBeWhitespace()
    ]);
    this.formGroup = new FormGroup({ keyword: this.keywordControl, area: this.areaControl }, {
      validators: [CustomValidators.keywordsCannotBeDuplicated(this.customerCampaignService, () => this.isAreaFirst, this.getUnavailableKeywords.bind(this), 'This keyword already exists and cannot be duplicated.')]
    });
  }

  getUnavailableKeywords(): string[] {
    return this.haloKeywordSuggestions
      .filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational)
      .map(x => x.KeywordPhrase.toLowerCase().trim());
  }

  keywordSort = (a: KeywordSuggestion, b: KeywordSuggestion): number => this.keywordResearchService.keywordSort(a, b);

  addKeyword(): void {
    this.keywordControl.markAsDirty();
    this.keywordControl.updateValueAndValidity();

    if (!this.customer.IsCampaignNational) {
      this.areaControl.markAsDirty();
      this.areaControl.updateValueAndValidity();
    } else {
      this.areaControl.reset('');
      this.isAreaFirst = false;
    }

    if (!this.formGroup.valid) {
      toastr.error("Please correct errors before adding a keyword.");
      return;
    }

    let phrase = this.customerCampaignService.getKeywordPhrase(this.keywordControl.value, this.areaControl.value, this.isAreaFirst);

    let existingHaloKeywordSuggestion = this.haloKeywordSuggestions.filter(x => !x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational)
      .find(x => x.KeywordPhrase.toLowerCase().trim() === phrase.toLowerCase().trim());

    let existingKeywordSuggestion = this.pageSuggestions.filter(x => x.IsCampaignNational === this.customer.IsCampaignNational)
      .flatMap(x => x.KeywordSuggestions)
      .find(x => x.KeywordPhrase.toLowerCase().trim() === phrase.toLowerCase().trim() && x.IsCampaignNational === this.customer.IsCampaignNational && x.SourceId === KeywordSuggestionSources.KeywordTool);

    if (existingHaloKeywordSuggestion) {
      existingHaloKeywordSuggestion.IsSelected = true;
      existingHaloKeywordSuggestion.IsModified = true;
    } else if (existingKeywordSuggestion) {
      this.addKeywordSuggestion(existingKeywordSuggestion);
    } else {
      this.haloKeywordSuggestions.push({
        HaloKeywordSuggestionId: 0,
        CustomerId: this.customer.CustomerId,
        SourceId: KeywordSuggestionSources.User,
        IsCampaignNational: this.customer.IsCampaignNational,
        KeywordPhrase: phrase,
        Keyword: this.customerCampaignService.normalizeKeyword(this.keywordControl.value),
        Area: this.areaControl.value ? this.customerCampaignService.normalizeArea(this.areaControl.value) : null,
        IsAreaFirst: this.isAreaFirst,
        IsSelected: true,
        Volume: null,
        VolumeDescription: null,
        Competition: null,
        CompetitionDescription: null,
        Rank: null,
        InsertedDate: null,
        ArchivedDate: null,
        IsModified: true
      } as HaloKeywordSuggestion);
    }

    this.toggleAddingKeyword();
  }

  addKeywordSuggestion(keywordSuggestion: KeywordSuggestion): void {
    let existingHaloKeywordSuggestion = this.haloKeywordSuggestions.filter(x => !x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational)
      .find(x => x.KeywordPhrase.toLowerCase().trim() === keywordSuggestion.KeywordPhrase.toLowerCase().trim());

    if (existingHaloKeywordSuggestion) {
      existingHaloKeywordSuggestion.IsSelected = true;
      existingHaloKeywordSuggestion.IsModified = true;
      existingHaloKeywordSuggestion.Volume = keywordSuggestion.Volume;
      existingHaloKeywordSuggestion.VolumeDescription = keywordSuggestion.VolumeDescription;
      existingHaloKeywordSuggestion.Competition = keywordSuggestion.Competition;
      existingHaloKeywordSuggestion.CompetitionDescription = keywordSuggestion.CompetitionDescription;
      existingHaloKeywordSuggestion.Rank = keywordSuggestion.Rank;
    } else {
      this.haloKeywordSuggestions.push({
        HaloKeywordSuggestionId: 0,
        CustomerId: this.customer.CustomerId,
        SourceId: keywordSuggestion.SourceId,
        IsCampaignNational: this.customer.IsCampaignNational,
        KeywordPhrase: keywordSuggestion.KeywordPhrase,
        Keyword: keywordSuggestion.Keyword,
        Area: keywordSuggestion.Area,
        IsAreaFirst: keywordSuggestion.IsAreaFirst,
        IsSelected: true,
        Volume: keywordSuggestion.Volume,
        VolumeDescription: keywordSuggestion.VolumeDescription,
        Competition: keywordSuggestion.Competition,
        CompetitionDescription: keywordSuggestion.CompetitionDescription,
        Rank: keywordSuggestion.Rank,
        InsertedDate: null,
        ArchivedDate: null,
        IsModified: true
      } as HaloKeywordSuggestion);
    }
  }

  addHaloKeywordSuggestion(haloKeywordSuggestion: HaloKeywordSuggestion): void {
   haloKeywordSuggestion.IsSelected = true;
   haloKeywordSuggestion.IsModified = true;
  }

  get availableKeywordSuggestions() : KeywordSuggestion[] {
    return this.pageSuggestions
      .filter(x => x.IsCampaignNational === this.customer.IsCampaignNational)
      .flatMap(x => x.KeywordSuggestions)
      .filter(x => x.SourceId === KeywordSuggestionSources.KeywordTool
        && !this.haloKeywordSuggestions
          .filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational)
          .flatMap(x => x.KeywordPhrase).includes(x.KeywordPhrase))
      .reduce((acc, curr) => {
        if (!acc.set.has(curr.KeywordPhrase.toLowerCase().trim())) {
          acc.set.add(curr.KeywordPhrase.toLowerCase().trim());
          acc.list.push(curr);
        }
        return acc;
      }, { set: new Set<string>(), list: [] }).list;
  }

  get filteredKeywordSuggestions(): KeywordSuggestion[] {
    return this.keywordResearchService.filterKeywordSuggestions(this.availableKeywordSuggestions, this.keywordSuggestionFilter);
  }

  get filteredHaloKeywordSuggestions(): HaloKeywordSuggestion[] {
    return this.keywordResearchService.filterHaloKeywordSuggestions(this.haloKeywordSuggestions.filter(x => !x.IsSelected), this.keywordSuggestionFilter);
  }

  filterChanged($event: KeywordSuggestionFilter): void {
    this.keywordSuggestionFilter = $event;
  }

  private focus() {
    setTimeout(() => {
      this.keywordInput.nativeElement.focus();
    }, 0);
  }
}