<div [loadingOverlay]="isLoading">
  <form [formGroup]="localProfileForm">
    <div *ngIf="!requireFullValidation && !localProfileForm.controls.isComplete.value" class="alert alert-warning mt-2"
      role="alert">
      A Business Profile Validation and Augmentation task has not been completed for this Local Profile.
    </div>
    <div class="d-flex flex-row mb-3">
      <div class="p-2">
        <label class="control-label d-block" for="isActive">Active</label>
        <div>
          <shared-components-customchoice id="isActive" formControlName="isActive"
            [disable]="isReadOnly || disableBusinessCitationEligibilityControls"></shared-components-customchoice>
        </div>
      </div>
      <div class="p-2" [formValidation]="localProfileForm.controls.canSubscribe">
        <label class="control-label d-block" for="canSubscribe">
          Can Subscribe
          <ng-container *ngIf="!isReadOnly && !disableBusinessCitationEligibilityControls">
            <app-components-helptext [key]="'LocalProfile_CanSubscribe'"></app-components-helptext>
          </ng-container>
        </label>
        <div>
          <shared-components-customchoice id="canSubscribe" formControlName="canSubscribe"
            [disable]="isReadOnly || disableBusinessCitationEligibilityControls"></shared-components-customchoice>
        </div>
      </div>
      <div class="p-2 ms-5">
        <label class="control-label d-block" for="isClosed">Location Is Closed</label>
        <div>
          <shared-components-customchoice id="isClosed" formControlName="isClosed"
            [disable]="isReadOnly"></shared-components-customchoice>
        </div>
      </div>
      <ng-container *ngIf="partnerRequiresSso">
        <div class="p-2 ms-auto align-self-end">
          <div>
            <button class="btn btn-primary" (click)="editOnPartnerSite()" [disabled]="isLoading">
              <i class="fas fa-sign-in-alt"></i>{{ partnerSsoLabel }}</button>
          </div>
        </div>
      </ng-container>
    </div>
    <hr />
    <h4>General Info</h4>
    <div class="row mb-3">
      <div class="col-6">
        <div class="row g-3">
          <div class="col-12">
            <label class="form-label" for="companyName">Company Name</label>
            <input class="form-control" [formValidation]="localProfileForm.controls.companyName" type="text"
              formControlName="companyName" [disabled]="isReadOnly" [readonly]="isReadOnly" id="companyName"
              placeholder="Company Name" />
          </div>
          <div class="col-12">
            <label class="form-label" for="openingDate">Opening Date</label>
            <input [formValidation]="localProfileForm.controls.openingDate" type="date" formControlName="openingDate"
              [disabled]="isReadOnly" [readonly]="isReadOnly" class="form-control" id="openingDate"
              pattern="\d{4}-\d{2}-\d{2}" placeholder="Opening Date" />
          </div>
          <div class="col-12">
            <label class="form-label" for="countryId">Country<span><app-components-helptext
                  [key]="'LocalProfile_Country'"></app-components-helptext></span></label>
            <ng-select [formValidation]="localProfileForm.controls.countryId" id="countryId" [items]="countries"
              [clearable]="true" placeholder="Country" bindLabel="Name" bindValue="CountryId"
              formControlName="countryId"></ng-select>
          </div>
          <div class="col-12">
            <label class="form-label" for="streetAddress">Street Address</label>
            <input [formValidation]="localProfileForm.controls.streetAddress" type="text"
              formControlName="streetAddress" [disabled]="isReadOnly" [readonly]="isReadOnly" class="form-control"
              id="streetAddress" placeholder="Street Address" />
          </div>
          <div class="col-12">
            <label class="form-label" for="streetAddress2">Street Address 2</label>
            <input [formValidation]="localProfileForm.controls.streetAddress2" type="text"
              formControlName="streetAddress2" [disabled]="isReadOnly" [readonly]="isReadOnly" class="form-control"
              id="streetAddress2" placeholder="Street Address 2" />
          </div>
          <div class="col-12">
            <label class="form-label" for="city">City</label>
            <input [formValidation]="localProfileForm.controls.city" [disabled]="isReadOnly" [readonly]="isReadOnly"
              type="text" formControlName="city" class="form-control" id="city" placeholder="City/Suburb" />
          </div>
          <div class="col-8">
            <label class="form-label" for="stateId">State</label>
            <ng-select [formValidation]="localProfileForm.controls.stateId" id="stateId" [items]="states"
              [clearable]="true" bindLabel="displayName" bindValue="StateId" formControlName="stateId"
              placeholder="State"></ng-select>
          </div>
          <div class="col-4">
            <label class="form-label" for="zip">Postal Code</label>
            <input [formValidation]="localProfileForm.controls.zip" type="text" formControlName="zip"
              class="form-control" id="zip" placeholder="Postal Code" [disabled]="isReadOnly" [readonly]="isReadOnly" />
          </div>
          <div class="col-12">
            <button (click)="checkAddress()" [disabled]="isReadOnly" type="button" class="btn btn-success">
              <i class="fa fa-check"></i>
              Check Address
            </button>
          </div>
          <div class="col-12" [formValidation]="localProfileForm.controls.verifyAddressMatchesWithGoogle">
            <label class="control-label">Did you verify with Google that the Customer's address matches the City/Zip
              provided?</label>
            <div style="width:141px;">
              <shared-components-customchoice id="verifyAddressMatchesWithGoogle"
                formControlName="verifyAddressMatchesWithGoogle"
                [disable]="isReadOnly"></shared-components-customchoice>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row g-3">
          <div class="col-12">
            <label class="form-label d-block" for="isStreetAddressHidden">Hide Street Address</label>
            <shared-components-customchoice id="isStreetAddressHidden" formControlName="isStreetAddressHidden"
              [disable]="isReadOnly"></shared-components-customchoice>
          </div>
          <div class="col-12">
            <label class="form-label" for="serviceArea">Service Areas</label>
            <ng-select [formValidation]="localProfileForm.controls.serviceAreas" id="serviceArea"
              [items]="serviceAreas$ | async" [clearable]="true" bindLabel="Description" formControlName="serviceAreas"
              [loading]="serviceAreaLoading" [minTermLength]="3" [typeahead]="serviceAreaInput$" [multiple]="true"
              placeholder="Service Areas" maxSelectedItems="20">
            </ng-select>
          </div>
          <div class="col-12">
            <label class="form-label" for="categories">Categories</label>
            <ng-select [formValidation]="localProfileForm.controls.categories" id="categories" [items]="categories"
              [clearable]="true" [multiple]="true" [closeOnSelect]="false" bindLabel="FullName"
              bindValue="BusinessCitationProviderCategoryId" [virtualScroll]="true" formControlName="categories"
              placeholder="Categories"></ng-select>
          </div>
          <ng-container
            *ngIf="localProfileForm.controls.categories.value.length === 0 && localProfileForm.controls.previousCategory1.value">
            <div class="col-12">
              <label class="control-label">Previous Categories</label>
              <ul class="list-group">
                <ng-container *ngIf="localProfileForm.controls.previousCategory1.value">
                  <li class="list-group-item">{{localProfileForm.controls.previousCategory1.value}}</li>
                </ng-container>
                <ng-container *ngIf="localProfileForm.controls.previousCategory2.value">
                  <li class="list-group-item">{{localProfileForm.controls.previousCategory2.value}}</li>
                </ng-container>
                <ng-container *ngIf="localProfileForm.controls.previousCategory3.value">
                  <li class="list-group-item">{{localProfileForm.controls.previousCategory3.value}}</li>
                </ng-container>
                <ng-container *ngIf="localProfileForm.controls.previousCategory4.value">
                  <li class="list-group-item">{{localProfileForm.controls.previousCategory4.value}}</li>
                </ng-container>
                <ng-container *ngIf="localProfileForm.controls.previousCategory5.value">
                  <li class="list-group-item">{{localProfileForm.controls.previousCategory5.value}}</li>
                </ng-container>
              </ul>
            </div>
          </ng-container>


        </div>
      </div>
    </div>
    <hr />
    <h4>Contact Info</h4>
    <div class="row mb-3">
      <div class="col-6">
        <div class="row g-3">
          <div class="col-12">
            <label class="form-label" for="phone">Company Phone</label>
            <input [formValidation]="localProfileForm.controls.phone" type="text" formControlName="phone"
              [disabled]="isReadOnly" [readonly]="isReadOnly" class="form-control" id="phone"
              placeholder="Company Phone" />
          </div>
          <div class="col-12">
            <label class="form-label" for="alternatePhone">Alternate Phone</label>
            <input [formValidation]="localProfileForm.controls.alternatePhone" type="text" [disabled]="isReadOnly"
              [readonly]="isReadOnly" formControlName="alternatePhone" class="form-control" id="alternatePhone"
              placeholder="Alternate Phone" />
          </div>
          <div class="col-12">
            <label class="form-label" for="fax">Fax</label>
            <input [formValidation]="localProfileForm.controls.fax" type="text" formControlName="fax"
              class="form-control" [disabled]="isReadOnly" [readonly]="isReadOnly" id="fax" placeholder="Fax" />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row g-3">
          <div class="col-12">
            <label class="form-label" for="contactName">Contact Name</label>
            <input [formValidation]="localProfileForm.controls.contactName" type="text" formControlName="contactName"
              [disabled]="isReadOnly" [readonly]="isReadOnly" class="form-control" id="contactName"
              placeholder="Contact Name" />
          </div>
          <div class="col-12">
            <label class="form-label" for="website">Website</label>
            <input [formValidation]="localProfileForm.controls.website" type="text" formControlName="website"
              [disabled]="isReadOnly" [readonly]="isReadOnly" class="form-control" id="website" placeholder="Website" />
          </div>
          <div class="col-12">
            <label class="form-label" for="email">Email</label>
            <input [formValidation]="localProfileForm.controls.email" type="text" formControlName="email"
              [disabled]="isReadOnly" [readonly]="isReadOnly" class="form-control" id="email" placeholder="Email" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="control-label d-block" for="showOperatingHours">Business Hours</label>
          <div>
            <shared-components-customchoice id="showOperatingHours" formControlName="showOperatingHours"
              choiceTrue="Post business hours" choiceFalse="Do not post business hours"
              [disable]="isReadOnly"></shared-components-customchoice>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="localProfileForm.controls.showOperatingHours.value" style="margin-top: 5px;">
      <div class="col-6">
        <div class="card card-body bg-light">
          <div class="row">
            <label class="control-label">Standard Hours</label>
            <div class="col-12">
              <shared-components-customtoggle formControlName="isOpen247" label="Open 24/7"
                [disabled]="localProfileForm.controls.isByAppointmentOnly.value || isReadOnly"></shared-components-customtoggle>
            </div>
            <div class="col-12">
              <shared-components-customtoggle formControlName="isByAppointmentOnly" label="By Appointment Only"
                [disabled]="localProfileForm.controls.isOpen247.value || isReadOnly"></shared-components-customtoggle>
            </div>
          </div>
          <div
            *ngIf="!(localProfileForm.controls.isOpen247.value) && !(localProfileForm.controls.isByAppointmentOnly.value)"
            [formValidation]="localProfileForm.controls.businessHours">
            <div class="card" formArrayName="businessHours"
              *ngFor="let businessHours of businessHoursForms.controls; let i = index;">
              <div class="card-body" [formGroupName]="i">
                <ng-container
                  *ngIf="{ showSplit: businessHours.controls.from2.value || businessHours.controls.to2.value } as variable">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{ businessHours.controls.dayOfWeek.value | dayName }}</h5>
                    <div>
                      <ng-container *ngIf="isReadOnly">
                        <span class="ms-3">
                          <input disabled readonly formControlName="isOpen24Hours" type="checkbox" /> 24 hours
                        </span>
                        <span class="ms-3">
                          <input disabled readonly formControlName="isClosed" type="checkbox" /> Closed
                        </span>
                      </ng-container>
                      <ng-container *ngIf="!isReadOnly">
                        <span class="ms-3">
                          <input formControlName="isOpen24Hours" (change)="isOpen24HoursChanged($event, businessHours)"
                            type="checkbox" /> 24 hours
                        </span>
                        <span class="ms-3">
                          <input formControlName="isClosed" (change)="isClosedChanged($event, businessHours)"
                            type="checkbox" /> Closed
                        </span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="row form-group mt-2 align-items-end"
                    *ngIf="!businessHours.controls.isOpen24Hours.value && !businessHours.controls.isClosed.value">
                    <div class="col-9">
                      <div class="d-flex w-100 justify-content-center">
                        <span>
                          <input [disabled]="isReadOnly" class="form-control" type="time" width="300px" step="300"
                            name="from1" id="from1" pattern="[0-9]{2}:[0-9]{2}" formControlName="from1" />
                        </span>
                        <span class="ms-3 me-3">to</span>
                        <span>
                          <input [disabled]="isReadOnly" class="form-control" type="time" step="300" name="to1" id="to1"
                            pattern="[0-9]{2}:[0-9]{2}" formControlName="to1" />
                        </span>
                      </div>
                      <ng-container *ngIf="variable.showSplit">
                        <div class="d-flex w-100 mt-2 justify-content-center">
                          <span>
                            <input [disabled]="isReadOnly" class="form-control" type="time" step="300" name="from2"
                              id="from2" pattern="[0-9]{2}:[0-9]{2}" formControlName="from2" />
                          </span>
                          <span class="ms-3 me-3">to</span>
                          <span>
                            <input [disabled]="isReadOnly" class="form-control" type="time" step="300" name="to2"
                              id="to2" pattern="[0-9]{2}:[0-9]{2}" formControlName="to2" />
                          </span>
                        </div>
                      </ng-container>
                    </div>
                    <div class="col-3">
                      <div class="d-flex w-100 mt-2 justify-content-center">
                        <ng-container *ngIf="!variable.showSplit">
                          <button [disabled]="isReadOnly" type="button" class="btn btn-sm btn-success"
                            (click)="variable.showSplit = true"><i class="fas fa-plus"></i>Split</button>
                        </ng-container>
                        <ng-container *ngIf="variable.showSplit">
                          <button [disabled]="isReadOnly" type="button" class="btn btn-sm btn-warning"
                            (click)="clearSecondInterval(businessHours); variable.showSplit = false;"><i
                              class="fas fa-trash"></i>Split</button>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <br />
          <div class="form-group">
            <label for="additionalHoursInformation">Additional Hours Information</label>
            <textarea [formValidation]="localProfileForm.controls.additionalHoursInformation"
              formControlName="additionalHoursInformation" [disabled]="isReadOnly" [readonly]="isReadOnly"
              class="form-control" id="additionalHoursInformation"
              placeholder="Additional Hours Information"></textarea>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-body bg-light" style="overflow:auto; max-height: 1340px;">
          <div class="d-flex w-100 justify-content-between">
            <label class="control-label" for="products">Special Hours</label>
            <button [disabled]="isReadOnly" type="button" class="btn btn-sm btn-success"
              (click)="addSpecialHours()">Add</button>
          </div>
          <ng-container *ngIf="specialHoursForms.controls.length">
            <div style="margin-top: 5px;" [formValidation]="localProfileForm.controls.specialHours">
              <div class="card" formArrayName="specialHours"
                *ngFor="let specialHours of specialHoursForms.controls; let i = index;">
                <div class="card-body" [formGroupName]="i">
                  <ng-container
                    *ngIf="{ showSplit: specialHours.controls.from2.value || specialHours.controls.to2.value } as variable">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">
                        <input class="form-control" formControlName="date" width="300px" type="date" name="date"
                          id="date" pattern="\d{4}-\d{2}-\d{2}" />
                      </h5>
                      <div>
                        <ng-container *ngIf="isReadOnly">
                          <span class="ms-3">
                            <input disabled readonly formControlName="isOpen24Hours" type="checkbox" /> 24 hours
                          </span>
                          <span class="ms-3">
                            <input disabled readonly formControlName="isClosed" type="checkbox" /> Closed
                          </span>
                        </ng-container>
                        <ng-container *ngIf="!isReadOnly">
                          <span class="ms-3">
                            <input formControlName="isOpen24Hours" (change)="isOpen24HoursChanged($event, specialHours)"
                              type="checkbox" /> 24 hours
                          </span>
                          <span class="ms-3">
                            <input formControlName="isClosed" (change)="isClosedChanged($event, specialHours)"
                              type="checkbox" /> Closed
                          </span>
                        </ng-container>
                      </div>
                      <i class="fas fa-trash" style="cursor: pointer;" (click)="removeSpecialHours(specialHours)"></i>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <input [disabled]="isReadOnly" [readonly]="isReadOnly" type="text"
                            formControlName="description" class="form-control" id="description"
                            placeholder="Description" />
                        </div>
                      </div>
                    </div>
                    <div class="row form-group mt-2 align-items-end"
                      *ngIf="!specialHours.controls.isOpen24Hours.value && !specialHours.controls.isClosed.value">
                      <div class="col-9">
                        <div class="d-flex w-100 justify-content-center">
                          <span>
                            <input [disabled]="isReadOnly" class="form-control" type="time" width="300px" step="300"
                              name="from1" id="from1" pattern="[0-9]{2}:[0-9]{2}" formControlName="from1" />
                          </span>
                          <span class="ms-3 me-3">to</span>
                          <span>
                            <input [disabled]="isReadOnly" class="form-control" type="time" step="300" name="to1"
                              id="to1" pattern="[0-9]{2}:[0-9]{2}" formControlName="to1" />
                          </span>
                        </div>
                        <ng-container *ngIf="variable.showSplit">
                          <div class="d-flex w-100 mt-2 justify-content-center">
                            <span>
                              <input [disabled]="isReadOnly" class="form-control" type="time" step="300" name="from2"
                                id="from2" pattern="[0-9]{2}:[0-9]{2}" formControlName="from2" />
                            </span>
                            <span class="ms-3 me-3">to</span>
                            <span>
                              <input [disabled]="isReadOnly" class="form-control" type="time" step="300" name="to2"
                                id="to2" pattern="[0-9]{2}:[0-9]{2}" formControlName="to2" />
                            </span>
                          </div>
                        </ng-container>
                      </div>
                      <div class="col-3">
                        <div class="d-flex w-100 mt-2 justify-content-center">
                          <ng-container *ngIf="!variable.showSplit">
                            <button [disabled]="isReadOnly" type="button" class="btn btn-sm btn-success"
                              (click)="variable.showSplit = true"><i class="fas fa-plus"></i>Split</button>
                          </ng-container>
                          <ng-container *ngIf="variable.showSplit">
                            <button [disabled]="isReadOnly" type="button" class="btn btn-sm btn-warning"
                              (click)="clearSecondInterval(specialHours); variable.showSplit = false;"><i
                                class="fas fa-trash"></i>Split</button>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <hr />
    <h4>Business Info and Description</h4>
    <div class="row g-3 mb-3">
      <div class="col-6">
        <label for="featuredMessage">Featured Message</label>
        <input [formValidation]="localProfileForm.controls.featuredMessage" type="text" [disabled]="isReadOnly"
          [readonly]="isReadOnly" formControlName="featuredMessage" class="form-control" id="featuredMessage"
          placeholder="Featured Message" />
      </div>
      <div class="col-6">
        <label for="description">Description</label>
        <textarea [formValidation]="localProfileForm.controls.description" formControlName="description"
          [disabled]="isReadOnly" [readonly]="isReadOnly" class="form-control" id="description"
          placeholder="Description"></textarea>
      </div>
    </div>
    <hr />
    <h4>Social Media Profiles</h4>
    <div class="row mb-3">
      <div formGroupName="socialProfiles" class="row g-3">
        <div *ngFor="let socialProfileForm of socialProfileForms.controls; let i = index;" [formGroupName]="i" class="col-6">
          <label class="form-label">{{ socialProfileForm.controls.name.value }}</label>
          <div class="input-group">
            @if (socialProfileForm.controls.accountPrefix.value)
            {
            <span class="input-group-text">{{ socialProfileForm.controls.accountPrefix.value }}</span>
            } @else if (socialProfileForm.controls.prependBaseUrl.value) {
            <span class="input-group-text">{{ socialProfileForm.controls.baseUrl.value }}</span>
            }
            <input [disabled]="isReadOnly" [formValidation]="socialProfileForm.controls.url" [readonly]="isReadOnly" type="text"
              formControlName="url" class="form-control" [placeholder]="socialProfileForm.controls.name.value" />
          </div>
        </div>
      </div>
    </div>
    <hr />
    <h4>Rich Data</h4>
    <div class="row mb-3">
      <div class="col-6">
        <div class="row g-3">
          <div class="col-12">
            <label class="form-label d-block" for="paymentOptions">Payment Options</label>
            <ng-select id="paymentOptions" [items]="paymentOptions" [multiple]="true" [clearable]="true"
              bindLabel="Name" bindValue="PaymentOptionId" [closeOnSelect]="false" formControlName="paymentOptions"
              placeholder="Payment Options"></ng-select>
          </div>
          <div class="col-12">
            <label class="form-label" for="products">Products</label>
            <input [formValidation]="localProfileForm.controls.products" [disabled]="isReadOnly" [readonly]="isReadOnly"
              type="text" formControlName="products" class="form-control" id="products" placeholder="Products" />
          </div>
          <div class="col-12">
            <label class="form-label" for="brands">Brands</label>
            <ng-select id="brands" class="hide-arrow" [addTag]="true" [items]="localProfileForm.controls.brands.value"
              [isOpen]="false" [multiple]="true" [clearable]="true" [clearSearchOnAdd]="true" [closeOnSelect]="false"
              formControlName="brands" placeholder="Brands"></ng-select>
          </div>
          <div class="col-12">
            <label class="form-label d-block" for="languages">Languages</label>
            <ng-select id="languages" [items]="languages" [multiple]="true" [clearable]="true"
              bindLabel="Name" bindValue="Name" [closeOnSelect]="false" formControlName="languages"
              placeholder="Languages"></ng-select>
          </div>
          <div class="col-12">
            <label class="form-label" for="services">Services</label>
            <input [formValidation]="localProfileForm.controls.services" [disabled]="isReadOnly" [readonly]="isReadOnly"
              type="text" formControlName="services" class="form-control" id="services" placeholder="Services" />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row g-3">
          <div class="col-12">
            <label class="form-label" for="associations">Associations</label>
            <input [formValidation]="localProfileForm.controls.associations" [disabled]="isReadOnly"
              [readonly]="isReadOnly" type="text" formControlName="associations" class="form-control" id="associations"
              placeholder="Associations" />
          </div>
          <div class="col-12">
            <label class="form-label" for="specialties">Specialties</label>
            <input [formValidation]="localProfileForm.controls.specialties" [disabled]="isReadOnly"
              [readonly]="isReadOnly" type="text" formControlName="specialties" class="form-control" id="specialties"
              placeholder="Specialties" />
          </div>
          <div class="col-12">
            <label class="form-label" for="notes">Additional Information: qualifications, certifications, awards, and so
              on.</label>
            <textarea [formValidation]="localProfileForm.controls.notes" formControlName="notes" [disabled]="isReadOnly"
              [readonly]="isReadOnly" class="form-control" id="notes" rows="6"
              placeholder="Additional Information"></textarea>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <h4>Photos & Videos</h4>
    <div class="row mb-3">
      <div class="col-6">
        <div class="row g-3">
          <div class="col-12">
            <label class="form-label" for="logoUrl">Logo URL</label>
            <input [formValidation]="localProfileForm.controls.logoUrl" type="text" [disabled]="isReadOnly"
              [readonly]="isReadOnly" formControlName="logoUrl" class="form-control" id="logoUrl"
              placeholder="Logo URL" />
          </div>
          <div class="col-12">
            <label class="form-label" for="imageUrls">Image URLs</label>
            <textarea [formValidation]="localProfileForm.controls.imageUrls" [disabled]="isReadOnly"
              [readonly]="isReadOnly" formControlName="imageUrls" [disabled]="isReadOnly" [readonly]="isReadOnly"
              class="form-control" id="imageUrls" rows="6" placeholder="Image URLs"></textarea>
          </div>
          <div class="col-12">
            <label class="form-label" for="videoUrls">Video URLs</label>
            <textarea [formValidation]="localProfileForm.controls.videoUrls" [disabled]="isReadOnly"
              [readonly]="isReadOnly" formControlName="videoUrls" [disabled]="isReadOnly" [readonly]="isReadOnly"
              class="form-control" id="videoUrls" rows="6" placeholder="Video URLs"></textarea>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row g-3">
          <div class="col-12">
            <label class="form-label" for="googleCoverPhoto">Google Cover Photo</label>
            <input [formValidation]="localProfileForm.controls.googleCoverPhoto" type="text"
              formControlName="googleCoverPhoto" [disabled]="isReadOnly" [readonly]="isReadOnly" class="form-control"
              id="googleCoverPhoto" placeholder="Google Cover Photo" />
          </div>
          <div class="col-12">
            <label class="form-label" for="googleProfilePhoto">Google Profile Photo</label>
            <input [formValidation]="localProfileForm.controls.googleProfilePhoto" type="text"
              formControlName="googleProfilePhoto" [disabled]="isReadOnly" [readonly]="isReadOnly" class="form-control"
              id="googleProfilePhoto" placeholder="Google Profile Photo" />
          </div>
          <div class="col-12">
            <label class="form-label" for="googlePreferredPhoto">Google Preferred Photo</label>
            <input [formValidation]="localProfileForm.controls.googleProfilePhoto" type="text"
              formControlName="googlePreferredPhoto" [disabled]="isReadOnly" [readonly]="isReadOnly"
              class="form-control" id="googlePreferredPhoto" placeholder="Google Preferred Photo" />
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="showRegistration">
      <hr />
      <h4>Registration</h4>
      <div class="row mb-3">
        <div class="col-6">
          <div class="row g-3">
            <div class="col-6">
              <label class="form-label" for="googleListingClaimedByStatusId">Google Listing Claimed By?</label>
              <ng-select [formValidation]="localProfileForm.controls.googleListingClaimedByStatusId"
                id="googleListingClaimedByStatusId" [items]="listingClaimedByStatuses" [clearable]="false"
                bindLabel="Name" bindValue="ListingClaimedByStatusId"
                formControlName="googleListingClaimedByStatusId"></ng-select>
            </div>
            <ng-container *ngIf="true || !(localProfileForm.controls.googleListingClaimedByStatusId.value > 1)">
              <div class="col-6">
                <label class="form-label" for="googleRegistrationPhoneNumber">Google Registration Phone</label>
                <input [formValidation]="localProfileForm.controls.googleRegistrationPhoneNumber" type="text"
                  formControlName="googleRegistrationPhoneNumber" [disabled]="true" [readonly]="true"
                  class="form-control" id="googleRegistrationPhoneNumber" placeholder="Google Registration Phone" />
              </div>
            </ng-container>
            <div class="col-12">
              <label class="form-label" for="googleProfileUrl">Google Profile URL</label>
              <input [formValidation]="localProfileForm.controls.googleProfileUrl" type="text"
                formControlName="googleProfileUrl" [disabled]="isReadOnly" [readonly]="isReadOnly" class="form-control"
                id="googleProfileUrl" placeholder="Google Profile URL" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row g-3">
            <div class="col-12">
              <label class="form-label" for="registrationEmail">Registration Email</label>
              <input [formValidation]="localProfileForm.controls.registrationEmail" type="text"
                formControlName="registrationEmail" [disabled]="true" [readonly]="true" class="form-control"
                id="registrationEmail" placeholder="Registration Email" />
            </div>
            <div class="col-12">
              <label class="form-label" for="registrationPassword">Registration Password</label>
              <input [formValidation]="localProfileForm.controls.registrationPassword" type="text"
                formControlName="registrationPassword" [disabled]="true" [readonly]="true" class="form-control"
                id="registrationPassword" placeholder="Registration Password" />
            </div>
            <ng-container *ngIf="registrationPhoneNumber">
              <div class="col-12">
                <label class="form-label" for="registrationPhone">Registration Phone Number</label>
                <div class="input-group">
                  <input type="text" [disabled]="true" [readonly]="true" [value]="registrationPhoneNumber.PhoneNumber"
                    [disabled]="true" [readonly]="true" class="form-control" id="registrationPhone"
                    placeholder="Registration Phone Number" />
                  <button (click)="requestNewPhoneNumber()" class="btn btn-danger">
                    <i class="fa fa-phone"></i>Request New
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</div>
