import { Component, Input } from '@angular/core';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-shared-page-status',
  templateUrl: './page-status.component.html'
})
export class PageStatusComponent  {
  @Input() websiteUrl: Boo.Objects.WebsiteUrl;
  @Input() websiteUrls: Boo.Objects.WebsiteUrl[];
  @Input() haloKeywords: Boo.Objects.WebsiteKeywordTracking[];
  @Input() archivedWebsiteUrls: Boo.Objects.ArchivedWebsiteUrl[];
  @Input() archivedKeywords: Boo.Objects.ArchivedKeyword[];
  @Input() firstPageKeywordPhrases: string[];
  @Input() restrictions: Boo.Objects.CustomerCampaign.Enums.RestrictionTypes[];

  customerCampaignRestrictionTypes_ReadOnly = Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.ReadOnly;
  websiteUrlStatuses_New = Boo.Objects.Enums.WebsiteUrlStatuses.New;
  websiteUrlStatuses_Limited = Boo.Objects.Enums.WebsiteUrlStatuses.Limited;
  websiteUrlStatuses_Active = Boo.Objects.Enums.WebsiteUrlStatuses.Active;
  websiteUrlStatuses_Archived = Boo.Objects.Enums.WebsiteUrlStatuses.Archived;

  constructor(private customerCampaignService: CustomerCampaignService) { }

  isActiveStatus(websiteUrl: Boo.Objects.WebsiteUrl): boolean {
    return this.customerCampaignService.isActiveStatus(websiteUrl);
  }

  updateWebsiteUrlStatus(websiteUrl: Boo.Objects.WebsiteUrl, status: Boo.Objects.Enums.WebsiteUrlStatuses): void {
    this.customerCampaignService.updateWebsiteUrlStatus(this.websiteUrls, this.haloKeywords, this.archivedWebsiteUrls, this.archivedKeywords, this.firstPageKeywordPhrases, websiteUrl, status);
  }

  archiveKeyword(websiteUrlKeyword: Boo.Objects.WebsiteUrlKeyword, websiteUrl: Boo.Objects.WebsiteUrl): void {
    this.customerCampaignService.archiveKeyword(this.archivedKeywords, websiteUrlKeyword, websiteUrl);
  }
}