

@if(selectedHaloKeywordSuggestions.length === 0) {
  <div class="row">
    <div class="col-md-12">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Halo Keywords</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>No halo keywords have been selected yet</td>
          </tr>
          <tr>
            <td>
              <div class="row">
                <div class="col-auto">
                  <app-components-shared-keyword-research-add-halo-keyword
                    [customer]="customer"
                    [pageSuggestions]="pageSuggestions"
                    [haloKeywordSuggestions]="haloKeywordSuggestions">
                  </app-components-shared-keyword-research-add-halo-keyword>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
}
@else {
  <div class="row">
    <div class="col-md-12">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>
              Halo Keywords
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let haloKeywordSuggestion of selectedHaloKeywordSuggestions | orderBy: keywordSort">
            <tr>
              <td>
                <app-components-shared-keyword-research-halo-keyword
                  [customer]="customer"
                  [pageSuggestions]="pageSuggestions"
                  [haloKeywordSuggestion]="haloKeywordSuggestion"
                  [haloKeywordSuggestions]="haloKeywordSuggestions">
                </app-components-shared-keyword-research-halo-keyword>
              </td>
            </tr>
          </ng-container>
          <tr>
            <td>
              <div class="row">
                <div class="col-auto">
                  <app-components-shared-keyword-research-add-halo-keyword
                    [customer]="customer"
                    [pageSuggestions]="pageSuggestions"
                    [haloKeywordSuggestions]="haloKeywordSuggestions">
                  </app-components-shared-keyword-research-add-halo-keyword>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
}