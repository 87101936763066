import { Component, ViewChild } from '@angular/core';
import System from 'framework/System';
import BaseComponent from '../BaseComponent';
import IValidatedResult = app.interfaces.IValidatedResult;
import { SaveTypes } from 'app/models/enums/SaveTypes';
import Utils from '../../../../app/shared/utils';
import { CustomerNoteService } from '../../../services/customer-note.service';
import { KeywordResearchComponent as SharedKeywordResearchComponent } from '../../shared/keyword-research/keyword-research/keyword-research.component';
import { KeywordResearchTypes } from '../../../shared/models/keywords/keywords';

@Component({
  selector: 'app-components-ticket-keywordresearch',
  templateUrl: './KeywordResearch.component.html'
})
export class KeywordResearchComponent extends BaseComponent {

  @ViewChild(SharedKeywordResearchComponent) keywordResearchComponent: SharedKeywordResearchComponent;

  keywordResearchType = KeywordResearchTypes.SeoOnboarding;
  isOpen = false;
  salesNote: string;
  isEnabled: boolean;
  triggerTicketCancel: () => void;
  customerJS: Boo.Objects.Customer;

  constructor(
    private customerNoteService: CustomerNoteService) {
    super();
  }

  canActivate?(_user: Boo.Objects.User, _partner: Boo.Objects.Partner, params: app.ticket.components.interfaces.ICustomerCampaignComponentActivateParams): boolean {
    this.customerJS = ko.mapping.toJS(params.customer);
    return true;
  }

  public activate(params: any): JQueryPromise<void> {
    this.triggerTicketCancel = params.triggerTicketCancel;

    return super.activate(params).then(() => 
      Utils.wrapDfd(this.customerNoteService.getSalesNotes(params.customer.CustomerId())))
      .then((salesNotes: Boo.Objects.CustomerNote[]) => {
        if (salesNotes && salesNotes.length > 0) {
          this.salesNote = _.chain(salesNotes)
            .filter((note: Boo.Objects.CustomerNote) => {
              return note.Text !== '';
            })
            .pluck('Text')
            .value()
            .reverse()
            .join('\n--------\n');
        }
      });
  }

  save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void> {
    return super.save(saveType)
      .then(() => {
        Utils.wrapDfd(this.keywordResearchComponent.save());
      })
      .then(() => {
        return System.emptyPromise();
      })
  }

  cancelTicket(): void {
    this.triggerTicketCancel();
  }

  validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
    return super.validate(saveType)
      .then((validationResult) => {
        if (saveType === SaveTypes.Complete && !this.keywordResearchComponent.isValid()) {
          this.isOpen = true;
          return System.resolvedPromise<IValidatedResult>({ isValid: false, errorMessages: ['Keyword research is invalid'] });
        }
        return validationResult;
      });
  }
}