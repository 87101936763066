import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import WorkflowStates = Boo.Objects.Enums.WorkflowStates;
import { StrategyUpdateRequest } from '../shared/models/strategy-update/strategy-update';

@Injectable({
  providedIn: 'root'
})
export class CustomerCampaignService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/customercampaign`;

  constructor(private http: HttpClient) { }

  getSummary(customerId: number): Observable<Boo.Objects.CustomerCampaign.CustomerCampaignSummary> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.Objects.CustomerCampaign.CustomerCampaignSummary>(`${this.baseUrl}/summary`, { params: params })
  }

  getTaskInfo(customerId: number, taskId: number): Observable<Boo.Objects.WebsiteUrlTask> {
    const params = new HttpParams()
      .set('customerId', customerId)
      .set('taskId', taskId);

    return this.http.get<Boo.Objects.WebsiteUrlTask>(`${this.baseUrl}/taskInfo`, { params: params });
  }

  save(customerId: number, websiteUrls: Boo.Objects.WebsiteUrl[], haloKeywords: Boo.Objects.WebsiteKeywordTracking[], isCampaignNational?: boolean): Observable<any> {
    return this.http.post(`${this.baseUrl}/save`, {
      customerId: customerId,
      websiteUrls: websiteUrls,
      haloKeywords: haloKeywords,
      isCampaignNational: isCampaignNational
    });
  }

  getActiveHaloKeywords(customerId: number): Observable<Boo.Objects.WebsiteKeywordTracking[]> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.Objects.WebsiteKeywordTracking[]>(`${this.baseUrl}/activeHaloKeywords`, { params: params });
  }

  getWebsiteUrl(websiteUrlId: number): Observable<Boo.Objects.WebsiteUrl> {
    const params = new HttpParams().set('websiteUrlId', websiteUrlId);

    return this.http.get<Boo.Objects.WebsiteUrl>(`${this.baseUrl}/websiteUrl`, { params: params });
  }

  getWebsiteUrls(customerId: number, excludeInactiveKeywords?: boolean, websiteUrlStatuses?: Boo.Objects.Enums.WebsiteUrlStatuses[]): Observable<Boo.Objects.WebsiteUrl[]> {
    return this.http.post<Boo.Objects.WebsiteUrl[]>(`${this.baseUrl}/websiteUrls`, {
      customerId: customerId,
      excludeInactiveKeywords: !!excludeInactiveKeywords,
      websiteUrlStatuses: websiteUrlStatuses
    });
  }

  getKeywordHistory(websiteUrlId: number, since: Date): Observable<Boo.Objects.WebsiteUrlKeywordHistory[]> {
    const params = new HttpParams()
      .set('websiteUrlId', websiteUrlId)
      .set('since', since.toISOString());

    return this.http.get<Boo.Objects.WebsiteUrlKeywordHistory[]>(`${this.baseUrl}/keywordHistory`, { params: params });
  }

  requestStrategyUpdate(strategyUpdateRequest: StrategyUpdateRequest) : Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/requestStrategyUpdate`, strategyUpdateRequest);
  }

  getTaskTypeLabelMap(): { [key: number]: string } {
    return {
      [Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendation]: 'Onsite Recommendation',
      [Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendationCreateContent]: 'Onsite Recommendation',
      [Boo.Objects.Enums.TaskTypeEnum.OnsiteCopyCreateContentStandard]: 'Onsite Copy',
      [Boo.Objects.Enums.TaskTypeEnum.OnsiteCopyCreateContentStandardRetired]: 'Onsite Copy',
    }
  }

  calculateReadOnlyState(websiteUrl: Boo.Objects.WebsiteUrl): void {
    websiteUrl.IsReadOnly = false;
    websiteUrl.ReadOnlyReason = '';

    if (websiteUrl.WebsiteUrlTask) {
      let terminalStates: number[] = [WorkflowStates.Completed, WorkflowStates.Canceled, WorkflowStates.Deleted, WorkflowStates.ReconcileCompleted];
      let uiBlockingTaskTypes: Boo.Objects.Enums.TaskTypeEnum[] = [
        Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendationCreateContent,
        Boo.Objects.Enums.TaskTypeEnum.OnsiteCopyCreateContentStandard,
        Boo.Objects.Enums.TaskTypeEnum.OnsiteCopyCreateContentStandardRetired
      ];

      let isInTerminalState = terminalStates.includes(websiteUrl.WebsiteUrlTask.WorkflowStateId);
      let isPendingWithoutRejectedPriorTasks = !websiteUrl.WebsiteUrlTask.HavePriorTasksBeenRejected && websiteUrl.WebsiteUrlTask.TaskStatusId === Boo.Objects.Enums.TaskStatusEnum.Pending;
      let isWorkblocked = websiteUrl.WebsiteUrlTask.IsWorkblocked;
      let isUIBlockingTaskType = uiBlockingTaskTypes.includes(websiteUrl.WebsiteUrlTask.TaskTypeId);



      if (!isInTerminalState && isUIBlockingTaskType) {
        websiteUrl.IsRestrictedFromStrategyUpdate = true;
        websiteUrl.RestrictionReason = `${this.getTaskTypeLabelMap()[websiteUrl.WebsiteUrlTask.TaskTypeId]} in progress`;

        if (!(isPendingWithoutRejectedPriorTasks || isWorkblocked)) {
          websiteUrl.IsReadOnly = true;
          websiteUrl.ReadOnlyReason = `${this.getTaskTypeLabelMap()[websiteUrl.WebsiteUrlTask.TaskTypeId]} in progress`;
        }
      }
    }
  }

  normalizeKeyword(keyword: string): string {
    return keyword
      .replaceAll('‘', '\'')
      .replaceAll('’', '\'')
      .replaceAll('“', '"')
      .replaceAll('”', '"')
      .replace(/\s+/g, ' ')
      .trim();
  }

  normalizeArea(area: string): string {
    return area.replace(/\s+/g, ' ').trim();
  }

  getKeywordPhrase(keyword: string, area: string, isAreaFirst: boolean): string {
    return (isAreaFirst ? this.normalizeArea(area) + ' ' + this.normalizeKeyword(keyword) : this.normalizeKeyword(keyword) + ' ' + this.normalizeArea(area)).trim();
  }

  activeStatuses(): Boo.Objects.Enums.WebsiteUrlStatuses[] {
    return [Boo.Objects.Enums.WebsiteUrlStatuses.Active, Boo.Objects.Enums.WebsiteUrlStatuses.Limited, Boo.Objects.Enums.WebsiteUrlStatuses.New];
  }

  isActiveStatus(websiteUrl: Boo.Objects.WebsiteUrl): boolean {
    return this.activeStatuses().includes(websiteUrl.StatusId);
  }

  domainMatches(customerUrl: string, pageUrl: string): boolean {
    let url: uri.URI = new URI(pageUrl.toLowerCase());
    let matchUrl: uri.URI = new URI(customerUrl.toLowerCase());
    return url.normalize().hostname() === matchUrl.normalize().hostname() && url.normalize().protocol() === matchUrl.normalize().protocol();
  }

  updateWebsiteUrlStatus(
    websiteUrls: Boo.Objects.WebsiteUrl[],
    haloKeywords: Boo.Objects.WebsiteKeywordTracking[],
    archivedWebsiteUrls: Boo.Objects.ArchivedWebsiteUrl[],
    archivedKeywords: Boo.Objects.ArchivedKeyword[],
    firstPageKeywordPhrases: string[],
    websiteUrl: Boo.Objects.WebsiteUrl,
    status: Boo.Objects.Enums.WebsiteUrlStatuses): void {
    let firstPageKeywordsMovedToHalo = false;
    websiteUrl.StatusId = status;
    websiteUrl.IsModified = true;

    if (status == Boo.Objects.Enums.WebsiteUrlStatuses.Archived) {
      websiteUrl.Keywords
        .filter(keyword => keyword.IsActive)
        .forEach(keyword => {
          if (firstPageKeywordPhrases.includes(keyword.KeywordPhrase.trim())) {
            firstPageKeywordsMovedToHalo = true;
            this.moveKeywordToHalo(websiteUrl.CustomerId, haloKeywords, keyword, firstPageKeywordPhrases);
          } else {
            this.archiveKeyword(archivedKeywords, keyword, websiteUrl)
          }
        });

      archivedWebsiteUrls.push({
        WebsiteUrlId: websiteUrl.WebsiteUrlId,
        Url: websiteUrl.Url,
        InsertedDate: websiteUrl.InsertedDate,
        LatestArchiveDate: new Date()
      } as Boo.Objects.ArchivedWebsiteUrl);

      if (!websiteUrl.WebsiteUrlId) {
        websiteUrls.splice(websiteUrls.indexOf(websiteUrl), 1);
      }
    }

    if (status === Boo.Objects.Enums.WebsiteUrlStatuses.Limited || status === Boo.Objects.Enums.WebsiteUrlStatuses.New) {
      websiteUrl.Keywords.forEach(keyword => {
        if (keyword.IsActive && firstPageKeywordPhrases.includes(keyword.KeywordPhrase.trim())) {
          firstPageKeywordsMovedToHalo = true;
          this.moveKeywordToHalo(websiteUrl.CustomerId, haloKeywords, keyword, firstPageKeywordPhrases);
        }
      });
    }
    
    if (firstPageKeywordsMovedToHalo) {
     toastr.info('First page keywords were moved to Halo.');
    }
  }

  updateArchivedWebsiteUrlStatus(
    customerId: number,
    websiteUrls: Boo.Objects.WebsiteUrl[],
    archivedWebsiteUrls: Boo.Objects.ArchivedWebsiteUrl[],
    archivedWebsiteUrl: Boo.Objects.ArchivedWebsiteUrl,
    status: Boo.Objects.Enums.WebsiteUrlStatuses): void {
    let websiteUrl = websiteUrls.find(x => x.WebsiteUrlId === archivedWebsiteUrl.WebsiteUrlId);
    if (websiteUrl) {
      websiteUrl.StatusId = status;
      websiteUrl.Keywords.forEach(keyword => { keyword.IsActive = false; });
      websiteUrl.IsModified = true;
    } else {
      websiteUrls.push({
        CustomerId: customerId,
        Description: '',
        IsHomePage: false,
        KeywordPhrases: '',
        Keywords: [],
        WebsiteUrlTask: null,
        StatusId: status,
        Url: archivedWebsiteUrl.Url,
        WebsiteUrlId: archivedWebsiteUrl.WebsiteUrlId,
        IsModified: true
      } as Boo.Objects.WebsiteUrl);
    }

    archivedWebsiteUrls.splice(archivedWebsiteUrls.indexOf(archivedWebsiteUrl), 1);
  }

  archiveKeyword(
    archivedKeywords: Boo.Objects.ArchivedKeyword[],
    websiteUrlKeyword: Boo.Objects.WebsiteUrlKeyword,
    websiteUrl: Boo.Objects.WebsiteUrl): void {
    archivedKeywords.push({
      KeywordId: websiteUrlKeyword.KeywordId,
      Keyword: websiteUrlKeyword.Keyword,
      AreaId: websiteUrlKeyword.AreaId,
      Area: websiteUrlKeyword.Area,
      IsAreaFirst: websiteUrlKeyword.IsAreaFirst,
      KeywordPhrase: websiteUrlKeyword.KeywordPhrase,
      ProRankTrackerUrlTermId: websiteUrlKeyword.ProRankTrackerUrlTermId,
      InitialRank: websiteUrlKeyword.InitialRank,
      InitialRankDate: websiteUrlKeyword.InitialRankDate,
      InsertedDate: websiteUrlKeyword.InsertedDate,
      WebsiteUrlId: websiteUrlKeyword.WebsiteUrlId,
      WebsiteUrl: websiteUrl.Url,
      WebsiteUrlKeywordId: websiteUrlKeyword.WebsiteUrlKeywordId,
      WebsiteKeywordTrackingId: null,
      LatestArchiveDate: new Date()
    } as Boo.Objects.ArchivedKeyword);

    if (websiteUrlKeyword.WebsiteUrlKeywordId) {
      websiteUrlKeyword.IsActive = false;
      websiteUrlKeyword.ProRankTrackerUrlTermId = null;
      websiteUrlKeyword.IsModified = true;
    } else {
      websiteUrl.Keywords.splice(websiteUrl.Keywords.indexOf(websiteUrlKeyword), 1);
    }
  }

  moveKeywordToWebsiteUrl(
    websiteUrlKeyword: Boo.Objects.WebsiteUrlKeyword,
    destinationWebsiteUrl: Boo.Objects.WebsiteUrl): void {
    let existingKeyword = destinationWebsiteUrl.Keywords.find(x => x.KeywordPhrase.toLowerCase().trim() === websiteUrlKeyword.KeywordPhrase.toLowerCase().trim());

    if (existingKeyword) {
      existingKeyword.IsActive = true;
      existingKeyword.IsRecommended = false;
      existingKeyword.ProRankTrackerUrlTermId = websiteUrlKeyword.ProRankTrackerUrlTermId;
      existingKeyword.InitialRank = existingKeyword.InitialRank || websiteUrlKeyword.InitialRank;
      existingKeyword.InitialRankDate = existingKeyword.InitialRankDate || websiteUrlKeyword.InitialRankDate;
      existingKeyword.IsModified = true;
    } else {
      destinationWebsiteUrl.Keywords.push({
        AreaId: websiteUrlKeyword.AreaId,
        Area: websiteUrlKeyword.Area,
        IsActive: true,
        IsAreaFirst: websiteUrlKeyword.IsAreaFirst,
        IsRecommended: false,
        KeywordId: websiteUrlKeyword.KeywordId,
        Keyword: websiteUrlKeyword.Keyword,
        KeywordPhrase: websiteUrlKeyword.KeywordPhrase,
        ProRankTrackerUrlTermId: websiteUrlKeyword.ProRankTrackerUrlTermId,
        InitialRank: websiteUrlKeyword.InitialRank,
        InitialRankDate: websiteUrlKeyword.InitialRankDate,
        WebsiteUrlId: destinationWebsiteUrl.WebsiteUrlId,
        IsModified: true
      } as Boo.Objects.WebsiteUrlKeyword);
    }

    websiteUrlKeyword.IsActive = false;
    websiteUrlKeyword.IsModified = true;
    websiteUrlKeyword.ProRankTrackerUrlTermId = null;
  }

  moveKeywordToHalo(
    customerId: number,
    haloKeywords: Boo.Objects.WebsiteKeywordTracking[],
    websiteUrlKeyword: Boo.Objects.WebsiteUrlKeyword,
    firstPageKeywordPhrases: string[]): void {
    let existingHaloKeyword = haloKeywords.find(x => x.KeywordPhrase.toLowerCase().trim() === websiteUrlKeyword.KeywordPhrase.toLowerCase().trim());

    let wasFirstPage = firstPageKeywordPhrases.includes(websiteUrlKeyword.KeywordPhrase.trim());

    if (existingHaloKeyword) {
      existingHaloKeyword.IsActive = true;
      existingHaloKeyword.IsModified = true;
      existingHaloKeyword.ProRankTrackerUrlTermId = websiteUrlKeyword.ProRankTrackerUrlTermId;
      existingHaloKeyword.InitialRank = existingHaloKeyword.InitialRank || websiteUrlKeyword.InitialRank;
      existingHaloKeyword.InitialRankDate = existingHaloKeyword.InitialRankDate || websiteUrlKeyword.InitialRankDate;
      existingHaloKeyword.WasFirstPage = existingHaloKeyword.WasFirstPage || wasFirstPage;
    } else {
      haloKeywords.push({
        AreaId: websiteUrlKeyword.AreaId,
        Area: websiteUrlKeyword.Area,
        CustomerId: customerId,
        IsActive: true,
        IsAreaFirst: websiteUrlKeyword.IsAreaFirst,
        KeywordId: websiteUrlKeyword.KeywordId,
        Keyword: websiteUrlKeyword.Keyword,
        KeywordPhrase: websiteUrlKeyword.KeywordPhrase,
        ProRankTrackerUrlTermId: websiteUrlKeyword.ProRankTrackerUrlTermId,
        InitialRank: websiteUrlKeyword.InitialRank,
        InitialRankDate: websiteUrlKeyword.InitialRankDate,
        WasFirstPage: wasFirstPage,
        Rank: websiteUrlKeyword.Rank,
        IsModified: true
      } as Boo.Objects.WebsiteKeywordTracking);
    }

    websiteUrlKeyword.IsActive = false;
    websiteUrlKeyword.IsModified = true;
    websiteUrlKeyword.ProRankTrackerUrlTermId = null;
  }

  archiveHaloKeyword(
    archivedKeywords: Boo.Objects.ArchivedKeyword[],
    haloKeywords: Boo.Objects.WebsiteKeywordTracking[],
    haloKeyword: Boo.Objects.WebsiteKeywordTracking): void {
    archivedKeywords.push({
      KeywordId: haloKeyword.KeywordId,
      Keyword: haloKeyword.Keyword,
      AreaId: haloKeyword.AreaId,
      Area: haloKeyword.Area,
      IsAreaFirst: haloKeyword.IsAreaFirst,
      KeywordPhrase: haloKeyword.KeywordPhrase,
      ProRankTrackerUrlTermId: haloKeyword.ProRankTrackerUrlTermId,
      InitialRank: haloKeyword.InitialRank,
      InitialRankDate: haloKeyword.InitialRankDate,
      InsertedDate: haloKeyword.InsertedDate,
      WebsiteUrlId: null,
      WebsiteUrl: null,
      WebsiteUrlKeywordId: null,
      WebsiteKeywordTrackingId: haloKeyword.WebsiteKeywordTrackingId,
      LatestArchiveDate: new Date()
    } as Boo.Objects.ArchivedKeyword);

    if (haloKeyword.WebsiteKeywordTrackingId) {
      haloKeyword.IsActive = false;
      haloKeyword.ProRankTrackerUrlTermId = null;
      haloKeyword.IsModified = true;
    } else {
      haloKeywords.splice(haloKeywords.indexOf(haloKeyword), 1);
    }
  }

  moveHaloKeywordToWebsiteUrl(
    haloKeyword: Boo.Objects.WebsiteKeywordTracking,
    websiteUrl: Boo.Objects.WebsiteUrl): void {
    let existingKeyword = websiteUrl.Keywords.find(x => x.KeywordPhrase.toLowerCase().trim() === haloKeyword.KeywordPhrase.toLowerCase().trim());

    if (existingKeyword) {
      existingKeyword.IsActive = true;
      existingKeyword.IsRecommended = false;
      existingKeyword.ProRankTrackerUrlTermId = haloKeyword.ProRankTrackerUrlTermId;
      existingKeyword.InitialRank = existingKeyword.InitialRank || haloKeyword.InitialRank;
      existingKeyword.InitialRankDate = existingKeyword.InitialRankDate || haloKeyword.InitialRankDate;
      existingKeyword.IsModified = true
    } else {
      websiteUrl.Keywords.push({
        AreaId: haloKeyword.AreaId,
        Area: haloKeyword.Area,
        IsActive: true,
        IsAreaFirst: haloKeyword.IsAreaFirst,
        IsRecommended: false,
        KeywordId: haloKeyword.KeywordId,
        Keyword: haloKeyword.Keyword,
        KeywordPhrase: haloKeyword.KeywordPhrase,
        ProRankTrackerUrlTermId: haloKeyword.ProRankTrackerUrlTermId,
        InitialRank: haloKeyword.InitialRank,
        InitialRankDate: haloKeyword.InitialRankDate,
        WebsiteUrlId: websiteUrl.WebsiteUrlId,
        Rank: haloKeyword.Rank,
        IsModified: true
      } as Boo.Objects.WebsiteUrlKeyword);
    }

    haloKeyword.IsActive = false;
    haloKeyword.IsModified = true;
    haloKeyword.ProRankTrackerUrlTermId = null;
  }

  moveArchivedKeywordToHalo(
    customerId: number,
    haloKeywords: Boo.Objects.WebsiteKeywordTracking[],
    archivedKeywords: Boo.Objects.ArchivedKeyword[],
    archivedKeyword: Boo.Objects.ArchivedKeyword,
    firstPageKeywordPhrases: string[]): void {
    let existingHaloKeyword = haloKeywords.find(x => x.KeywordPhrase.toLowerCase().trim() === archivedKeyword.KeywordPhrase.toLowerCase().trim());

    let wasFirstPage = firstPageKeywordPhrases.includes(archivedKeyword.KeywordPhrase.trim());

    if (existingHaloKeyword) {
      existingHaloKeyword.IsActive = true;
      existingHaloKeyword.IsModified = true;
      existingHaloKeyword.ProRankTrackerUrlTermId = archivedKeyword.ProRankTrackerUrlTermId;
      existingHaloKeyword.InitialRank = existingHaloKeyword.InitialRank || archivedKeyword.InitialRank;
      existingHaloKeyword.InitialRankDate = existingHaloKeyword.InitialRankDate || archivedKeyword.InitialRankDate;
      existingHaloKeyword.WasFirstPage = existingHaloKeyword.WasFirstPage || wasFirstPage;
    } else {
      haloKeywords.push({
        AreaId: archivedKeyword.AreaId,
        Area: archivedKeyword.Area,
        CustomerId: customerId,
        IsActive: true,
        IsAreaFirst: archivedKeyword.IsAreaFirst,
        KeywordId: archivedKeyword.KeywordId,
        Keyword: archivedKeyword.Keyword,
        KeywordPhrase: archivedKeyword.KeywordPhrase,
        ProRankTrackerUrlTermId: archivedKeyword.ProRankTrackerUrlTermId,
        InitialRank: archivedKeyword.InitialRank,
        InitialRankDate: archivedKeyword.InitialRankDate,
        WasFirstPage: wasFirstPage,
        IsModified: true
      } as Boo.Objects.WebsiteKeywordTracking);
    }

    archivedKeywords.splice(archivedKeywords.indexOf(archivedKeyword), 1);
  }

  moveArchivedKeywordToWebsiteUrl(
    archivedKeywords: Boo.Objects.ArchivedKeyword[],
    archivedKeyword: Boo.Objects.ArchivedKeyword,
    websiteUrl: Boo.Objects.WebsiteUrl): void {
    let existingKeyword = websiteUrl.Keywords.find(x => x.KeywordPhrase.toLowerCase().trim() === archivedKeyword.KeywordPhrase.toLowerCase().trim());

    if (existingKeyword) {
      existingKeyword.IsActive = true;
      existingKeyword.IsRecommended = false;
      existingKeyword.ProRankTrackerUrlTermId = archivedKeyword.ProRankTrackerUrlTermId;
      existingKeyword.InitialRank = existingKeyword.InitialRank || archivedKeyword.InitialRank;
      existingKeyword.InitialRankDate = existingKeyword.InitialRankDate || archivedKeyword.InitialRankDate;
      existingKeyword.IsModified = true;
    } else {
      websiteUrl.Keywords.push({
        AreaId: archivedKeyword.AreaId,
        Area: archivedKeyword.Area,
        IsActive: true,
        IsAreaFirst: archivedKeyword.IsAreaFirst,
        IsRecommended: false,
        KeywordId: archivedKeyword.KeywordId,
        Keyword: archivedKeyword.Keyword,
        KeywordPhrase: archivedKeyword.KeywordPhrase,
        ProRankTrackerUrlTermId: archivedKeyword.ProRankTrackerUrlTermId,
        InitialRank: archivedKeyword.InitialRank,
        InitialRankDate: archivedKeyword.InitialRankDate,
        WebsiteUrlId: websiteUrl.WebsiteUrlId,
        IsModified: true
      } as Boo.Objects.WebsiteUrlKeyword);
    }

    archivedKeywords.splice(archivedKeywords.indexOf(archivedKeyword), 1);
  }

  toggleIsRecommended(websiteUrlKeyword: Boo.Objects.WebsiteUrlKeyword): void {
    websiteUrlKeyword.IsRecommended = !websiteUrlKeyword.IsRecommended;
    websiteUrlKeyword.IsModified = true;
  }
}
