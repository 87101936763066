import { Component, Input } from '@angular/core';
import { HaloKeywordSuggestion, KeywordResearchTypes, KeywordSuggestion, KeywordSuggestionSources, PageSuggestion, PageSuggestionSources } from '../../../../shared/models/keywords/keywords';

@Component({
  selector: 'app-components-shared-keyword-research-keyword',
  templateUrl: './keyword.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class KeywordComponent {
  @Input() keywordResearchType: KeywordResearchTypes;
  @Input() customer: Boo.Objects.Customer;
  @Input() pageSuggestions: PageSuggestion[];
  @Input() haloKeywordSuggestions: HaloKeywordSuggestion[];
  @Input() pageSuggestion: PageSuggestion;
  @Input() keywordSuggestion: KeywordSuggestion;
  @Input() kwrSearchTerm: string;

  removeKeyword() {
    if (this.keywordSuggestion.SourceId === KeywordSuggestionSources.User) {
      this.pageSuggestion.KeywordSuggestions.splice(this.pageSuggestion.KeywordSuggestions.indexOf(this.keywordSuggestion), 1);
    } else {
      this.keywordSuggestion.IsSelected = false;
    }
    this.pageSuggestion.IsModified = true;
  }

  get validationMessage(): string {
    switch(this.keywordResearchType) {
      case KeywordResearchTypes.SeoOnboarding:
        return this.getOnboardingValidationMessage();
      case KeywordResearchTypes.StrategyUpdate:
        return this.getStrategyUpdateValidationMessage();
      default:
        return '';
    }
  }

  private getOnboardingValidationMessage(): string {
    var keywordPhrases = this.pageSuggestions.filter(x => x.IsSelected && x != this.pageSuggestion && x.IsCampaignNational === this.customer.IsCampaignNational)
      .flatMap(x => x.KeywordSuggestions)
      .filter(x => x.IsSelected && x != this.keywordSuggestion)
      .map(x => x.KeywordPhrase.toLowerCase().trim())
      .concat(this.haloKeywordSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).map(x => x.KeywordPhrase.toLowerCase().trim()));

    if (keywordPhrases.includes(this.keywordSuggestion.KeywordPhrase.toLowerCase().trim())) {
      return 'Duplicate';
    }

    return '';
  }

  private getStrategyUpdateValidationMessage(): string {
    var keywordPhrases = this.pageSuggestions.filter(x => (x.SourceId === PageSuggestionSources.CustomerCampaign || x.IsSelected) && x != this.pageSuggestion && x.IsCampaignNational === this.customer.IsCampaignNational)
      .flatMap(x => x.KeywordSuggestions)
      .filter(x => x.IsSelected && x != this.keywordSuggestion)
      .map(x => x.KeywordPhrase.toLowerCase().trim())
      .concat(this.haloKeywordSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).map(x => x.KeywordPhrase.toLowerCase().trim()));

    if (keywordPhrases.includes(this.keywordSuggestion.KeywordPhrase.toLowerCase().trim())) {
      return 'Duplicate';
    }

    return '';
  }
}
