<div
  *withPermissions="let allow of ['CanSetTopWorkPriority', 'CanSetHighProfile']"
  class="card">
  <div class="card-header">
    Basic Information
  </div>
  <div [loadingOverlay]="isLoading" class="card-body" style="min-height: 100px;">
    <div class="row">
      <ng-container *ngIf="customer && forms">
        <div [(formGroup)]="forms" class="col-md-6">
          <div class="form-group" [formValidation]="forms.get('companyName')">
            <label class="control-label">Company Name</label>
            <input formControlName="companyName" class="form-control" />
          </div>
          <div class="form-group" [formValidation]="forms.get('verticalId')">
            <label class="control-label">Vertical/Category</label>
            <div>
              <app-components-verticalselector formControlName="verticalId"></app-components-verticalselector>
            </div>
          </div>
          <div class="form-group" [formValidation]="forms.get('teamId')">
            <label class="control-label">Team</label>
            <select formControlName="teamId" class="form-select">
              <option [ngValue]="0">Choose...</option>
              <option *ngFor="let option of teams" [ngValue]="option.TeamId">{{ option.Name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="control-label">CSR</label>
            <select disabled *ngIf="csrs.length === 0" class="form-select">
              <option>Loading...</option>
            </select>
            <ng-select *ngIf="csrs.length !== 0 && csrUserId.enabled" [(formControl)]="csrUserId"
              [clearable]="false">
              <ng-option [value]="0">Choose...</ng-option>
              <ng-option *ngFor="let csr of csrs" [value]="csr.UserId">{{csr.FullName}}</ng-option>
            </ng-select>
            <ng-container *ngIf="csrUserId.disabled">
              <input [ngModel]="csrName()" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true"
                [readonly]="true" class="form-control" type="text" />
            </ng-container>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">
                  Adult Content
                  <app-components-helptext [key]="'BasicInformation_AdultContent'"></app-components-helptext>
                </label>
                <div>
                  <shared-components-customchoice [(value)]="customer.HasAdultContent"></shared-components-customchoice>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">
                  Send Emails
                  <app-components-helptext [key]="'BasicInformation_SendEmails'"></app-components-helptext>
                </label>
                <div>
                  <shared-components-customchoice formControlName="emails"></shared-components-customchoice>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="control-label">Status History</label>
            <div class="tab-content">
              <div class="tab-pane active" id="home">
                <app-table [items]="customer.StatusHistory" [exportFilename]="'customer-status-history'">
                  <ng-template #tableHeader>
                    <tr>
                      <th export sort desc field="StatusDate">Date</th>
                      <th export sort field="Status">Status</th>
                    </tr>
                  </ng-template>
                  <ng-template #tableBody let-status>
                    <tr>
                      <td>{{status.StatusDate | dateText: 'M/DD/YYYY h:mm a' : false }}</td>
                      <td>{{status.Status}}</td>
                    </tr>
                  </ng-template>
                </app-table>
              </div>
            </div>
          </div>
          <ng-container *ngIf="shouldSeoMoneyBackGuaranteeBeShown">
            <div>
              <ng-container *ngIf="customer.CustomerId">
                <compose Selector="app-components-guarantees-moneybackguarantee"
                  [ActivateOptions]="{ customerId: customer.CustomerId }"></compose>
              </ng-container>
            </div>
          </ng-container>
          <div class="card">
            <div class="card-header">Link Building</div>
            <div class="card-body">
              <div class="form-group" [formValidation]="forms.get('heading')">
                <label class="control-label">Heading</label>
                <input formControlName="heading" class="form-control" />
              </div>
              <div class="form-group" [formValidation]="forms.get('description')">
                <label class="control-label">Description</label>
                <textarea formControlName="description" class="form-control"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" [(formGroup)]="forms">
          <div class="form-group" [formValidation]="forms.get('countryId')">
            <label class="control-label">
              Country
              <span *ngIf="!canChangeCountry">
                <app-components-helptext
                  [key]="'BasicInformation_CantChangeCountryActiveLocalProfiles'"></app-components-helptext>
              </span>
            </label>
            <select formControlName="countryId" class="form-select" (change)="countryChanged()">
              <option [ngValue]="null">Choose...</option>
              <option *ngFor="let option of countries" [ngValue]="option.CountryId">{{ option.Name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="control-label">Time Zone</label>
            <ng-select [items]="filteredTimeZones" placeholder="Choose..." [clearable]=false bindLabel="NameWithAbbr"
              bindValue="Name" [ngModel]="timeZone" (ngModelChange)="onTimeZoneSelected($event)"
              [ngModelOptions]="{ standalone: true }"></ng-select>
          </div>
          <div class="form-group" [formValidation]="forms.get('languageId')">
            <label class="control-label">Language</label>
            <select formControlName="languageId"
              class="form-select">
              <option [ngValue]="null">Choose...</option>
              <option *ngFor="let option of languages" [ngValue]="option.LanguageId">{{ option.Name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="control-label">Referred By</label>
            <input [(formControl)]="referredBy" class="form-control" />
          </div>
          <div class="form-group">
            <label class="control-label">Lead Source</label>
            <select formControlName="leadSourceId" class="form-select">
                <option [ngValue]="null">Choose...</option>
                <option *ngFor="let leadSource of leadSources" [ngValue]="leadSource.LeadSourceId">{{leadSource.Name}}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="control-label">
              Ignore Keyword Misspellings
              <app-components-helptext [key]="'BasicInformation_ManagerApprovalRequired'"></app-components-helptext>
            </label>
            <div *ngIf="isManager">
              <shared-components-customchoice
                [(value)]="customer.IgnoreKeywordMisspellings"></shared-components-customchoice>
            </div>
            <div *ngIf="!isManager">
              <p>
                <i class="fa fa-check"></i>
                <span>{{(customer.IgnoreKeywordMisspellings) ? 'Yes' : 'No'}}</span>
              </p>
            </div>
          </div>
          <div *ngIf="hasHadUrlUnreachableTicket" class="form-group">
            <label class="control-label">
              Check if Customer URLS are reachable at renewal?
              <app-components-helptext [key]="'BasicInformation_URLUnreachableTicket'"></app-components-helptext>
            </label>
            <div>
              <shared-components-customchoice
                [(value)]="customer.VerifyUrlsAreAccessible"></shared-components-customchoice>
            </div>
          </div>
          <div *ngIf="allow().CanSetTopWorkPriority()" class="form-group">
            <label class="control-label d-block">Is Top Work Priority Account</label>
            <shared-components-customchoice [(value)]="isTopWorkPriority"></shared-components-customchoice>
          </div>
          <div class="form-group">
            <ng-container *ngIf="allow().CanSetHighProfile()">
              <label class="control-label d-block">Is High Profile Account</label>
              <div>
                <shared-components-customchoice [(value)]="isHighProfile"
                  (valueChange)="forms.get('highProfileReason').updateValueAndValidity()"></shared-components-customchoice>
              </div>
            </ng-container>
          </div>
          <div *ngIf="allow().CanSetHighProfile()" class="form-group" [formValidation]="forms.get('highProfileReason')">
            <label class="control-label d-block">High Profile Reason</label>
            <textarea formControlName="highProfileReason" class="form-control"></textarea>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="card card-body bg-light d-block text-end">
      <button *ngIf="canClose" (click)="cancel()" class="btn btn-link">Cancel</button>
      <button [disabled]="isLoading" (click)="save()" class="btn btn-success">
        <i class="fa fa-save"></i>
        Update Basic Information
      </button>
    </div>
  </div>
</div>