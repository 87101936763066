import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { BasicInfoComponent } from './BasicInfo/BasicInfo.component';
import { ConnectedUsersFloatingPanelComponent } from './ConnectedUsersFloatingPanel/ConnectedUsersFloatingPanel.component';
import { ContactAttemptsComponent } from './ContactAttempts/ContactAttempts.component';
import { CustomActionsReportComponent } from './CustomActionsReport/CustomActionsReport.component';
import { CustomerNotesComponent } from './customer-notes/customer-notes.component';
import { CustomerSynopsisComponent } from './CustomerSynopsis/CustomerSynopsis.component';
import { CustomerTodoListFloatingPanelComponent } from './CustomerTodoListFloatingPanel/CustomerTodoListFloatingPanel.component';
import { CustomerTodoListTicketComponent } from './CustomerTodoListTicket/CustomerTodoListTicket.component';
import { CustomReportsComponent } from './CustomReports/CustomReports.component';
import { DescendantsComponent } from './Descendants/Descendants.component';
import { FeaturesComponent } from './Features/Features.component';
import { FilesComponent} from './files/files.component';
import { ForceCustomerRefreshComponent } from './ForceCustomerRefresh/ForceCustomerRefresh.component';
import { KeywordResearchComponent } from './KeywordResearch/KeywordResearch.component';
import { StrategyUpdateComponent } from './StrategyUpdate/StrategyUpdate.component';
import { CustomerCampaignComponent } from './customer-campaign/customer-campaign.component';
import { LinkBuildingComponent } from './LinkBuilding/LinkBuilding.component';
import { LocalProfilesComponent } from './local-profiles/LocalProfiles.component';
import { LockConfirmationComponent } from './LockConfirmation/LockConfirmation.component';
import { SeoContentSurveyComponent } from './SeoContentSurvey/SeoContentSurvey.component';
import { NoteComponent } from './Note/Note.component';
import { NoteFloatingPanelComponent } from './NoteFloatingPanel/NoteFloatingPanel.component';
import { OnsiteCopyImplementationNotesComponent } from './OnsiteCopyImplementationNotes/OnsiteCopyImplementationNotes.component';
import { OnsiteCopyRequestComponent } from './OnsiteCopyRequest/OnsiteCopyRequest.component';
import { OnsiteRecommendationsComponent } from './OnsiteRecommendations/OnsiteRecommendations.component';
import { OnsiteRecommendationsCompleteComponent } from './OnsiteRecommendationsComplete/OnsiteRecommendationsComplete.component';
import { PreAuditReportComponent } from './pre-audit-report/pre-audit-report.component';
import { ReportingAccessComponent } from './ReportingAccess/ReportingAccess.component';
import { RetentionComponent } from './Retention/Retention.component';
import { ReviewComponent } from './Review/Review.component';
import { TicketComponent } from './Ticket.component';
import { TicketCompleteConfirmationComponent } from './TicketCompleteConfirmation/TicketCompleteConfirmation.component';
import { TicketNavigationComponent } from './TicketNavigation/TicketNavigation.component';
import { TicketNavigationFloatingPanelComponent } from './TicketNavigationFloatingPanel/TicketNavigationFloatingPanel.component';
import { TicketTimerComponent } from './TicketTimer/TicketTimer.component';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { BlogAndCopyContentReviewComponent } from './blog-and-copy-content-review/blog-and-copy-content-review.component';
import { UpaChecklistComponent } from './upa-checklist/upa-checklist.component';
import { ManageCustomerModule } from '../managecustomer/manage-customer.module';
import { BacklinkParametersComponent } from './backlink-parameters/backlink-parameters.component';


@NgModule({
  declarations: [
    BasicInfoComponent,
    ConnectedUsersFloatingPanelComponent,
    ContactAttemptsComponent,
    CustomActionsReportComponent,
    CustomerNotesComponent,
    CustomerSynopsisComponent,
    CustomerTodoListFloatingPanelComponent,
    CustomerTodoListTicketComponent,
    CustomReportsComponent,
    DescendantsComponent,
    FeaturesComponent,
    FilesComponent,
    ForceCustomerRefreshComponent,
    KeywordResearchComponent,
    StrategyUpdateComponent,
    CustomerCampaignComponent,
    LinkBuildingComponent,
    LocalProfilesComponent,
    LockConfirmationComponent,
    SeoContentSurveyComponent,
    NoteComponent,
    NoteFloatingPanelComponent,
    OnsiteCopyImplementationNotesComponent,
    OnsiteCopyRequestComponent,
    OnsiteRecommendationsComponent,
    OnsiteRecommendationsCompleteComponent,
    PreAuditReportComponent,
    ReportingAccessComponent,
    RetentionComponent,
    ReviewComponent,
    TicketComponent,
    TicketCompleteConfirmationComponent,
    TicketNavigationComponent,
    TicketNavigationFloatingPanelComponent,
    TicketTimerComponent,
    BlogAndCopyContentReviewComponent,
    UpaChecklistComponent,
    BacklinkParametersComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedComponentsModule,
    ManageCustomerModule
  ]
})
export class TicketModule { }
