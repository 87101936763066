import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { SharedComponentsModule } from '../../shared/shared-components.module';
import { InternalActionRejectComponent } from './reject-modal/reject-modal.component';
import { BlogBuildComponent } from './workspaces/blog-build/blog-build.component';
import { BlogDomainPurchaseComponent } from './workspaces/blog-domain-purchase/blog-domain-purchase.component';
import { DomainPurchaseActionInformationComponent } from './domain-purchase-action-information/domain-purchase-action-information.component';
import { ActionInformationComponent } from './action-information/action-information.component';
import { SiteThemesReviewContentComponent } from './workspaces/site-themes-review-content/site-themes-review-content.component';
import { SiteThemesAdditionalInfoComponent } from './workspaces/site-themes-review-content/site-themes-additional-info/site-themes-additional-info.component';
import { ClassifiedBusinessListingQaComponent } from './workspaces/classified-business-listing-qa/classified-business-listing-qa.component';
import { LocalBusinessCitationQaComponent } from './workspaces/local-business-citation-qa/local-business-citation-qa.component';
import { ContentReviewQaComponent } from './workspaces/content-review-qa/content-review-qa.component';
import { PremiumBacklinkQaComponent } from './workspaces/premium-backlink-qa/premium-backlink-qa.component';
import { OnsiteCopyReviewQaComponent } from './workspaces/onsite-copy-review-qa/onsite-copy-review-qa.component';
import { OnsiteBloggingReviewQaComponent } from './workspaces/onsite-blogging-review-qa/onsite-blogging-review-qa.component';
import { QaActionInformationComponent } from './qa-action-information/qa-action-information.component';
import { PremiumMonthlyCampainUpdateQaComponent } from './workspaces/premium-monthly-campaign-update-qa/premium-monthly-campaign-update-qa.component';
import { PremiumPreliminaryAuditQaComponent } from './workspaces/premium-preliminary-audit-qa/premium-preliminary-audit-qa.component';
import { ProactiveUnderperformingKeywordQaComponent } from './workspaces/proactive-underperforming-keyword-qa/proactive-underperforming-keyword-qa.component';
import { InternalActionWorkspaceComponent } from './internal-action-workspace/internal-action-workspace.component';
import { InternalTasksComponent } from './internal-tasks.component';
import { FormsModule } from '@angular/forms';
import { SiteThemesCreateContentComponent } from './workspaces/site-themes-create-content/site-themes-create-content.component';
import { OnsiteRecommendationReviewQaComponent } from './workspaces/onsite-recommendation-review-qa/onsite-recommendation-review-qa.component';
import { ManageCustomerModule } from '../../managecustomer/manage-customer.module';
import { ActionComponentsModule } from '../actioncomponents/actioncomponents.module';
import { SpecialistModule } from '../specialist.module';
import { QaModule } from '../../qa/qa.module';


@NgModule({
  declarations: [
    InternalActionRejectComponent,
    BlogBuildComponent,
    BlogDomainPurchaseComponent,
    DomainPurchaseActionInformationComponent,
    ActionInformationComponent,
    SiteThemesCreateContentComponent,
    SiteThemesReviewContentComponent,
    SiteThemesAdditionalInfoComponent,
    QaActionInformationComponent,
    ClassifiedBusinessListingQaComponent,
    LocalBusinessCitationQaComponent,
    ContentReviewQaComponent,
    PremiumBacklinkQaComponent,
    OnsiteCopyReviewQaComponent,
    OnsiteBloggingReviewQaComponent,
    PremiumMonthlyCampainUpdateQaComponent,
    PremiumPreliminaryAuditQaComponent,
    ProactiveUnderperformingKeywordQaComponent,
    InternalActionWorkspaceComponent,
    InternalTasksComponent,
    OnsiteRecommendationReviewQaComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedComponentsModule,
    FormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
    ManageCustomerModule,
    ActionComponentsModule,
    SpecialistModule,
    QaModule
  ]
})
export class InternalTasksModule { }
