
<ng-select 
	[items]="verticals"
	placeholder="Choose..."
	bindLabel="Name"
	bindValue="VerticalId"
	[groupBy]="groupByFn"
	[groupValue]="groupValueFn"
	[searchFn]="searchFn"
	[selectableGroup]="true"
	[formControl]="vertical">
	<ng-template ng-optgroup-tmp let-item="item">
		{{ item.Name }}
	</ng-template>

	<ng-template ng-label-tmp let-item="item">
		{{ item.Name }}
	</ng-template>
</ng-select>
